import React, { useReducer, useEffect, useCallback, useState, useContext } from 'react';
import styled from 'styled-components';
import { Input, Dropdown, Button } from 'semantic-ui-react';
import RangeSlider from 'react-bootstrap-range-slider';
import { Accordion, AccordionGroup } from '../../utils/accordion/accordion';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import SearchFilter from './search';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import debounce from 'lodash/debounce';
import { Rating } from 'react-simple-star-rating';
import { UserContext } from '../../contexts/user_context';
import LanguageContext from '../../contexts/language_context';
import LanguageText from '../language_text/language_text';

const reducer = (state, action) => {
  switch (action.type) {
    case 'get-filter-success':
      return {
        ...state,
        categoryFilter: action.filter.expertise,
        majorFilter: action.filter.majors
      };
    case 'change-rate': return { ...state, rate: action.rate };
    case 'rate-submitted': return { ...state, submitted: true, submitLoading: false };
    case 'rate-submit-start': return { ...state, submitLoading: true };
    case 'rate-submit-error': return { ...state, submitLoading: false };
    case 'change-expertise-search': return { ...state, expertiseSearch: action.value };
  }
};

const Filter = ({
  filterError,
  onChangeCategoryFilter,
  onChangeExperienceFilter,
  onChangeUniFilter,
  onChangeMajorFilter,
  onChangeRateFilter,
  onChangeSearch,
  onChangeLangFilter,
  toggleFilterFn,
  isFilterOpen,
  cFilter,
  uniF,
  langF,
  majorF
}) => {
  const [state, dispatch] = useReducer(reducer, {
    categoryFilter: [],
    rate: 0,
    submitted: false,
    submitLoading: false,
    expertiseSearch: ''
  });
  const { categoryFilter, rate, submitted, submitLoading, expertiseSearch } = state;
  const { user } = useContext(UserContext);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (user.isRateSubmitted) dispatch({ type: 'rate-submitted' });
  }, [user]);

  useEffect(() => {
    getApiClient().getCandidatesFilter(language)
      .then(response => {
        dispatch({ type: 'get-filter-success', filter: response.data.filter });
      })
      .catch(makeStandardApiErrorHandler(error => {
        filterError(error);
        dispatch({ type: 'rate-submit-error' });
      }));
  }, [language, filterError]);

  const onChangeRate = useCallback((rate) => dispatch({ type: 'change-rate', rate: rate }), []);
  const submitRate = useCallback(() => {
    dispatch({ type: 'rate-submit-start' });
    getApiClient().submitRateService(rate)
      .then(response => {
        if (response.data.success) {
          dispatch({ type: 'rate-submitted' });
        }
      })
      .catch(makeStandardApiErrorHandler(error => filterError(error)));
  }, [filterError, rate]);

  const changeExpertiseSearch = useCallback((e) => {
    dispatch({ type: 'change-expertise-search', value: e.target.value });
  }, []);

  const LANG_OPTIONS = ['Arabic', 'English', 'French', 'German', 'Spanish', 'Chinese', 'Japanese', 'Portuguese', 'Russian', 'Other'];

  return (
    <FilterBox isOpen={isFilterOpen}>
      <div>
        <CloseButton onClick={toggleFilterFn} isOpen={isFilterOpen}>&times;</CloseButton>
        <Title><LanguageText text='Filters' /></Title>
        <AccordionGroup defaultOpen={false}>
          <Accordion smaller name='Expertise' key2={1} color='black'>
            <MenuSection name='Expertise' menuItems={categoryFilter} filters={cFilter}
              onChangeCategoryFilter={onChangeCategoryFilter} language={language}
              expertiseSearch={expertiseSearch} changeExpertiseSearch={changeExpertiseSearch} />
          </Accordion>
          <Accordion smaller name='Years of Experience' key2={2} color='black'>
            <ExperienceFilter onChangeExperienceFilter={onChangeExperienceFilter} />
          </Accordion>
          <Accordion smaller name='University' key2={3} color='black'>
            <UniversityFilter onChangeUniFilter={onChangeUniFilter} filterError={filterError} filters={uniF} />
          </Accordion>
          {/* <Accordion smaller name='Major' key2={4} color='black'>
            <MenuSection name='Major' menuItems={majorFilter} filters={majorF}
              onChangeMajorFilter={onChangeMajorFilter} language={language} />
          </Accordion> */}
          <Accordion smaller name='Language' key2={5} color='black'>
            <MenuSection name='Language' menuItems={LANG_OPTIONS} filters={langF}
              onChangeLangFilter={onChangeLangFilter} language={language} />
          </Accordion>
          <Accordion smaller name='Average Rate' key2={6} color='black'>
            <AverageRateMenu onChangeRateFilter={onChangeRateFilter} language={language} />
          </Accordion>
        </AccordionGroup>
        <OR><LanguageText text='OR' /></OR>
        <SearchBox>
          <SearchFilter onChangeSearch={onChangeSearch} />
        </SearchBox>
      </div>
      {!submitted &&
        <div>
          <p>Rate our service!</p>
          <Rating onClick={onChangeRate} />
          <ServiceBtnDiv>
            <Button content='Send' onClick={submitRate} loading={submitLoading} />
          </ServiceBtnDiv>
        </div>}
    </FilterBox>
  );
};

const ServiceBtnDiv = styled.div`
  display: flex;
  justify-content: center;

  .ui.button {
    margin-top: 10px;
    padding: 8px 30px 8px 30px;
    color: white;
    background-color: #23485B;
    font-family: 'Corbel';
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.44), inset 0px 0px 5px #000000;
    border-radius : 10px;
    font-size: 16px;
  }
`;

const SearchBox = styled.div`
  text-align: center;

  .ui.input > input {
    text-align: center !important;
    background-color: #F9F9F9 !important;
    font-size: 16px !important;
    border-radius: 10px !important;
    font-family: 'Corbel' !important;
  }
`;

const OR = styled.p`
  text-align: center;
  margin: 12px 0 12px 0;
  font-family: 'Corbel';
  font-size: 18px;
`;

const FilterBox = styled.div`
  width: 400px;
  min-height: 700px;
  background-color: #F8F8F8;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1146px) {
    padding: 0;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
    padding : ${props => props.isOpen ? '24px' : '0'};
    width : ${props => props.isOpen ? '100%' : '0'};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  border: none;
  background: transparent;
  cursor: pointer;
  ${props => !props.isOpen && 'display: none'};
`;

const Title = styled.p`
  padding: 6px 0 20px 0;
  text-align: center;
  font-family: 'Corbel Bold';
  font-size: 22px;
  color: #22475A;
  padding-bottom: 0;
`;

const MenuSection = ({
  name,
  menuItems,
  onChangeCategoryFilter,
  onChangeMajorFilter,
  onChangeLangFilter,
  language,
  filters,
  expertiseSearch,
  changeExpertiseSearch
}) => {
  if (menuItems && menuItems.length === 0) return null;
  let onChange = onChangeCategoryFilter;
  if (name === 'Major') onChange = onChangeMajorFilter;
  if (name === 'Language') onChange = onChangeLangFilter;
  return (
    menuItems &&
      <MenuSectionContainer style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        {name === 'Expertise'
          ? <div>
              <SearchIt value={expertiseSearch} onChange={changeExpertiseSearch} placeholder='Search for Expertise' />
              {menuItems.map(x => x.toLowerCase().includes(expertiseSearch.toLowerCase()) &&
                <MenuItem key={x} filters={filters} name={name} menuItem={x} onChange={onChange} />)}
            </div>
          : menuItems.map(menuItem =>
              /* eslint-disable-next-line react/jsx-no-bind */
              <MenuItem key={menuItem} filters={filters} name={name} menuItem={menuItem} onChange={onChange} />)}
      </MenuSectionContainer>
  );
};

const SearchIt = styled(Input)`
  width: 100% !important;
  margin-bottom: 2px;
  > input {
    border-radius: 10px !important;
  }
`;

const MenuItem = ({ filters, menuItem, name, onChange }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const categoryParams = searchParams.get('category');
    const majorParams = searchParams.get('major');
    const langParams = searchParams.get('lang');

    if ((categoryParams && categoryParams.includes(menuItem)) || (majorParams && majorParams.includes(menuItem)) ||
      (langParams && langParams.includes(menuItem))) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [menuItem, filters]);

  const handleClick = useCallback((menuItem) => {
    setIsActive(!isActive);
    onChange(menuItem);
  }, [isActive, onChange]);

  return (
    /* eslint-disable-next-line react/jsx-no-bind */
    <FilterInnerContainer isActive={isActive} onClick={() => handleClick(menuItem)}>
      {name !== 'Language'
        ? <span>{menuItem}</span>
        : <LanguageText text={menuItem} />}
    </FilterInnerContainer>
  );
};

const MenuSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px 4px 8px;
  max-height: 200px;
  ${props => props.withoutScroll ? 'overflow: none' : 'overflow: auto'}; 
`;

const FilterInnerContainer = styled.div`
  margin-top: 4px;
  padding: 2px;
  padding-left: 8px;
  transition: background-color 0.1s ease-in-out;
  ${props => props.isActive && 'background-color: #EECA5E'};
  &:hover {
    ${props => !props.withoutEffect && 'background-color: #EECA5E'};
    cursor: pointer;
  }
`;

const ExperienceFilter = ({ onChangeExperienceFilter }) => {
  const [range, setRange] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const experienceParam = searchParams.get('experience');
    if (experienceParam) setRange(parseInt(experienceParam));
  }, []);

  const changeRange = useCallback((changeEvent) => {
    setRange(changeEvent.target.value);
  }, []);

  const handleChange = useCallback(() => {
    onChangeExperienceFilter(range);
  }, [onChangeExperienceFilter, range]);

  return (
    <MenuSectionContainer withoutScroll>
      <FilterInnerContainer withoutEffect>
        <UIRangeSlider value={range} onChange={changeRange} onAfterChange={handleChange} min={0} max={50}
          variant='warning' />
      </FilterInnerContainer>
    </MenuSectionContainer>
  );
};

const UIRangeSlider = styled(RangeSlider)`
  width: 100% !important;
`;

const RATE_OPTIONS = [
  { key: 0, value: 'Hourly', text: 'Hourly' },
  { key: 1, value: 'Daily', text: 'Daily' }
];

const AverageRateMenu = ({ onChangeRateFilter, language }) => {
  const [min, setMin] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(RATE_OPTIONS[0].value);

  const handleKeyPress = useCallback((e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const isNumeric = /^[0-9]*$/.test(keyValue);
    if (!isNumeric) {
      e.preventDefault();
    }
  }, []);

  const handleChange = useCallback((e, { value }) => {
    if (e.target.id === 'min') {
      setMin(e.target.value);
    } else {
      setDropdownValue(value);
    }
  }, []);

  useEffect(() => {
    const debouncedOnChangeRateFilter = debounce(onChangeRateFilter, 500);

    const updateRateFilter = () => {
      const rateFilter = { min, type: dropdownValue };
      debouncedOnChangeRateFilter(rateFilter);
    };

    updateRateFilter();

    return () => {
      debouncedOnChangeRateFilter.cancel();
    };
  }, [min, dropdownValue, onChangeRateFilter]);

  return (
    <Box style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
      <div>
        <RateText><LanguageText text='RATE' /></RateText>
        <Input placeholder='35' icon='dollar' onKeyPress={handleKeyPress} id='min' onChange={handleChange}
          style={{ marginRight: language === 'ar' ? '0' : '12px', marginLeft: language === 'ar' ? '12px' : '0', width: '100px' }} />
      </div>
      <UIDropdown value={dropdownValue} selection onChange={handleChange}
         options={RATE_OPTIONS.map(option => ({
          ...option,
          text: <LanguageText text={option.text} />
        }))} language={language} />
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: end;

  .ui.input > input {
    text-align: center !important;
    padding: 10px 12px 10px 6px !important;
  }
  .ui.icon.input > i.icon:not(.link) {
    width: 20px !important;
    color: initial !important;
  }
`;

const RateText = styled.p`
  text-align: center;
  margin-bottom: 0;
  margin-right: 12px;
  font-family: 'Corbel';
  font-weight: 500;
  font-size: 12px;
`;

const UIDropdown = styled(Dropdown)`
  min-width: 110px !important;
  max-width: 110px !important;
  .text {
    width: 100% !important;
    text-align: ${props => props.language === 'ar' ? 'right' : 'left'} !important;
  }
  .menu > .item {
    text-align: ${props => props.language === 'ar' ? 'right' : 'left'} !important;
  }
`;

const UniversityFilter = ({ onChangeUniFilter, filterError }) => {
  const [universities, setUniversities] = useState([]);

  const handleSearch = useCallback((e) => {
    if (e.target.value.length > 3) {
      setTimeout(() => {
        getApiClient().getUniversities(e.target.value)
          .then(response => setUniversities(response.data.uni))
          .catch(makeStandardApiErrorHandler(error => filterError(error)));
      }, 1000);
    }
  }, [filterError]);

  return (
    <>
      <UniSearchBox>
        <Input placeholder='Search by keyword' onChange={handleSearch} />
      </UniSearchBox>
      <MenuSectionContainer>
        {universities.map(uni =>
          /* eslint-disable-next-line react/jsx-no-bind */
          <UniMenu key={uni} menuItem={uni.name} onChange={onChangeUniFilter} />)}
      </MenuSectionContainer>
    </>
  );
};

const UniSearchBox = styled.div`
  text-align: center;

  .ui.input > input {
    text-align: center !important;
    border-radius: 10px !important;
    font-family: 'Corbel' !important;
    font-weight: 400 !important;
  }
`;

const UniMenu = ({ menuItem, onChange }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uniParams = searchParams.get('uni');

    if ((uniParams && uniParams.includes(menuItem))) {
      setIsActive(true);
    }
  }, [menuItem]);

  const handleClick = useCallback((event) => {
    setIsActive(!isActive);
    onChange(event.target.id);
  }, [isActive, onChange]);

  return (
    <FilterInnerContainer id={menuItem} isActive={isActive} onClick={handleClick}>
      {menuItem}
    </FilterInnerContainer>
  );
};

export default Filter;
