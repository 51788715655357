import React, { useEffect } from 'react';
import styled from 'styled-components';
import Wave from 'react-wavify';
import Navbar from '../../Common/navbar.js';
import Footer from '../../Common/footer.js';
import LanguageText from '../language_text/language_text.js';

export default function Terms() {
  useEffect(() => {
    document.title = 'Wazeefati | Terms';
    window.scrollTo(0, 0);
  }, []);

  const language = 'en';
  return (
    <div style={{ position: 'relative' }}>
    <Navbar />
    <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <Fields>
        <Congrats>Terms & Privacy</Congrats>
        <Container language={language}>
            <Label><LanguageText text='IntroductionHeader' withoutTranslation /></Label>
            <Para><LanguageText text='IntroductionText1' withoutTranslation /></Para>
            <Para><LanguageText text='IntroductionText2' withoutTranslation /></Para>
            <Para>
            <ul style={{ margin: '0px 20px' }}>
              <li><LanguageText text='IntroductionText3' withoutTranslation /></li>
              <li>
                <LanguageText text='IntroductionText4' withoutTranslation />
                <ul style={{ margin: '0px 20px' }}>
                  <li><LanguageText text='IntroductionText5' withoutTranslation /></li>
                  <li><LanguageText text='IntroductionText6' withoutTranslation /></li>
                </ul>
              </li>
              <li><LanguageText text='IntroductionText7' withoutTranslation /></li>
              <li><LanguageText text='IntroductionText8' withoutTranslation /></li>
              <li><LanguageText text='IntroductionText9' withoutTranslation /></li>
            </ul>
            </Para>
            <Para><LanguageText text='IntroductionText10' withoutTranslation /></Para>
            <Label><LanguageText text='Your wazeefati.org acccount' withoutTranslation /></Label>
            <Para><LanguageText text='Your wazeefati.org acccount part1' withoutTranslation /></Para>
            <Para><LanguageText text='Your wazeefati.org acccount part2' withoutTranslation /></Para>
            <Para><LanguageText text='Your wazeefati.org acccount part3' withoutTranslation /></Para>
            <Label><LanguageText text='YOUR CONTENT' withoutTranslation /></Label>
            <Para><LanguageText text='YOUR CONTENT part1' withoutTranslation /></Para>
            <Para>
            <ol style={{ margin: '0px 20px' }}>
              <li><LanguageText text='YOUR CONTENT part2' withoutTranslation /></li>
              <li><LanguageText text='YOUR CONTENT part3' withoutTranslation /></li>
              <li><LanguageText text='YOUR CONTENT part4' withoutTranslation /></li>
              <li><LanguageText text='YOUR CONTENT part5' withoutTranslation /></li>
              <li><LanguageText text='YOUR CONTENT part6' withoutTranslation /></li>
              <li><LanguageText text='YOUR CONTENT part7' withoutTranslation /></li>
              <li><LanguageText text='YOUR CONTENT part8' withoutTranslation /></li>
              <li><LanguageText text='YOUR CONTENT part9' withoutTranslation /></li>
              <li><LanguageText text='YOUR CONTENT part10' withoutTranslation /></li>
            </ol>
            </Para>
            <Label><LanguageText text='THIRD PARTY WEBSITES AND CONTENT' withoutTranslation /></Label>
            <Para><LanguageText text='THIRD PARTY WEBSITES AND CONTENT part1' withoutTranslation /></Para>
            <Label><LanguageText text='INTELLECTUAL PROPERTY' withoutTranslation /></Label>
            <Para><LanguageText text='INTELLECTUAL PROPERTY part1' withoutTranslation /></Para>
            <Para><LanguageText text='INTELLECTUAL PROPERTY part2' withoutTranslation /></Para>
            <Para><LanguageText text='INTELLECTUAL PROPERTY part3' withoutTranslation /></Para>
            <Para><LanguageText text='INTELLECTUAL PROPERTY part4' withoutTranslation /></Para>
            <Para><LanguageText text='INTELLECTUAL PROPERTY part5' withoutTranslation /></Para>
            <Para><LanguageText text='INTELLECTUAL PROPERTY part6' withoutTranslation /></Para>
            <Label><LanguageText text='CHANGES' withoutTranslation /></Label>
            <Para><LanguageText text='CHANGES part1' withoutTranslation /></Para>
            <Label><LanguageText text='DISCLAIMER OF WARRANTIES' withoutTranslation /></Label>
            <Para><LanguageText text='DISCLAIMER OF WARRANTIES part1' withoutTranslation /></Para>
            <Label><LanguageText text='LIMITATION OF LIABILITY' withoutTranslation /></Label>
            <Para><LanguageText text='LIMITATION OF LIABILITY part1' withoutTranslation /></Para>
            <Para><LanguageText text='LIMITATION OF LIABILITY part2' withoutTranslation /></Para>
            <Para><LanguageText text='LIMITATION OF LIABILITY part3' withoutTranslation /></Para>
            <Para><LanguageText text='LIMITATION OF LIABILITY part4' withoutTranslation /></Para>
            <Label><LanguageText text='INDEMNIFICATION' withoutTranslation /></Label>
            <Para><LanguageText text='INDEMNIFICATION part1' withoutTranslation /></Para>
            <Label><LanguageText text='MISCELLANEOUS' withoutTranslation /></Label>
            <Para><LanguageText text='MISCELLANEOUS part1' withoutTranslation /></Para>
            <Para><LanguageText text='MISCELLANEOUS part2' withoutTranslation /></Para>
            <Para><LanguageText text='MISCELLANEOUS part3' withoutTranslation /></Para>
            <Para><LanguageText text='MISCELLANEOUS part4' withoutTranslation /></Para>
            <Para><LanguageText text='MISCELLANEOUS part5' withoutTranslation /></Para>
            <Para><LanguageText text='MISCELLANEOUS part6' withoutTranslation /></Para>
            <Label><LanguageText text='CONTACTING WAZEEFATI.ORG' withoutTranslation /></Label>
            <Para><LanguageText text='MISCELLANEOUS part1' withoutTranslation /></Para>
        </Container>
    </Fields>
    <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <Footer />
    </div>
  );
};

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0px 50px 0px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 100px 0px;
  min-height 90vh;
  @media screen and (max-width: 745px){
    margin-bottom: 20px !important;
  }
`;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: 270px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: 280px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const Label = styled.div`
  font-family: 'Roboto';
  margin-top: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 47px;
  font-variant: all-small-caps;
  font-variant: all-small-caps;
  line-height: 55px;
`;
const Para = styled.div`
  margin-top: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
`;
const Congrats = styled.div`
margin-top: -30px;
width: 70vw;
text-align: left;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 55px;
/* identical to box height */

color: #FFC000;
@media screen and (max-width: 824px) {
  font-size: 40px;
  text-align: center;
}
`;
const Container = styled.div`
direction: ${props => props.language === 'ar' ? 'rtl' : 'ltr'};
width: 70vw;
padding-bottom: 100px;
min-height: 50vh;
margin-top: 10px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 55px;
/* identical to box height */

color: black;
`;
