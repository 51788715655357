import React from 'react';
import styled from 'styled-components';
import { Form, Dropdown } from 'semantic-ui-react';
import Data from '../../countriesData';

// const options = [
//   { key: 'Bachelor', text: 'Bachelor', value: 'Bachelor' },
//   { key: 'Master', text: 'Master', value: 'Master' },
//   { key: 'PHD', text: 'PHD', value: 'PHD' }
// ];

export default function Award(props) {
  return (
    <Fields>
      <Bigger>
        {props.awards.map((data, index) => {
          return (
            /* eslint-disable-next-line */
      <Container key={index}>
      <Form.Field>
        <Label>from</Label>
        <Digit value={data.award_from} onChange={props.AddawardsFrom} index={index} placeholder='YYYY' min='0' type='text' />
      </Form.Field>
      <Dash>-</Dash>
      <Form.Field>
      <Label>institution</Label>
      <Name value={data.institution} onChange={props.AddawardsInstitution} index={index} placeholder='Sanad Lebanon' />
      </Form.Field>
      <Form.Field>
        <Label>country</Label>
        <Country index={index} onChange={props.AddawardsCountry} value={data.country} placeholder='Select Country' fluid search selection options={Data} />
      </Form.Field>
      {/* <Form.Field>
        <Label>type</Label>
        <Type value={data.type} onChange={props.AddawardsType} index={index} placeholder='Master' options={options} />
      </Form.Field> */}
      <Form.Field>
        <Label>description</Label>
        <Desc value={data.description} onChange={props.AddawardsDesc} index={index} placeholder='what was the award for?' />
      </Form.Field>
      {/* eslint-disable-next-line */}
      {index === 0 ? '' : <Rem onClick={() => props.RemoveAward(index)}>-</Rem>}
      </Container>);
              })}
      <Plus onClick={props.Addawards}>+</Plus>
      </Bigger>
    </Fields>
  );
}

const Dash = styled.div`
  margin-top: 30px;
  font-size: 30px;
  @media screen and (max-width: 745px){
    display: none;
  }
`;
const Rem = styled.div`
display: flex;
margin: 25.5px 0px 0px -10px;
font-size: 1.5rem;
color: #FFC000;
box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
align-items: center;
justify-content: center;
width: 30px;
height: 40px;
cursor: pointer;
color: red;
background: white;
@media screen and (max-width: 765px){
  width: 90vw;
  margin: 31.5px 0px 0px 0px;
}
`;
const Bigger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 745px){
    margin-bottom: 35px !important;
  }
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0px 10px 0px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height 50vh;
`;
const Plus = styled.div`
  position: absolute;
  right: -15px;
  bottom: -40px;
  display: flex;
  margin: 15px;
  font-size: 1.5rem;
  color: #FFC000;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #23485B;
  border-radius: 100px;
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 79vw;
  @media screen and (max-width: 745px){
    justify-content: center;
    margin-bottom: 20px !important;
    border-top: 1px solid black;
    width: 90vw !important;
  }
`;
const Label = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-family: 'Corbel' !important;
  font-variant: small-caps;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
`;
const Digit = styled(Form.Input)`
  width: 100px;
  height: 38px;
  background: white;
  margin: 0px 5px !important;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  > input{
    height: 35px;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  }
`;
const Name = styled(Form.Input)`
  width: 250px;
  height: 38px;
  margin: 0px 5px !important;
  background: white;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    margin: 0px 5px 0px 5px!important;
    width: 90vw !important;
  }
`;
const Country = styled(Dropdown)`
  width: 200px !important;
  height: 43.11px !important;
  margin: 0px 5px !important;
  background: white;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    height: 45px !important;
  }
  .text {
    margin-top: 3px;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    height: 45px !important;
  }
  .flag {
    display: none !important;
  }
  .icon{
    margin-top: -5px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
const Desc = styled(Form.Input)`
  width: 550px;
  height: 38px;
  margin: 0px 5px !important;
  background: white;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
// const Type = styled(Form.Select)`
//   width: 100px;
//   height: 38px;
//   margin: 0px 5px !important;
//   border-radius: 5px;
//   > div {
//     min-width: 100px !important;
//   }
//     div {
//     color: rgb(35, 72, 91) !important;
//     font-size: 16px !important;
//   }
//   @media screen and (max-width: 745px){
//     width: 90vw !important;
//   }
// `;
