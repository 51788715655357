import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onPageChange(currentPage + 1);
  };
  const onPrevious = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className={classnames('pagination-container', { [className]: className })}>
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <li className={classnames('pagination-item', { disabled: currentPage === 1 })} onClick={onPrevious}>
        <div className="arrow left" />
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
        }
        return (
          /* eslint-disable-next-line react/jsx-no-bind */
          <li key={pageNumber} onClick={() => onPageChange(pageNumber)}
            className={classnames('pagination-item', { selected: pageNumber === currentPage })}>
            {pageNumber}
          </li>
        );
      })}
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <li className={classnames('pagination-item', { disabled: currentPage === lastPage })} onClick={onNext}>
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
