import React, { useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown, Button } from 'semantic-ui-react';
import { UserContext } from '../contexts/user_context';
import LanguageContext from '../contexts/language_context';
import LBFlag from '../svgs/lebanon_flag.svg';
import FranceFlag from '../svgs/france_flag.svg';
import UnitedKingdomFlag from '../svgs/united_kingdom_flag.svg';

export default function navbar() {
    const { user } = useContext(UserContext);
    const { language, setLanguage } = useContext(LanguageContext);

    const Color = {
        textDecoration: 'none',
        color: '#23485B',
        fontSize: '15px',
        fontFamily: 'Corbel Bold'
    };
    const ColorList = {
        color: '#23485B',
        fontSize: '15px',
        fontFamily: 'Corbel !important'
    };
    const Menu = {
        padding: '10px',
        background: '#F9F9F9',
        width: '100%'
    };
    const LangMenu = {
        padding: '10px',
        background: '#F9F9F9',
        width: '100%'
    };
    const token = localStorage.getItem('wazeefati_token');
    const navigate = useNavigate();
    const LogoutHandler = useCallback((l) => {
        localStorage.removeItem('wazeefati_token');
        setTimeout(() => navigate('/login'), 300);
    }, [navigate]);

    const LogoutHandlerButton = useCallback(() => {
        localStorage.removeItem('wazeefati_token');
        setTimeout(() => navigate('/'), 300);
    }, [navigate]);

    const SignupEmployer = useCallback(() => {
        localStorage.removeItem('wazeefati_token');
        setTimeout(() => navigate('/signup/employer'), 300);
    }, [navigate]);

    const SignupCandidate = useCallback(() => {
        localStorage.removeItem('wazeefati_token');
        setTimeout(() => navigate('/signup/candidate'), 300);
    }, [navigate]);

    const setLanguageEng = useCallback(() => {
        setLanguage('en');
    }, [setLanguage]);
    const setLanguageFrench = useCallback(() => {
        setLanguage('fr');
    }, [setLanguage]);
    const setLanguageArabic = useCallback(() => {
        setLanguage('ar');
    }, [setLanguage]);

    const goLogin = useCallback(() => navigate('/login'), [navigate]);

    const currentLangImg = language === 'en'
        ? UnitedKingdomFlag
        : language === 'fr'
        ? FranceFlag
        : LBFlag;

    return (
        <Container>
            <UILink to='/'><Logo src='https://wazeefati-s3.s3.eu-central-1.amazonaws.com/logo.png' /></UILink>
            <UnorderLink>
                <li>
                <UIDropdown style={Color} item text="For Employers">
                    <Dropdown.Menu style={Menu}>
                        {token
                        ? user && user.status === 'employer'
                        ? <UILink style={ColorList} onClick={LogoutHandler}>
                            <DropdownItem>Logout</DropdownItem>
                          </UILink>
                            : user && user.status === 'candidate' &&
                            <UILink style={ColorList} onClick={SignupEmployer}>
                                <DropdownItem>Sign Up/Login</DropdownItem>
                            </UILink>
                        : <UILink style={ColorList} to='/signup/employer'><DropdownItem>Sign Up/Login</DropdownItem></UILink>}
                    {user && user.status === 'employer' &&
                        <>
                            <UILink style={ColorList} to='/dashboard'><DropdownItem>Dashboard</DropdownItem></UILink>
                            <UILink style={ColorList} to='/about_me'><DropdownItem>Post A Job</DropdownItem></UILink>
                        </>}
                    <UILink style={ColorList} to='/candidates'><DropdownItem>Find Talents</DropdownItem></UILink>
                    </Dropdown.Menu>
                </UIDropdown>
                </li>
                <li>
                <UIDropdown style={Color} item text="For Candidates">
                    <Dropdown.Menu style={Menu}>
                    {token
                        ? user && user.status === 'candidate'
                        ? <UILink style={ColorList} onClick={LogoutHandler}>
                                <DropdownItem>Logout</DropdownItem>
                          </UILink>
                        : user && user.status === 'employer' &&
                        <UILink style={ColorList} onClick={SignupCandidate}>
                            <DropdownItem>Sign Up/Login</DropdownItem>
                        </UILink>
                        : <UILink style={ColorList} to='/signup/candidate'><DropdownItem>Sign Up/Login</DropdownItem></UILink>}
                        <UILink style={ColorList} to='/jobs'><DropdownItem>Find Jobs</DropdownItem></UILink>
                        {user && user.status === 'candidate' &&
                            <UILink style={ColorList} to='/resume'><DropdownItem>Edit Profile</DropdownItem></UILink>}
                    </Dropdown.Menu>
                </UIDropdown>
                </li>
                <li style={{ fontFamily: 'Corbel Bold !important' }}><UILink style={Color} to='/about_us'>About Us</UILink></li>
                <li style={{ fontFamily: 'Corbel Bold !important' }}><UILink style={Color} to='/contact_us'>Contact Us</UILink></li>
                <LangContainer>
                    <LoginContainer>
                        {token
                            ? <UIDropdownForUser style={Color} item text={user && user.full_name}>
                                    <Dropdown.Menu style={Menu}>
                                        <UILink style={ColorList} onClick={LogoutHandlerButton}>
                                            <DropdownItem>Logout</DropdownItem>
                                        </UILink>
                                    </Dropdown.Menu>
                              </UIDropdownForUser>
                            : <CustomButton onClick={goLogin}>Login</CustomButton>}
                    </LoginContainer>
                    <UIDropdown style={Color} item
                        text={<DropDiv><DropImg src={currentLangImg} /> {language.toUpperCase()}</DropDiv>}>
                        <Dropdown.Menu style={LangMenu}>
                            <LangItem onClick={setLanguageEng} active={language === 'en'}>
                                <ENLangImg src={UnitedKingdomFlag} />
                                <LangSpan>EN</LangSpan>
                            </LangItem>
                            <LangItem onClick={setLanguageFrench} active={language === 'fr'}>
                                <FRLangImg src={FranceFlag} />
                                <LangSpan>FR</LangSpan>
                            </LangItem>
                            <LangItem onClick={setLanguageArabic} active={language === 'ar'}>
                                <LBLangImg src={LBFlag} />
                                <LangSpan>AR</LangSpan>
                            </LangItem>
                        </Dropdown.Menu>
                    </UIDropdown>
                </LangContainer>
            </UnorderLink>
        </Container>
    );
};

const LoginContainer = styled.div`
`;

const LangSpan = styled.span`
    margin-top: 3px;
    margin-left: 10px;
`;

const ENLangImg = styled.img`
    width: 24px;
    margin-left: 2px;
`;

const FRLangImg = styled.img`
    width: 27px;
`;

const LBLangImg = styled.img`
    width: 24px;
    margin-left: 2px;
`;

const DropDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DropImg = styled.img`
    width: 24px !important;
    margin-top: 0 !important;
    margin: 0 !important;
    margin-right: 4px !important;
`;

const LangItem = styled.div`
    text-decoration: none;
    color: #23485B;
    font-size: 15px;
    font-family: 'Corbel' !important;
    cursor: pointer;
    font-weight: ${props => props.active ? '500' : '400'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px 0 4px;
    padding: 3px;
`;

const LangContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-left: auto;
    margin-right: 50px;
    margin-top: 8px;
    font-family: 'Corbel Bold' !important;
    margin-bottom: 5px;
`;

const UnorderLink = styled.ul`
    display: flex;
    width: 100%;
    list-style-type: none;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    align-items: center;
    font-size: 20px;
    margin-left: 40px;
    color: #38095E;
    @media screen and (max-width: 1200px){
        padding:0;
    }

    > li {
        padding-right: 30px;
        font-weight: 400;
    }
`;

const UILink = styled(Link)`
`;

const DropdownItem = styled(Dropdown.Item)`
    padding: 8px 10px 0 10px;
`;

const UIDropdown = styled(Dropdown)`
    font-family: 'Corbel Bold' !important;
`;
const UIDropdownForUser = styled(Dropdown)`
    margin: 0px 20px 2px 0;
    font-family: 'Corbel Bold' !important;
`;

const CustomButton = styled(Button)`
    cursor: pointer;
    background-color: transparent !important;
    font-size: 15px !important;
    font-family: "Corbel Bold" !important;
`;

const Logo = styled.img`
    width: 80px;
    margin-top: 7px;
    margin-left:30px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 9vh;
    margin: 1vh 1vw 0 1vw;
    margin-top: 1vh;
    margin-left: 1vw;
    background: #F9F9F9;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
`;
