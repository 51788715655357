import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useQueryState } from 'react-router-use-location-state';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import Pagination from '../../utils/pagination/pagination';
import Filter from './filter';
import Wave from 'react-wavify';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer.js';
import RequireUserAccess from '../../permissions/require_user_access';
import { getScreenWidth } from '../../helpers/screen_width';

const reducer = (state, action) => {
  switch (action.type) {
    case 'get-candidates-start':
      return {
        ...state,
        loading: state.refresh,
        cardsLoading: !state.refresh,
        errorMessage: null
      };
    case 'get-candidates-success':
      return {
        ...state,
        loading: false,
        candidates: action.candidates,
        totalCount: action.totalCount,
        cardsLoading: false,
        refresh: false,
        isFilterOpen: false
      };
    case 'get-candidates-failed': return { ...state, loading: false, cardsLoading: false, errorMessage: action.error };
    case 'on-page-change': return { ...state, currentPage: action.currentPage };
    case 'load-filter-error': return { ...state, loading: false, errorMessage: action.error };
    case 'load-filter-bubbles': return { ...state, filterBubbles: action.bubbles };
    case 'add-filter-bubble': return { ...state, filterBubbles: state.filterBubbles.concat(action.bubble) };
    case 'remove-filter-bubble':
      return { ...state, filterBubbles: state.filterBubbles.filter(x => x !== action.bubble) };
    case 'toggle-filter': return { ...state, isFilterOpen: !state.isFilterOpen };
    case 'toggle-filter-1': return { ...state, isFilterOpen: false };
    default: throw new Error('Unhandled action type: ' + action.type);
  }
};

const CandidatesPage = () => {
  const [state, dispatch] = useReducer(reducer, {
    candidates: [],
    filterBubbles: [],
    totalCount: 0,
    currentPage: 1,
    loading: true,
    cardsLoading: false,
    errorMessage: null,
    refresh: true,
    isFilterOpen: false
  });
  const {
    candidates,
    filterBubbles,
    totalCount,
    currentPage,
    loading,
    cardsLoading,
    errorMessage,
    isFilterOpen
  } = state;
  const cursor = useRef(null);
  const getMore = useRef(1);

  const [categoryFilter, setCategoryFilter] = useQueryState('category', '');
  const [experienceFilter, setExperienceFilter] = useQueryState('experience', '');
  const [uniFilter, setUniFilter] = useQueryState('uni', '');
  const [langFilter, setLangFilter] = useQueryState('lang', '');
  const [majorFilter, setMajorFilter] = useQueryState('major', '');
  // eslint-disable-next-line
  const [rateFilter, setRateFilter] = useQueryState('rate', '');
  const [searchQuery, setSearchQuery] = useQueryState('q', '');

  useEffect(() => {
    document.title = 'Wazeefati | Candidates';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: 'get-candidates-start' });
    const searchParams = new URLSearchParams(window.location.search);
    let categoryParams = searchParams.get('category');
    let experienceParams = searchParams.get('experience');
    let uniParams = searchParams.get('uni');
    let langParams = searchParams.get('lang');
    let majorParams = searchParams.get('major');
    let rateParams = searchParams.get('rate');
    let searchParam = searchParams.get('q');

    if (!categoryParams) categoryParams = '';
    if (!experienceParams) experienceParams = 0;
    if (!uniParams) uniParams = '';
    if (!langParams) langParams = '';
    if (!majorParams) majorParams = '';
    if (!rateParams) rateParams = '';
    if (!searchParam) searchParam = '';

    const direction = currentPage >= getMore.current ? 'after' : 'before';
    getApiClient().getCandidates(cursor.current, categoryParams, experienceParams, uniParams, majorParams,
      rateParams, langParams, searchParam, direction)
      .then(response => {
        dispatch({
          type: 'get-candidates-success',
          candidates: response.data.candidates,
          totalCount: response.data.totalCount
        });
        cursor.current = response.data.nextCursor;
        getMore.current = currentPage;
      })
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'get-candidates-failed', error: error })));
  }, [cursor, currentPage, categoryFilter, experienceFilter, uniFilter, langFilter, majorFilter,
      rateFilter, searchQuery]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const newFilteBubbles = [];
    const categoryParams = searchParams.get('category');
    const experienceParams = searchParams.get('experience');
    const uniParams = searchParams.get('uni');
    const langParams = searchParams.get('lang');
    const majorParams = searchParams.get('major');
    const searchParam = searchParams.get('q');

    categoryParams && categoryParams.split(',').map(param => {
      newFilteBubbles.push(param);
    });
    experienceParams && experienceParams.split(',').map(param => {
      newFilteBubbles.push('Experience: ' + param);
    });
    uniParams && uniParams.split(',').map(param => {
      newFilteBubbles.push(param);
    });
    majorParams && majorParams.split(',').map(param => {
      newFilteBubbles.push(param);
    });
    langParams && langParams.split(',').map(param => {
      newFilteBubbles.push(param);
    });
    searchParam && newFilteBubbles.push(searchParam);
    dispatch({ type: 'load-filter-bubbles', bubbles: newFilteBubbles });
  }, []);

  const screenWidth = getScreenWidth();

  useEffect(() => {
    if (screenWidth > 1146) {
      dispatch({ type: 'toggle-filter-1' });
    }
  }, [screenWidth]);

  const onPageChange = useCallback((currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch({ type: 'on-page-change', currentPage: currentPage });
  }, []);

  const filterError = useCallback((error) => {
    dispatch({ type: 'load-filter-error', error: error });
  }, []);

  const onAddBubble = useCallback((value) => {
    dispatch({ type: 'add-filter-bubble', bubble: value });
  }, []);

  const onRemoveBubble = useCallback((value) => {
    dispatch({ type: 'remove-filter-bubble', bubble: value });
  }, []);

  const onChangeCategoryFilter = useCallback((category) => {
    if (categoryFilter.includes(category)) {
      const newCategoryFilter = categoryFilter.split(',').filter(c => c !== category).join(',');
      setCategoryFilter(newCategoryFilter);
      onRemoveBubble(category);
    } else {
      const newCategoryFilter = categoryFilter ? `${categoryFilter},${category}` : category;
      setCategoryFilter(newCategoryFilter);
      onAddBubble(category);
    }
    cursor.current = null;
  }, [categoryFilter, setCategoryFilter, onAddBubble, onRemoveBubble]);

  const onChangeExperienceFilter = useCallback((experience) => {
    onRemoveBubble('Experience: ' + experienceFilter);
    setExperienceFilter(null);
    if (experience !== '0') {
      setExperienceFilter(experience);
      onAddBubble(`Experience: ${experience}`);
    }
    cursor.current = null;
  }, [experienceFilter, setExperienceFilter, onAddBubble, onRemoveBubble]);

  const onChangeUniFilter = useCallback((uni) => {
    if (uniFilter.includes(uni)) {
      const newUniFilter = uniFilter.split(',').filter(x => x !== uni).join(',');
      setUniFilter(newUniFilter);
      onRemoveBubble(uni);
    } else {
      const newUniFilter = uniFilter ? `${uniFilter},${uni}` : uni;
      setUniFilter(newUniFilter);
      onAddBubble(uni);
    }
    cursor.current = null;
  }, [uniFilter, setUniFilter, onAddBubble, onRemoveBubble]);

  const onChangeLangFilter = useCallback((lang) => {
    if (langFilter.includes(lang)) {
      const newFilter = langFilter.split(',').filter(x => x !== lang).join(',');
      setLangFilter(newFilter);
      onRemoveBubble(lang);
    } else {
      const newFilter = langFilter ? `${langFilter},${lang}` : lang;
      setLangFilter(newFilter);
      onAddBubble(lang);
    }
    cursor.current = null;
  }, [langFilter, setLangFilter, onAddBubble, onRemoveBubble]);

  const onChangeMajorFilter = useCallback((major) => {
    if (majorFilter.includes(major)) {
      const newMajorFilter = majorFilter.split(',').filter(x => x !== major).join(',');
      setMajorFilter(newMajorFilter);
      onRemoveBubble(major);
    } else {
      const newCategoryFilter = majorFilter ? `${majorFilter},${major}` : major;
      setMajorFilter(newCategoryFilter);
      onAddBubble(major);
    }
    cursor.current = null;
  }, [majorFilter, setMajorFilter, onAddBubble, onRemoveBubble]);

  const onChangeRateFilter = useCallback((rate) => {
    const newRate = { type: rate.type };
    if (rate.min && rate.min.length > 0) newRate.min = rate.min;
    if (!rate.min) setRateFilter(null);
    else setRateFilter(JSON.stringify(newRate));
    cursor.current = null;
  }, [setRateFilter]);

  const onChangeSearch = useCallback((input) => {
    if (!input) {
      onRemoveBubble(searchQuery);
      setSearchQuery(null);
    } else {
      onRemoveBubble(searchQuery);
      setSearchQuery(input);
      onAddBubble(input);
    }
    cursor.current = null;
  }, [onRemoveBubble, onAddBubble, setSearchQuery, searchQuery]);

  const toggleFilterFn = useCallback(() => dispatch({ type: 'toggle-filter' }), []);

  const onCrossBubble = useCallback((e) => {
    const name = e.target.id;

    const newFilter = categoryFilter.split(',').filter(x => x !== name).join(',');
    setCategoryFilter(newFilter);

    const newUniFilter = uniFilter.split(',').filter(x => x !== name).join(',');
    setUniFilter(newUniFilter);

    const newLFilter = langFilter.split(',').filter(x => x !== name).join(',');
    setLangFilter(newLFilter);

    const newMajorFilter = majorFilter.split(',').filter(x => x !== name).join(',');
    setMajorFilter(newMajorFilter);

    const newSFilter = searchQuery.split(',').filter(x => x !== name).join(',');
    setSearchQuery(newSFilter);

    if (name.includes('Experience')) {
      setExperienceFilter(null);
    }

    onRemoveBubble(name);
    cursor.current = null;
  }, [categoryFilter, uniFilter, searchQuery, langFilter, majorFilter, setCategoryFilter, setLangFilter,
      setUniFilter, setMajorFilter, setExperienceFilter, onRemoveBubble, setSearchQuery]);

  const paginationStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  };

  return (
    <div style={{ position: 'relative' }}>
    <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <Navbar />
    {loading
    ? <Loader active={loading} size='medium'>Loading</Loader>
    : <Container>
        <p style={{ color: 'red' }}>{errorMessage}</p>
        <FilterButton onClick={toggleFilterFn}>&#9776; Filters</FilterButton>
        <Flex>
          <div style={{ width: '100%' }}>
            {filterBubbles.length > 0 &&
              <FilterResult>
                <Paragraph style={{ fontSize: '18px' }}>Filter by:</Paragraph>
                <FilterBubble bubbles={filterBubbles} onRemoveBubble={onCrossBubble} />
              </FilterResult>}
            {cardsLoading
            ? <UILoader active={cardsLoading} size='medium'>Loading</UILoader>
            : <CardDiv>
                {candidates.length > 0
                  ? candidates.map(value => <Card key={value.id} data={value} screenWidth={screenWidth} />)
                  : <NoResultFound>No Result Found.</NoResultFound>}
                <PaginationBox>
                  {/* eslint-disable-next-line react/jsx-no-bind */}
                  <Pagination className={paginationStyle} onPageChange={page => onPageChange(page)} pageSize={5}
                    currentPage={currentPage} totalCount={totalCount} />
                </PaginationBox>
              </CardDiv>}
          </div>
          <Filter filterError={filterError} onChangeCategoryFilter={onChangeCategoryFilter}
            onChangeExperienceFilter={onChangeExperienceFilter} onChangeUniFilter={onChangeUniFilter}
            onChangeMajorFilter={onChangeMajorFilter} onChangeRateFilter={onChangeRateFilter}
            onChangeLangFilter={onChangeLangFilter} onChangeSearch={onChangeSearch} toggleFilterFn={toggleFilterFn}
            isFilterOpen={isFilterOpen} cFilter={categoryFilter} uniF={uniFilter} langF={langFilter}
            majorF={majorFilter} />
        </Flex>
      </Container>}
      <Footer />
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
  );
};

const NoResultFound = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 500;
  font-size: 20px;
  padding: 10px;
`;

const FilterButton = styled.p`
  display: none;
  @media screen and (max-width: 1146px) {
    display: block;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
  }
`;

const BottomWave = styled(Wave)`
  position: absolute;
  bottom: 270px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: 280px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const UILoader = styled(Loader)`
  position: relative !important;
`;

const FilterResult = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  margin-bottom: 20px;
  border-bottom: 1px solid black;

  p {
    margin-top: 6px;
  }
`;

const Flex = styled.div`
  display: flex;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  justify-content: space-between;
`;

const Container = styled.div`
  margin: 65px 0 158px 0;
  padding: 82px 152px 40px 152px;
  box-shadow: 0px 0px 14px 11px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 840px) {
    padding: 82px 100px 40px 100px;
  }
  @media screen and (max-width: 740px) {
    padding: 82px 60px 40px 60px;
  }
  @media screen and (max-width: 650px) {
    padding: 82px 40px 40px 40px;
  }
`;

const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 20px 0;
`;

const Card = ({ data, screenWidth }) => {
  const navigate = useNavigate();
  const openCandidateProfile = useCallback(() => navigate(`/candidate/${data.user_id}`), [data.user_id, navigate]);
  const maxBubbleNb = screenWidth <= 564 ? 2 : 4;

  return (
    <CardContainer onClick={openCandidateProfile}>
      <ColumnFlex>
        <EndFlex>
          <FullName>
            {screenWidth <= 606
              ? <>
                  {data.full_name}<br />
                  <Span>
                    {data.title}
                  </Span>
                </>
              : <>
                  {data.full_name} -&nbsp;
                  <Span>
                    {data.title}
                  </Span>
                </>}
          </FullName>
          <div>
            <Paragraph>Min Hourly Rate: ${data.min_hourly_charge}</Paragraph>
            <Paragraph>Max Hourly Rate: ${data.max_hourly_charge}</Paragraph>
          </div>
        </EndFlex>
        <CenterFlex>
          <BubblesContainer>
            <div style={{ display: 'flex' }}>
              {data.skills.slice(0, maxBubbleNb).map(value => value !== 'null' && <Bubble key={value}>{value}</Bubble>)}
              {data.skills.length > maxBubbleNb && <Bubble>...</Bubble>}
            </div>
          </BubblesContainer>
        </CenterFlex>
        <p>
          {data.strengths.length <= 112
            ? data.strengths
            : <span>{data.strengths.substring(0, 112)}...</span>}
        </p>
      </ColumnFlex>
    </CardContainer>
  );
};

const CardDiv = styled.div`
  margin-right: 22px;

  @media screen and (max-width: 890px) {
    margin-right: 0;
  }
`;

const Span = styled.span`
  font-family: 'Corbel';
  font-size: 14px;
`;

const CardContainer = styled.div`
  padding: 16px 34px 8px 34px;
  box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.25), inset -256px 0px 35px rgba(172, 172, 172, 0.25);
  border-top: 0.4px solid #000000;
  cursor: pointer;
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
`;

const FullName = styled.p`
  font-family: 'Corbel Bold';
  font-size: 20px;
  margin-bottom: 0;
  color: #23485B;
`;

const CenterFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BubblesContainer = styled.div`
  margin-top: 26px;
  margin-bottom: 26px;
`;

const Bubble = styled.div`
  border: 2px solid #23485B;
  color: #23485B;
  padding: 6px 12px 6px 12px;
  margin-left: 14px;
  border-radius: 12px;
  font-family: 'Corbel';
`;

const EndFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Paragraph = styled.p`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 16px;
  margin: 6px 0 6px 0;
`;

const FilterBubble = ({ bubbles, onRemoveBubble }) => {
  return (
    bubbles.map(x =>
      <FilterBubbleContainer key={x} id={x} role='button' onClick={onRemoveBubble}>
        <FilterBubbleText id={x}>
          {x} <span style={{ color: 'red' }} id={x} onClick={onRemoveBubble}>x</span>
        </FilterBubbleText>
      </FilterBubbleContainer>)
  );
};

const FilterBubbleContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  transition: all 150ms ease-in-out;
  margin-bottom: 4px;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.25);
  &:hover {
    border: 1px solid #aaa;
    div { color: #7f7f7f; }
  }
  &:not(:first-child) {
    margin-left: 12px;
  }
  &:last-child {
    margin-right: 12px;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const FilterBubbleText = styled.span`
  white-space: nowrap;
  color: #131313;
  transition: all 150ms ease-in-out;
  @media screen and (min-width: 1400px) { font-size: 1.1em; }
`;

export default RequireUserAccess(CandidatesPage);
