import axios from 'axios';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const _getHeaders = () => {
  const token = localStorage.getItem('wazeefati_token');
  return { Authorization: `Bearer ${token}` };
};

const get = (endpoint, id) => {
  if (id) return axios.get(REACT_APP_API_URL + endpoint + '/' + id, { headers: _getHeaders() });
  return axios.get(REACT_APP_API_URL + endpoint, { headers: _getHeaders() });
};

const post = (endpoint, data) => {
  return axios.post(REACT_APP_API_URL + endpoint, data, { headers: _getHeaders() });
};

class WebApiClient {
  loginUser(email, password) {
    const data = new FormData();
    data.append('email', email);
    data.append('password', password);
    return post('/api/users/login', data);
  }

  signupUser(companyName, fullName, email, phoneNumber, password, status, confirmPassword,
    regNb, regPlace, regYear, street, city, zipCode, country, hrName, hrNb, companyWebsite) {
    const data = new FormData();
    data.append('companyName', companyName);
    data.append('fullName', fullName);
    data.append('email', email);
    data.append('phoneNumber', phoneNumber);
    data.append('password', password);
    data.append('confirmPassword', confirmPassword);
    data.append('status', status);
    data.append('phoneNumber', phoneNumber);
    data.append('regNb', regNb);
    data.append('regPlace', regPlace);
    data.append('regYear', regYear);
    data.append('street', street);
    data.append('city', city);
    data.append('zipCode', zipCode);
    data.append('country', country);
    data.append('hrName', hrName);
    data.append('hrNb', hrNb);
    data.append('companyWebsite', companyWebsite);
    return post('/api/users/signup', data);
  }

  me() {
    return get('/api/users/me');
  }

  sendUpdatePasswordMail(email) {
    const data = new FormData();
    data.append('email', email);
    return post('/api/users/forgot_password', data);
  }

  verifyPasswordResetToken(token) {
    const data = new FormData();
    data.append('token', token);
    return post('/api/users/verify_password_reset_token', data);
  }

  resetPassword(password, token) {
    const data = new FormData();
    data.append('password', password);
    data.append('token', token);
    return post('/api/users/reset_password', data);
  }

  completeProfile(birthDate, gender, registrationNumber, phoneNumber, locality, file) {
    const data = new FormData();
    data.append('birthDate', birthDate);
    data.append('gender', gender);
    data.append('registrationNumber', registrationNumber);
    data.append('phoneNumber', phoneNumber);
    data.append('locality', locality);
    data.append('file', file);
    return post('/api/users/complete_profile', data);
  }

  getJob(jobId) {
    return get('/api/jobs/get_job', jobId);
  }

  submitJobApplication(jobId) {
    const data = new FormData();
    data.append('jobId', jobId);
    return post('/api/jobs/submit_application', data);
  }

  getCandidate(candidateId) {
    return get('/api/users/get_candidate_profile', candidateId);
  }

  getJobApplications(jobId, page) {
    const data = new FormData();
    data.append('jobId', jobId);
    data.append('page', page);
    return post('/api/jobs/get_job_applications', data);
  }

  getJobApplication(jobId) {
    const data = new FormData();
    data.append('jobId', jobId);
    return post('/api/users/get_job_application', data);
  }

  getEmployerAboutme() {
    return post('/api/users/getAboutEmployer');
  }

  changeJobStatus(jobId, jobStatus) {
    const data = new FormData();
    data.append('jobId', jobId);
    data.append('jobStatus', jobStatus);
    return post('/api/jobs/change_job_status', data);
  }

  getJobApplicationsCount(jobId) {
    return get('/api/jobs/get_job_applications_count', jobId);
  }

  getCandidates(cursor, categoryFilter, experienceFilter, uniFilter, majorFilter, rateFilter, langFilter,
    searchQuery, direction) {
    const data = new FormData();
    data.append('cursor', cursor);
    data.append('categoryFilter', categoryFilter);
    data.append('experienceFilter', experienceFilter);
    data.append('uniFilter', uniFilter);
    data.append('majorFilter', majorFilter);
    data.append('rateFilter', rateFilter);
    data.append('searchQuery', searchQuery);
    data.append('languageFilter', langFilter);
    data.append('direction', direction);
    return post('/api/users/get_candidates', data);
  }

  getJobs(cursor, categoryFilter, experienceFilter, levelFilter, rateFilter, searchQuery, direction) {
    const data = new FormData();
    data.append('cursor', cursor);
    data.append('categoryFilter', categoryFilter);
    data.append('experienceFilter', experienceFilter);
    data.append('levelFilter', levelFilter);
    data.append('rateFilter', rateFilter);
    data.append('searchQuery', searchQuery);
    data.append('direction', direction);
    return post('/api/jobs/get_jobs', data);
  }

  getCandidatesFilter(language) {
    const data = new FormData();
    data.append('language', language);
    return post('/api/filter/get_candidates_filter', data);
  }

  getJobsExpertiseFilter(language) {
    const data = new FormData();
    data.append('language', language);
    return post('/api/filter/get_jobs_expertise_filter', data);
  }

  getCandidateFlow() {
    return get('/api/users/get_candidate_profile_flow');
  }

  aboutYou(title, expertise, skills, strengths, minDaily, maxDaily, minHourly, maxHourly, experienceYears) {
    const data = new FormData();
    data.append('title', title);
    data.append('expertise', expertise);
    data.append('skills', skills);
    data.append('strengths', strengths);
    data.append('minDaily', minDaily);
    data.append('maxDaily', maxDaily);
    data.append('minHourly', minHourly);
    data.append('maxHourly', maxHourly);
    data.append('experienceYears', experienceYears);
    return post('/api/users/about-you', data);
  }

  education(educations) {
    const data = new FormData();
    data.append('educations', educations);
    return post('/api/users/education', data);
  }

  languages(languages) {
    const data = new FormData();
    data.append('languages', languages);
    return post('/api/users/languages', data);
  }

  awards(awards) {
    const data = new FormData();
    data.append('awards', awards);
    return post('/api/users/awards', data);
  }

  experiences(experiences) {
    const data = new FormData();
    data.append('experiences', experiences);
    return post('/api/users/experiences', data);
  }

  links(cv, facebookLink, instagramLink, linkedinLink) {
    const data = new FormData();
    data.append('cv', cv);
    data.append('facebook_link', facebookLink);
    data.append('instagram_link', instagramLink);
    data.append('linkedin_link', linkedinLink);
    return post('/api/users/social_links', data);
  }

  references(references) {
    const data = new FormData();
    data.append('references', references);
    return post('/api/users/references', data);
  }

  job(headline, projectTitle, description, descriptionFile, jobTimeline, duration, durationType, positionTitle,
    levelOfExperience, yearsOfExperience, expertise, skills, min, max, amountType, maximumProjectBudget,
    activity, city, country, timezone) {
    const data = new FormData();
    data.append('headline', headline);
    data.append('project_title', projectTitle);
    data.append('description', description);
    data.append('description_file', descriptionFile);
    data.append('job_timeline', jobTimeline);
    data.append('duration', duration);
    data.append('duration_type', durationType);
    data.append('position_title', positionTitle);
    data.append('level_of_experience', levelOfExperience);
    data.append('years_of_experience', yearsOfExperience);
    data.append('expertise', expertise);
    data.append('skills', skills);
    data.append('min', min);
    data.append('max', max);
    data.append('amount_type', amountType);
    data.append('maximum_project_budget', maximumProjectBudget);
    data.append('activity', activity);
    data.append('city', city);
    data.append('country', country);
    data.append('timezone', timezone);
    return post('/api/users/jobApi', data);
  }

  UpdateJob(headline, projectTitle, description, descriptionFile, jobTimeline, duration, durationType, positionTitle,
    levelOfExperience, yearsOfExperience, expertise, skills, min, max, amountType, maximumProjectBudget,
    activity, city, country, timezone, id) {
    const data = new FormData();
    data.append('headline', headline);
    data.append('project_title', projectTitle);
    data.append('description', description);
    data.append('description_file', descriptionFile);
    data.append('job_timeline', jobTimeline);
    data.append('duration', duration);
    data.append('duration_type', durationType);
    data.append('position_title', positionTitle);
    data.append('level_of_experience', levelOfExperience);
    data.append('years_of_experience', yearsOfExperience);
    data.append('expertise', expertise);
    data.append('skills', skills);
    data.append('min', min);
    data.append('max', max);
    data.append('amount_type', amountType);
    data.append('maximum_project_budget', maximumProjectBudget);
    data.append('activity', activity);
    data.append('city', city);
    data.append('country', country);
    data.append('timezone', timezone);
    data.append('id', id);
    return post('/api/users/UpdateJobApi', data);
  }

  getUniversities(searchQuery) {
    const data = new FormData();
    data.append('name', searchQuery);
    return post('/api/users/get_universities', data);
  }

  getExpertise(search) {
    const data = new FormData();
    data.append('search', search);
    return post('/api/filter/get_expertise', data);
  }

  submitRateService(rate) {
    const data = new FormData();
    data.append('rate', rate);
    return post('/api/users/submit_rate_service', data);
  }

  getEmployerJobs(cursor, direction) {
    const data = new FormData();
    data.append('cursor', cursor);
    data.append('direction', direction);
    return post('/api/users/get_employer_jobs', data);
  }

  verifyEmailVerificationToken(token) {
    const data = new FormData();
    data.append('token', token);
    return post('/api/users/verify_email_verification_token', data);
  }

  verifyUser(token) {
    const data = new FormData();
    data.append('token', token);
    return post('/api/users/verify_user', data);
  }

  getMyJobsCount() {
    return get('/api/jobs/get_my_jobs_count');
  }

  getMyJobs(page) {
    const data = new FormData();
    data.append('page', page);
    return post('/api/jobs/get_my_jobs', data);
  }

  getEmployer(employerId) {
    return get('/api/jobs/get_employer', employerId);
  }
}

export default WebApiClient;
