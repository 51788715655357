import { createBrowserRouter } from 'react-router-dom';
import LoginPage from './components/login/login_page';
import PageNotFound from './components/page_not_found/page_not_found';
import HomePage from './components/home/home_page';
import SignupPage from './components/signup/signup_page';
import ProcessFlow from './components/candidate_process_flow/process_flow';
import EmployeerProcessFlow from './components/employeer_process_flow/process_flow';
import ForgotPasswordPage from './components/forgot_password/forgot_password_page';
import CompleteProfilePage from './components/complete_profile/complete_profile_page';
import ResetPasswordPage from './components/reset_password/reset_password_page';
import JobsPage from './components/jobs/jobs_page';
import Terms from './components/terms/Terms';
import JobPost from './components/jobs/job_post';
import ProfilePage from './components/candidates/profile_page';
import JobApplications from './components/jobs/job_applications';
import About from './components/about/about';
import CandidatesPage from './components/candidates/candidates_page';
import Dashboard from './components/dashboard/dashboardPage';
import EmailVerificationPage from './components/email_verification/email_verification_page';
import ContactUsPage from './components/contact_us/contact_us_page';
import MyJobs from './components/jobs/my_jobs';
// import EmployerProfile from './components/jobs/employer_profile';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/page_not_found',
    element: <PageNotFound />
  },
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/login/:status',
    element: <LoginPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/signup/:status',
    element: <SignupPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/resume',
    element: <ProcessFlow />,
    errorElement: <PageNotFound />
  },
  {
    path: '/about_me/:id',
    element: <EmployeerProcessFlow />,
    errorElement: <PageNotFound />
  },
  {
    path: '/about_me',
    element: <EmployeerProcessFlow />,
    errorElement: <PageNotFound />
  },
  {
    path: '/forgot_password',
    element: <ForgotPasswordPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/complete_profile',
    element: <CompleteProfilePage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/terms',
    element: <Terms />,
    errorElement: <PageNotFound />
  },
  {
    path: '/reset_password',
    element: <ResetPasswordPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/jobs',
    element: <JobsPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/job/:id',
    element: <JobPost />,
    errorElement: <PageNotFound />
  },
  {
    path: '/candidate/:id',
    element: <ProfilePage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/job_applications/:id',
    element: <JobApplications />,
    errorElement: <PageNotFound />
  },
  {
    path: '/about_us',
    element: <About />,
    errorElement: <PageNotFound />
  },
  {
    path: '/candidates',
    element: <CandidatesPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    errorElement: <PageNotFound />
  },
  {
    path: '/email_verification',
    element: <EmailVerificationPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/contact_us',
    element: <ContactUsPage />,
    errorElement: <PageNotFound />
  },
  {
    path: '/my_jobs',
    element: <MyJobs />,
    errorElement: <PageNotFound />
  }
  // {
  //   path: '/employer/:id',
  //   element: <EmployerProfile />,
  //   errorElement: <PageNotFound />
  // }
]);
