import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { getApiClient } from '../../server/get_api_client';
import { Form, Search, Label } from 'semantic-ui-react';

const initialState = {
  loading: false,
  results: [],
  value: ''
};
function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };

    default:
      throw new Error();
  };
};
  /* eslint-disable-next-line react/jsx-no-bind */
  const resultRenderer = ({ title }) => <Label content={title} />;

export default function AboutYou({
   AddSkills, skills, expertise, popitExpertise, popitSkills, AddExpertise,
   AddStregths, Addtitle, AdddailyChargeMin, AdddailyChargeMax, AddhourlyChargeMin, AddhourlyChargeMax,
   stregths, title, dailyChargeMin, dailyChargeMax, hourlyChargeMin, hourlyChargeMax, popitExpertiseSearch,
   RemoveExpertise, RemoveSkills, AddExperience, yearsOfExperience
  }) {
  const [expertiseList, setexpertiseList] = useState([]);
  const CallApi = useCallback((value) => {
    if (value.length > 1) {
      setTimeout(() => {
        getApiClient().getExpertise(value)
          .then(response => setexpertiseList(response.data.expertise))
          .catch(error => console.log(error));
      }, 400);
    }
  }, []);

  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  /* eslint-disable-next-line react/jsx-no-bind */
  const { loading, results, value } = state;
  console.log(value);
  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback((e, { value, index }) => {
    CallApi(value);
    clearTimeout(timeoutRef.current);
    dispatch({ type: 'START_SEARCH', query: value });
    popitExpertiseSearch(value, index);
    timeoutRef.current = setTimeout(() => {
      if (value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' });
        return;
      };

      const re = new RegExp(_.escapeRegExp(value), 'i');
      const isMatch = (result) => re.test(result.title);

      dispatch({
        type: 'FINISH_SEARCH',
        results: _.filter(expertiseList, isMatch)
      });
    }, 300);
  }, [expertiseList, popitExpertiseSearch, CallApi]);
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  return (
    <Fields>
          <LeftContainer>
            <Form.Field>
              <LabelIt>
                your professional title
              </LabelIt>
              <Title value={title} placeholder='Computer Engineer' onChange={Addtitle} type='text' />
            </Form.Field>
            <Form.Field>
              <LabelIt>your key areas of expertise</LabelIt>
              <Box>
                <WrapBox>
                {expertise.map((newData, index) => {
                  /* eslint-disable-next-line */
                  return (
                  /* eslint-disable-next-line */
                <>
                  <SearchIt value={newData} onChange={popitExpertise} placeholder='Computer Engineer' index={index} loading={loading}
                  /* eslint-disable-next-line */
                    key={index}
                  /* eslint-disable-next-line */
                  onResultSelect={(e, data) => {
                  dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title });
                  popitExpertiseSearch(data.result.title, index);
                  }} onSearchChange={handleSearchChange}
                resultRenderer={resultRenderer} results={results} />
                {/* eslint-disable-next-line */}
                {index === 0 ? '' : <Rem onClick={() => RemoveExpertise(index)}>-</Rem>}
                </>
                );
                })}
              <Plus onClick={AddExpertise}>+</Plus>
                </WrapBox>
              </Box>
            </Form.Field>
            <Form.Field>
              <LabelIt>your key skills</LabelIt>
              <Box>
                <WrapBox>
                {skills.map((data, index) => {
                  /* eslint-disable-next-line */
                  return (
                  /* eslint-disable-next-line */
                  <>
                  {/* eslint-disable-next-line */}
                  <Drop key={index} type='text' onChange={popitSkills} index={index} value={data} placeholder='Skills' />
                  {/* eslint-disable-next-line */}
                  {index === 0 ? '' : <Rem onClick={() => RemoveSkills(index)}>-</Rem>}
                  </>
                  );
                })}
              <Plus onClick={AddSkills}>+</Plus>
                </WrapBox>
              </Box>
            </Form.Field>
          </LeftContainer>
          <RightContainer>
            <Form.Field>
              <LabelIt>
               your strengths in brief: please limit to 250 words
              </LabelIt>
              <Strenghts placeholder='what sets you apart from the crowd' value={stregths} onChange={AddStregths} type='text' />
              <LabelIt2>word count: {stregths.split(' ').length}</LabelIt2>
            </Form.Field>
            <Form.Field>
              <ChargeContainer>
                <LabelIt>
                  your average hourly charge
                </LabelIt>
                <InputContainer>
                <Digit value={hourlyChargeMin} placeholder='30' type='number' min='0' icon='dollar' onChange={AddhourlyChargeMin} />
                  <div style={{ marginTop: '8px' }}> - </div>
                <Digit value={hourlyChargeMax} placeholder='60' type='number' min='0' icon='dollar' onChange={AddhourlyChargeMax} />
                </InputContainer>
              </ChargeContainer>
            </Form.Field>
            <Form.Field>
              <ChargeContainer>
                <LabelIt>
                  your average daily charge for remote jobs
                </LabelIt>
                <InputContainer>
                <Digit value={dailyChargeMin} placeholder='100' type='number' min='0' icon='dollar' onChange={AdddailyChargeMin} />
                  <div style={{ marginTop: '8px' }}> - </div>
                <Digit value={dailyChargeMax} placeholder='200' type='number' min='0' icon='dollar' onChange={AdddailyChargeMax} />
                </InputContainer>
              </ChargeContainer>
              <ChargeContainer>
                <LabelIt>
                  years of experience:
                </LabelIt>
                <InputContainer>
                <Ex value={yearsOfExperience} placeholder='3' type='number' min='0' onChange={AddExperience} />
                </InputContainer>
              </ChargeContainer>
            </Form.Field>
          </RightContainer>
    </Fields>
  );
}
const Rem = styled.div`
    display: flex;
    margin: 11.5px 0px 0px -10px;
    font-size: 1.5rem;
    color: #FFC000;
    box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 40px;
    color: red;
    background: white;
  `;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  padding: 0px 10px;
  margin: auto;
  min-height: 70vh;
  min-width: 200px;
  @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 10px;
  align-items: flex-start;
  min-height: 70vh;
  min-width: auto;
  @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;

const ChargeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 760px;
    @media screen and (max-width: 1107px){
    width: 90vw;
  }
    @media screen and (max-width: 620px){
    justify-content: center;
  }
`;
const LabelIt2 = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-family: 'Corbel' !important;
  font-variant: small-caps;
  font-weight: 400 !important;
  font-size: 1.1rem !important;
  padding-left: 4px;
`;
const LabelIt = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-family: 'Corbel' !important;
  font-variant: small-caps;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
`;
const Fields = styled.div`
  display: flex;
  margin: 70px 0px 0px 0px;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height 100%;
`;
const Title = styled(Form.Input)`
  width: 400px;
  height: 38px;
  background: white;
  border-radius: 5px;
    @media screen and (max-width: 1107px){
    width: 90vw;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
`;
const Digit = styled(Form.Input)`
  width: 100px;
  height: 38px;
  margin: 0px !important;
  background: white;
  border-radius: 5px;
  .ui.input {
    width: 100px !important;
    height: 38px !important;
  }
  .ui.input input {
    width: 48px !important;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
`;
const Ex = styled(Form.Input)`
  width: 250px;
  height: 38px;
  margin: 0px !important;
  background: white;
  border-radius: 5px;
  .ui.input {
    width: 250px !important;
    height: 38px !important;
  }
  .ui.input input {
    width: 48px !important;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
`;
const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: auto;
  justify-content: space-between;
  width: 240px;
`;
const Strenghts = styled(Form.TextArea)`
  width: 760px;
  min-height: 200px;
  background: white;
  border-radius: 5px;
  > textarea {
    width: 731px !important;
    min-height: 200px;
    resize: none !important;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  
  }
    @media screen and (max-width: 1107px){
    width: 90vw !important;
    > textarea {
    box-sizing: border-box;
    width: 100% !important;
  }
  }
`;
const Drop = styled(Form.Input)`
  margin: 10px !important;
  width: 250px !important;
  min-height: 10px !important;
  height: 35px !important;
  input {
    width: 90px !important;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  
  }
`;
const Plus = styled.div`
  display: flex;
  margin: 15px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #FFC000;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #23485B;
  border-radius: 100px;
`;
const Box = styled.div`
  flex-wrap: wrap;
  width: 400px;
  margin-bottom: 20px;
  min-height: 200px;
  background: #FFFFFF;
  box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const WrapBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  min-height: 100px;
    @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const SearchIt = styled(Search)`
  margin: 10px !important;
  width: 250px !important;
  height: 38px;
  background: white;
  border-radius: 5px;
  input {
    width: 90px !important;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  
  }
  .results {
    height: 200px !important;
    overflow: auto;
  }
`;
