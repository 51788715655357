import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useQueryState } from 'react-router-use-location-state';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import Pagination from '../../utils/pagination/pagination';
import Filter from './filter';
import RequireUserAccess from '../../permissions/require_user_access';
import Navbar from '../../Common/navbar.js';
import Footer from '../../Common/footer.js';
import Wave from 'react-wavify';
import { getScreenWidth } from '../../helpers/screen_width';

const reducer = (state, action) => {
  switch (action.type) {
    case 'get-jobs-start':
      return {
        ...state,
        loading: state.refresh,
        cardsLoading: !state.refresh,
        errorMessage: null
      };
    case 'get-jobs-success':
      return {
        ...state,
        loading: false,
        jobs: action.jobs,
        totalCount: action.totalCount,
        cardsLoading: false,
        refresh: false,
        isFilterOpen: false
      };
    case 'get-jobs-failed': return { ...state, loading: false, cardsLoading: false, errorMessage: action.error };
    case 'on-page-change': return { ...state, currentPage: action.currentPage };
    case 'load-filter-error': return { ...state, loading: false, errorMessage: action.error };
    case 'load-filter-bubbles': return { ...state, filterBubbles: action.bubbles };
    case 'add-filter-bubble': return { ...state, filterBubbles: state.filterBubbles.concat(action.bubble) };
    case 'remove-filter-bubble':
      return { ...state, filterBubbles: state.filterBubbles.filter(x => x !== action.bubble) };
    case 'toggle-filter': return { ...state, isFilterOpen: !state.isFilterOpen };
    case 'toggle-filter-1': return { ...state, isFilterOpen: false };
    default: throw new Error('Unhandled action type: ' + action.type);
  }
};

const CandidatesPage = () => {
  const [state, dispatch] = useReducer(reducer, {
    jobs: [],
    filterBubbles: [],
    totalCount: 0,
    currentPage: 1,
    loading: true,
    cardsLoading: false,
    errorMessage: null,
    refresh: true,
    isFilterOpen: false
  });
  const { jobs, filterBubbles, totalCount, currentPage, loading, cardsLoading, errorMessage, isFilterOpen } = state;
  const cursor = useRef(null);
  const getMore = useRef(1);

  const [categoryFilter, setCategoryFilter] = useQueryState('expertise', '');
  const [experienceFilter, setExperienceFilter] = useQueryState('experience', '');
  const [profLevelFilter, setProfLevelFilter] = useQueryState('level', '');
  // eslint-disable-next-line
  const [rateFilter, setRateFilter] = useQueryState('rate', '');
  const [searchQuery, setSearchQuery] = useQueryState('search', '');

  useEffect(() => {
    document.title = 'Wazeefati | Jobs';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: 'get-jobs-start' });
    const searchParams = new URLSearchParams(window.location.search);
    let categoryParams = searchParams.get('expertise');
    let experienceParams = searchParams.get('experience');
    let profLevelParams = searchParams.get('level');
    let rateParams = searchParams.get('rate');
    let searchParam = searchParams.get('search');

    if (!categoryParams) categoryParams = '';
    if (!experienceParams) experienceParams = 0;
    if (!profLevelParams) profLevelParams = '';
    if (!rateParams) rateParams = '';
    if (!searchParam) searchParam = '';

    const direction = currentPage >= getMore.current ? 'after' : 'before';
    getApiClient().getJobs(cursor.current, categoryParams, experienceParams, profLevelParams,
      rateParams, searchParam, direction)
      .then(response => {
        dispatch({
          type: 'get-jobs-success',
          jobs: response.data.jobs,
          totalCount: response.data.totalCount
        });
        cursor.current = response.data.nextCursor;
        getMore.current = currentPage;
      })
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'get-jobs-failed', error: error })));
  }, [cursor, currentPage, categoryFilter, experienceFilter, profLevelFilter, rateFilter, searchQuery]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const newFilteBubbles = [];
    const categoryParams = searchParams.get('expertise');
    const experienceParams = searchParams.get('experience');
    const profLevelParams = searchParams.get('level');
    const searchParam = searchParams.get('search');

    categoryParams && categoryParams.split(',').map(param => {
      newFilteBubbles.push(param);
    });
    experienceParams && experienceParams.split(',').map(param => {
      newFilteBubbles.push('Experience: ' + param);
    });
    profLevelParams && profLevelParams.split(',').map(param => {
      newFilteBubbles.push(param);
    });
    searchParam && newFilteBubbles.push(searchParam);
    dispatch({ type: 'load-filter-bubbles', bubbles: newFilteBubbles });
  }, []);

  const screenWidth = getScreenWidth();

  useEffect(() => {
    if (screenWidth > 1146) {
      dispatch({ type: 'toggle-filter-1' });
    }
  }, [screenWidth]);

  const onPageChange = useCallback((currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch({ type: 'on-page-change', currentPage: currentPage });
  }, []);

  const filterError = useCallback((error) => {
    dispatch({ type: 'load-filter-error', error: error });
  }, []);

  const onAddBubble = useCallback((value) => {
    dispatch({ type: 'add-filter-bubble', bubble: value });
  }, []);

  const onRemoveBubble = useCallback((value) => {
    dispatch({ type: 'remove-filter-bubble', bubble: value });
  }, []);

  const onChangeCategoryFilter = useCallback((category) => {
    if (categoryFilter.includes(category)) {
      const newCategoryFilter = categoryFilter.split(',').filter(c => c !== category).join(',');
      setCategoryFilter(newCategoryFilter);
      onRemoveBubble(category);
    } else {
      const newCategoryFilter = categoryFilter ? `${categoryFilter},${category}` : category;
      setCategoryFilter(newCategoryFilter);
      onAddBubble(category);
    }
    cursor.current = null;
  }, [categoryFilter, setCategoryFilter, onAddBubble, onRemoveBubble]);

  const onChangeExperienceFilter = useCallback((experience) => {
    onRemoveBubble('Experience: ' + experienceFilter);
    setExperienceFilter(null);
    if (experience !== '0') {
      setExperienceFilter(experience);
      onAddBubble(`Experience: ${experience}`);
    }
    cursor.current = null;
  }, [experienceFilter, setExperienceFilter, onAddBubble, onRemoveBubble]);

  const onChangeProfLevelFilter = useCallback((level) => {
    if (profLevelFilter.includes(level)) {
      const newFilter = profLevelFilter.split(',').filter(x => x !== level).join(',');
      setProfLevelFilter(newFilter);
      onRemoveBubble(level);
    } else {
      const newFilter = profLevelFilter ? `${profLevelFilter},${level}` : level;
      setProfLevelFilter(newFilter);
      onAddBubble(level);
    }
    cursor.current = null;
  }, [profLevelFilter, setProfLevelFilter, onAddBubble, onRemoveBubble]);

  const onChangeRateFilter = useCallback((rate) => {
    const newRate = { type: rate.type };
    if (rate.min && rate.min.length > 0) newRate.min = rate.min;
    if (!rate.min) setRateFilter(null);
    else setRateFilter(JSON.stringify(newRate));
    cursor.current = null;
  }, [setRateFilter]);

  const onChangeSearch = useCallback((input) => {
    if (!input) {
      onRemoveBubble(searchQuery);
      setSearchQuery(null);
    } else {
      onRemoveBubble(searchQuery);
      setSearchQuery(input);
      onAddBubble(input);
    }
    cursor.current = null;
  }, [onRemoveBubble, onAddBubble, setSearchQuery, searchQuery]);

  const toggleFilterFn = useCallback(() => dispatch({ type: 'toggle-filter' }), []);

  const onCrossBubble = useCallback((e) => {
    const name = e.target.id;
    const newFilter = profLevelFilter.split(',').filter(x => x !== name).join(',');
    setProfLevelFilter(newFilter);

    const newSFilter = searchQuery.split(',').filter(x => x !== name).join(',');
    setSearchQuery(newSFilter);

    const newCategoryFilter = categoryFilter.split(',').filter(c => c !== name).join(',');
    setCategoryFilter(newCategoryFilter);

    if (name.includes('Experience')) {
      setExperienceFilter(null);
    }

    onRemoveBubble(name);
    cursor.current = null;
  }, [categoryFilter, profLevelFilter, searchQuery, setProfLevelFilter, setCategoryFilter,
      setExperienceFilter, onRemoveBubble, setSearchQuery]);

  const paginationStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  };

  return (
    <>
    <div style={{ position: 'relative' }}>
    <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      <Navbar />
      {loading
        ? <Loader active={loading} size='medium'>Loading</Loader>
        : <Container>
            <p style={{ color: 'red' }}>{errorMessage}</p>
            <FilterButton onClick={toggleFilterFn}>&#9776; Filters</FilterButton>
            <Flex>
              <div style={{ width: '100%' }}>
                {filterBubbles.length > 0 &&
                  <FilterResult>
                    <Paragraph style={{ fontSize: '18px' }}>Filter by:</Paragraph>
                    <FilterBubble bubbles={filterBubbles} onRemoveBubble={onCrossBubble} />
                  </FilterResult>}
                {cardsLoading
                ? <UILoader active={cardsLoading} size='medium'>Loading</UILoader>
                : <CardDiv>
                    {jobs.length > 0
                      ? jobs.map(value => <Card key={value.id} data={value} screenWidth={screenWidth} />)
                      : <NoResultFound>No Result Found.</NoResultFound>}
                    <PaginationBox>
                      {/* eslint-disable-next-line react/jsx-no-bind */}
                      <Pagination className={paginationStyle} onPageChange={page => onPageChange(page)} pageSize={6}
                        currentPage={currentPage} totalCount={totalCount} />
                    </PaginationBox>
                  </CardDiv>}
              </div>
              <Filter filterError={filterError} onChangeCategoryFilter={onChangeCategoryFilter}
                onChangeExperienceFilter={onChangeExperienceFilter}
                onChangeProfLevelFilter={onChangeProfLevelFilter} onChangeRateFilter={onChangeRateFilter}
                onChangeSearch={onChangeSearch} toggleFilterFn={toggleFilterFn} isFilterOpen={isFilterOpen}
                cFilter={categoryFilter} profFilter={profLevelFilter} />
            </Flex>
          </Container>}
          <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
    <Footer />
    </>
  );
};

const NoResultFound = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 500;
  font-size: 20px;
  padding: 10px;
`;

const FilterButton = styled.p`
  display: none;
  @media screen and (max-width: 1146px) {
    display: block;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
  }
`;

const UILoader = styled(Loader)`
  position: relative !important;
`;

const FilterResult = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  margin-bottom: 20px;
  border-bottom: 1px solid black;

  p {
    margin-top: 6px;
  }
`;

const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -180px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -180px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const Flex = styled.div`
  display: flex;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  justify-content: space-between;
`;

const Container = styled.div`
  margin: 65px 0 158px 0;
  padding: 82px 152px 40px 152px;
  box-shadow: 0px 0px 14px 11px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 1144px) {
    padding: 82px 100px 40px 100px;
  }

  @media screen and (max-width: 1024px) {
    padding: 82px 80px 40px 80px;
  }

  @media screen and (max-width: 920px) {
    padding: 82px 60px 40px 60px;
  }

  @media screen and (max-width: 710px) {
    padding: 82px 40px 40px 40px;
  }
`;

const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 20px 0;
`;

const Card = ({ data, screenWidth }) => {
  const navigate = useNavigate();

  const openJob = useCallback(() => navigate(`/job/${data.id}`), [data.id, navigate]);

  const date = new Date(data.date_created);
  const year = date.getFullYear();
  const day = ('0' + date.getDate()).slice(-2);
  const dateCreated = `${date.toLocaleDateString('en-US', { month: 'short' })} ${day},
    ${year} at ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}`;
  const maxBubbleNb = screenWidth <= 564 ? 2 : 4;

  console.log(data);

  return (
    <CardContainer onClick={openJob}>
      <ColumnFlex>
        <EndFlex>
          <FullName>
            {data.headline} -&nbsp;
            <Span>
              {data.project_title} -&nbsp;
            </Span>
            <Span>
              {data.position_title}
            </Span>
          </FullName>
          <div>
            <Paragraph>Min Hourly Rate: ${data.min}</Paragraph>
            <Paragraph>Max Hourly Rate: ${data.max}</Paragraph>
          </div>
        </EndFlex>
        <CenterFlex>
          <BubblesContainer>
            <div style={{ display: 'flex' }}>
              {data.skills.slice(0, maxBubbleNb).map(value => value !== 'null' && <Bubble key={value}>{value}</Bubble>)}
              {data.skills.length > maxBubbleNb && <Bubble>...</Bubble>}
            </div>
          </BubblesContainer>
        </CenterFlex>
        <Desc>
          <Paragraph1>
            {data &&
              data.description.length <= 55
                ? data.description
                : <span>{data.description.substring(0, 56)}...</span>}
          </Paragraph1>
          <Paragraph style={{ marginLeft: 'auto' }}>{dateCreated}</Paragraph>
        </Desc>
      </ColumnFlex>
    </CardContainer>
  );
};

const Desc = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1310px) {
    flex-direction: column;
    align-items: start;
  }
`;

const Paragraph1 = styled.p`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 16px;
  margin: 6px 0 6px 0 !important;
`;

const CardDiv = styled.div`
  margin-right: 22px;

  @media screen and (max-width: 890px) {
    margin-right: 0;
  }
`;

const Span = styled.span`
  font-family: 'Corbel';
  font-size: 14px;
`;

const CardContainer = styled.div`
  padding: 16px 34px 8px 34px;
  box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.25), inset -256px 0px 35px rgba(172, 172, 172, 0.25);
  border-top: 0.4px solid #000000;
  cursor: pointer;
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
`;

const FullName = styled.p`
  font-family: 'Corbel Bold';
  font-size: 20px;
  margin-bottom: 0;
  color: #23485B;
`;

const CenterFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BubblesContainer = styled.div`
  margin-top: 26px;
  margin-bottom: 26px;
`;

const Bubble = styled.div`
  border: 2px solid #23485B;
  color: #23485B;
  padding: 6px 12px 6px 12px;
  margin-left: 14px;
  border-radius: 12px;
  font-family: 'Corbel';
  font-weight: 400;
`;

const EndFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Paragraph = styled.p`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 16px;
  margin: 6px 0 6px 0 !important;
`;

const FilterBubble = ({ bubbles, onRemoveBubble }) => {
  return (
    bubbles.map(x =>
      <FilterBubbleContainer key={x} id={x} role='button' onClick={onRemoveBubble}>
        <FilterBubbleText id={x}>
          {x} <span style={{ color: 'red' }} id={x} onClick={onRemoveBubble}>x</span>
        </FilterBubbleText>
      </FilterBubbleContainer>)
  );
};

const FilterBubbleContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  transition: all 150ms ease-in-out;
  margin-bottom: 4px;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.25);
  &:hover {
    border: 1px solid #aaa;
    div { color: #7f7f7f; }
  }
  &:not(:first-child) {
    margin-left: 12px;
  }
  &:last-child {
    margin-right: 12px;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const FilterBubbleText = styled.span`
  white-space: nowrap;
  color: #131313;
  transition: all 150ms ease-in-out;
  @media screen and (min-width: 1400px) { font-size: 1.1em; }
`;

export default RequireUserAccess(CandidatesPage);
