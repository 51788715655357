import React from 'react';
import { Link } from 'react-router-dom';
import Algos from '../svgs/algoshub.svg';
import Paragon from '../svgs/paragonlogo.svg';
import styled from 'styled-components';

export default function footer() {
    const Header = {
        fontFamily: 'Corbel Bold',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '25px'
    };
    const Color = { color: '#FFC000', textDecoration: 'underline' };
    const Dec = { textDecoration: 'none' };
  return (
    <Container>
        <Link to='/'><Logo src='https://wazeefati-s3.s3.eu-central-1.amazonaws.com/logo.png' /></Link>
        <Div>
        <UnorderedList>
            <lh style={Header}>Explore</lh>
            <Link to='/' style={Dec}><Item>Home</Item></Link>
            <Link to='/about_us' style={Dec}><Item>About us</Item></Link>
            <Link to='/contact_us' style={Dec}><Item>Contact us</Item></Link>
        </UnorderedList>
        <UnorderedList>
            <lh style={Header}>Legal</lh>
            <Link style={Dec} to='/terms'><Item>Terms & Privacy</Item></Link>
        </UnorderedList>
        </Div>
        <Left>
            <div style={{ marginTop: '15px' }}>
                POWERED BY
            </div>
            <div style={{ marginTop: '7px' }}>
                <a target="_blank" rel="noreferrer" style={Color} href='https://www.paragon-bi.com'>
                    <SmallLogo src={Paragon} />
                </a>
                <span>&#38;</span>
                <a target="_blank" rel="noreferrer" style={Color} href='https://www.algoshub.tech'>
                    <SmallLogo src={Algos} />
                </a>
            </div>
        </Left>
        <a target='blank' href='https://sanadleb.org'><Logo1 src='https://wazeefati-s3.s3.eu-central-1.amazonaws.com/sanad.png' style={{ paddingBottom: '26px' }} /></a>
    </Container>
  );
};

const Container = styled.footer`
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    background-color: #F4F4F4;
    box-shadow: 0px -4px 23px rgba(0, 0, 0, 0.25);
    padding: 0 28px 0 28px;
    @media screen and (max-width: 1000px){
        flex-direction: column;
        padding-top: 20px;
    }
`;
const UnorderedList = styled.ul`
    height 30vh;
    padding-top: 30px;
    list-style-type: none;
    @media screen and (max-width: 1000px){
        text-align: center;
        padding-top: 0;
        height: 100%;
        margin-top: 46px;
    }
`;
const Item = styled.li`
    margin-top: 5px;
    font-family: 'Corbel';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 124.52%;
    color: #676767;
`;
const Logo = styled.img`
    width: 180px;
`;
const SmallLogo = styled.img`
    height: 40px;
    margin: 0px 5px -15px 5px;
    margin-bottom: -15px;
`;

const Logo1 = styled.img`
    width: 150px;
`;

const Left = styled.h4`
    display: flex;
    position: absolute;
    bottom: 5px;
    left: 25px;
    font-style: normal;
    font-size: 20px;
    line-height: 124.52%;
    text-align: center;
\    letter-spacing: 0.05em;
    font-family: 'Corbel Bold';
    > a {
        font-weight: 500;
    }
    
    @media screen and (max-width: 1000px){
        flex-direction: column;
        text-align: center;
        position: static;
        margin: 80px 0 80px 0;
    }
`;

const Div = styled.div`
    display: flex;
    padding-top: 50px;
    align-items:center;
    justify-Content: space-around;
    width: 55vw;
    @media screen and (max-width: 1000px){
        width: 250px;
        padding-top: 0;
        justify-Content: center;
        flex-direction: column;
    }
`;
