import React, { useReducer, useCallback, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import line from '../../pics/jobflow.svg';
import AboutYou from './about-you';
import { useParams } from 'react-router-dom';
import JobOpening from './job-opening';
import DesiredProfile from './desired-profile';
import Payment from './payment';
import { getApiClient } from '../../server/get_api_client';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import ProfileComplete from './profile-complete';
import Navbar from '../../Common/navbar.js';
import Footer from '../../Common/footer.js';
import Wave from 'react-wavify';
import { UserContext } from '../../contexts/user_context';
import RequireUserResume from '../../permissions/require_user_resume';
import { UserStatusEnum } from '../../enums/user_status_enum';
import Arrow from '../../svgs/Vector.png';
const ct = require('countries-and-timezones');

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/msword', // .doc format
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx format
  'application/vnd.ms-excel', // .xls format
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx format
  'application/vnd.ms-powerpoint', // .ppt format
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx format
  'application/vnd.ms-powerpoint.presentation.macroenabled.12', // .pptm format
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // .ppts format
  'image/*' // All image types
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'add-error': return { ...state, errorMessage: action.errorMessage };
    case 'change-step':
      return {
        ...state,
        step: action.step,
        errorMessage: action.step === 5 ? state.errorMessage : ''
      };
    case 'change-city': return { ...state, city: action.city };
    case 'change-country': return { ...state, country: action.country };
    case 'change-timezone': return { ...state, timezone: action.timezone };
    case 'change-headline': return { ...state, headline: action.headline };
    case 'change-projectTitle': return { ...state, projectTitle: action.projectTitle };
    case 'change-positiontitle': return { ...state, positiontitle: action.positiontitle };
    case 'change-describe': return { ...state, describe: action.describe };
    case 'change-jobTimeLine': return { ...state, jobTimeLine: action.jobTimeLine };
    case 'add-activity': return { ...state, activity: action.activity };
    case 'add-expertise': return { ...state, expertise: action.expertise };
    case 'add-skills': return { ...state, skills: action.skills };
    case 'add-experience': return { ...state, experience: action.experience };
    case 'add-captcha': return { ...state, captcha: action.captcha };
    case 'add-editorState': return { ...state, editorState: action.editorStateValue };
    case 'add-min': return { ...state, min: action.min };
    case 'add-max': return { ...state, max: action.max };
    case 'add-paymenttype': return { ...state, paymenttype: action.paymenttype };
    case 'change-loading': return { ...state, loading: action.loading };
    case 'add-maximumbudget': return { ...state, maximumbudget: action.maximumbudget };
    case 'add-level': return { ...state, level: action.level };
    case 'add-file': return { ...state, file: action.file, errorMessage: '' };
  };
};

const EmployeerProcessFlow = () => {
  const [state, dispatch] = useReducer(reducer, {
    step: 1,
    activity: [''],
    expertise: [''],
    skills: [''],
    experience: '',
    level: '',
    city: '',
    country: '',
    timezone: '',
    file: '',
    headline: '',
    projectTitle: '',
    positiontitle: '',
    describe: '',
    jobTimeLine: { option: '', time: '', type: '' },
    captcha: false,
    min: '',
    max: '',
    paymenttype: '',
    loading: false,
    maximumbudget: '',
    errorMessage: ''
  });
  const { user, setUser } = useContext(UserContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const {
    step, errorMessage, activity, city, country, timezone, file, expertise,
    headline, projectTitle, positiontitle, postPeriod, describe, jobTimeLine,
    skills, experience, level, captcha, min, max, maximumbudget, paymenttype,
    loading
  } = state;

  console.log(country);

  const { id } = useParams();
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.title = 'Wazeefati | About Me';
    window.scrollTo(0, 0);
  }, []);

  const AddActivity = useCallback((e, { value, index }) => {
    const array = activity;
    array[index] = value;
    dispatch({ type: 'add-activity', activity: array });
  }, [activity]);

  const AddNewActivity = useCallback(() => {
    if (activity.includes(null)) {

    } else {
        dispatch({ type: 'add-activity', activity: activity.concat(null) });
    }
  }, [activity]);

  const AddExpertise = useCallback((e, { value, index }) => {
    const array = expertise;
    array[index] = value;
    dispatch({ type: 'add-expertise', expertise: array });
  }, [expertise]);

  const AddExpertiseSearch = useCallback((value, index) => {
    const array = expertise;
    array[index] = value;
    dispatch({ type: 'add-expertise', expertise: array });
  }, [expertise]);
  const AddNewExpertise = useCallback(() => {
    if (expertise.includes('')) {

    } else {
        dispatch({ type: 'add-expertise', expertise: expertise.concat('') });
    }
  }, [expertise]);

  const AddSkills = useCallback((e, { value, index }) => {
    const array = skills;
    array[index] = value;
    dispatch({ type: 'add-skills', skills: array });
  }, [skills]);
  const AddNewSkills = useCallback(() => {
    if (skills.includes(null)) {

    } else {
        dispatch({ type: 'add-skills', skills: skills.concat(null) });
    }
  }, [skills]);

  const AddFile = useCallback((e) => {
    if (!ALLOWED_FILE_TYPES.includes(e.target.files[0].type)) {
      dispatch({ type: 'add-error', errorMessage: 'Invalid file type.' });
      return;
    }
    dispatch({ type: 'add-file', file: e.target.files[0] });
  }, []);
  const RemoveExpertise = useCallback((index) => {
    const array = expertise;
    array.splice(index, 1);
    dispatch({ type: 'add-expertise', expertise: array });
  }, [expertise]);
  const RemoveSkills = useCallback((index) => {
    const array = skills;
    array.splice(index, 1);
    dispatch({ type: 'add-skills', skills: array });
  }, [skills]);
  const RemoveActivity = useCallback((index) => {
    const array = activity;
    array.splice(index, 1);
    dispatch({ type: 'add-activity', activity: array });
  }, [activity]);
  const AddCity = useCallback((e, { value, index }) => {
    dispatch({ type: 'change-city', city: value });
  }, []);
  const AddTimezone = useCallback((value) => {
    dispatch({ type: 'change-timezone', timezone: value });
  }, []);
  const GetTimeZone = useCallback((country) => {
    const Con = ct.getCountry(country);
    const TimeZone = ct.getTimezone(Con.timezones[0]);
    AddTimezone(TimeZone.utcOffset / 60);
    // return TimeZone.utcOffset / 60;
  }, [AddTimezone]);
  const AddCountry = useCallback((_, { value }) => {
    dispatch({ type: 'change-country', country: value });
    GetTimeZone(value);
  }, [GetTimeZone]);

  const Addheadline = useCallback((e, { value, index }) => {
    dispatch({ type: 'change-headline', headline: value });
  }, []);
  const AddprojectTitle = useCallback((e, { value, index }) => {
    dispatch({ type: 'change-projectTitle', projectTitle: value });
  }, []);
  const Addpositiontitle = useCallback((e, { value, index }) => {
    dispatch({ type: 'change-positiontitle', positiontitle: value });
  }, []);
  const Adddescribe = useCallback((e, { value, index }) => {
    dispatch({ type: 'change-describe', describe: value });
  }, []);
  const AddjobTimeLineOption = useCallback((e, { value, index }) => {
    const object = jobTimeLine;
    object.option = value;
    if (value === 'open-ended' || value === 'full-time') {
      object.time = '0';
      object.type = 'non';
    } else {
      object.time = '';
      object.type = '';
    }
    dispatch({ type: 'change-jobTimeLine', jobTimeLine: object });
  }, [jobTimeLine]);
  const AddjobTimeLineTime = useCallback((e, { value, index }) => {
    const object = jobTimeLine;
    object.time = value;
    dispatch({ type: 'change-jobTimeLine', jobTimeLine: object });
  }, [jobTimeLine]);
  const AddjobTimeLineType = useCallback((e, { value, index }) => {
    const object = jobTimeLine;
    object.type = value;
    dispatch({ type: 'change-jobTimeLine', jobTimeLine: object });
  }, [jobTimeLine]);

  const AddDesc = useCallback((e) => {
    if (!ALLOWED_FILE_TYPES.includes(e.target.files[0].type)) {
      dispatch({ type: 'add-error', errorMessage: 'Invalid file type.' });
      return;
    }
    dispatch({ type: 'add-file', file: e.target.files[0] });
  }, []);

  const AddLevel = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-level', level: value });
  }, []);
  const AddExperience = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-experience', experience: value });
  }, []);

  const AddMaximumbudget = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-maximumbudget', maximumbudget: value });
  }, []);
  const AddMin = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-min', min: value });
  }, []);
  const AddMax = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-max', max: value });
  }, []);
  const AddPaymenttype = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-paymenttype', paymenttype: value });
  }, []);
  const AddCaptcha = useCallback(() => {
    dispatch({ type: 'add-captcha', captcha: true });
  }, []);

const changeStateBack = useCallback(() => {
  dispatch({ type: 'change-step', step: step - 1 });
  window.scrollTo(0, 0);
}, [step]);

const valAboutYou = useCallback(() => {
  let final = false;
    if (!city || !country || activity.length === 0 || !timezone) {
        dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
        final = false;
      } else {
        dispatch({ type: 'add-error', errorMessage: '' });
        final = true;
      }
    return final;
}, [city, country, activity, timezone]);

const valJobOpenning = useCallback(() => {
  if (!headline || !positiontitle || !jobTimeLine.option || !jobTimeLine.time || !jobTimeLine.type) {
    dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
    return false;
  }
  if (!describe && !file) {
    dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
    return false;
  }
  dispatch({ type: 'add-error', errorMessage: '' });
  return true;
}, [headline, positiontitle, describe, jobTimeLine, file]);

const valDesiredProfile = useCallback(() => {
  let final = false;
    if (expertise.length === 0 || skills.length === 0 || !experience || !level) {
        dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
        final = false;
      } else {
        dispatch({ type: 'add-error', errorMessage: '' });
        final = true;
      }
    return final;
}, [expertise, skills, experience, level]);

const valPayment = useCallback(() => {
  let final = false;
    if (!min || !max || !paymenttype) {
        dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
        final = false;
      } else {
        dispatch({ type: 'add-error', errorMessage: '' });
        final = true;
      }
    return final;
}, [min, max, paymenttype]);

const calltheapinow = useCallback(() => {
  if (captcha === true) {
    if (file && typeof file !== 'string' && !ALLOWED_FILE_TYPES.includes(file.type)) {
      dispatch({ type: 'add-error', errorMessage: 'Invalid file type.' });
      return;
    }
    dispatch({ type: 'change-loading', loading: true });
    const exp = expertise.filter(e => e !== null);
    const sk = skills.filter(e => e !== null);
    if (id != null) {
    getApiClient().UpdateJob(headline, projectTitle, describe, file, jobTimeLine.option, jobTimeLine.time,
      jobTimeLine.type, positiontitle, level, experience, JSON.stringify(exp),
      JSON.stringify(sk), min, max, paymenttype, maximumbudget,
      JSON.stringify(activity), city, country, timezone, id).then(() => {
          dispatch({ type: 'change-step', step: step + 1 });
          dispatch({ type: 'change-loading', loading: false });
          const userEdit = user;
          userEdit.has_resume = true;
          setUser(userEdit);
      }
        ).catch(() => {
          dispatch({ type: 'change-loading', loading: false });
          dispatch({ type: 'add-error', errorMessage: 'There is an error from our end' });
        });
  } else {
    getApiClient().job(headline, projectTitle, describe, file, jobTimeLine.option, jobTimeLine.time,
      jobTimeLine.type, positiontitle, level, experience, JSON.stringify(exp),
      JSON.stringify(sk), min, max, paymenttype, maximumbudget,
      JSON.stringify(activity), city, country, timezone).then(() => {
        const userEdit = user;
        userEdit.has_resume = true;
        setUser(userEdit);
        dispatch({ type: 'change-step', step: step + 1 });
        dispatch({ type: 'change-loading', loading: false });
      }).catch(() => {
        dispatch({ type: 'change-loading', loading: false });
        dispatch({ type: 'add-error', errorMessage: 'There is an error from our end' });
      });
  }
}
}, [headline, projectTitle, describe, file, jobTimeLine,
  positiontitle, experience, level, expertise, skills, min, max, paymenttype, maximumbudget,
  activity, city, country, timezone, id, captcha, step, user, setUser]);

  const HandleData = useCallback((res) => {
    if (res.data.job.expertise.length > 0) {
    dispatch({ type: 'add-expertise', expertise: res.data.job.expertise });
    };
    dispatch({ type: 'change-describe', describe: res.data.job.description });
    const timeline = res.data.job.job_timeline;
    const duration = res.data.job.duration;
    const durationType = res.data.job.duration_type;
    const object = { option: timeline, time: duration, type: durationType };
    dispatch({ type: 'change-jobTimeLine', jobTimeLine: object });
    dispatch({ type: 'change-headline', headline: res.data.job.headline });
    dispatch({ type: 'add-level', level: res.data.job.level_of_experience });
    dispatch({ type: 'add-min', min: res.data.job.min });
    dispatch({ type: 'add-max', max: res.data.job.max });
    dispatch({ type: 'add-maximumbudget', maximumbudget: res.data.job.maximum_project_budget });
    dispatch({ type: 'change-positiontitle', positiontitle: res.data.job.position_title });
    dispatch({ type: 'change-projectTitle', projectTitle: res.data.job.project_title });
    if (res.data.job.skills.length > 0) {
    dispatch({ type: 'add-skills', skills: res.data.job.skills });
    };
    dispatch({ type: 'add-experience', experience: res.data.job.years_of_experience });
    dispatch({ type: 'change-city', city: res.data.job.city });
    dispatch({ type: 'change-country', country: res.data.job.country });
    dispatch({ type: 'change-timezone', timezone: res.data.job.timezone });
    dispatch({ type: 'add-paymenttype', paymenttype: res.data.job.amount_type });
    if (res.data.job.activity.length > 0) {
    dispatch({ type: 'add-activity', activity: res.data.job.activity });
    };
  }, []);

  const HandleAboutMe = useCallback((res) => {
    dispatch({ type: 'change-city', city: res.data.job.city });
    dispatch({ type: 'change-country', country: res.data.job.country });
    dispatch({ type: 'change-timezone', timezone: res.data.job.timezone });
    if (res.data.job.activity.length > 0) {
      dispatch({ type: 'add-activity', activity: res.data.job.activity });
      };
  }, []);
  const changeState = useCallback(() => {
    if (step === 1 && valAboutYou()) {
      for (var i = 0; i < activity.length; i++) {
        if (activity[i] === '' || !activity[i]) {
          dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
          return;
        }
      }
      dispatch({ type: 'change-step', step: step + 1 });
    } else if (step === 2 && valJobOpenning()) {
      dispatch({ type: 'change-step', step: step + 1 });
    } else if (step === 3 && valDesiredProfile()) {
      for (var j = 0; j < expertise.length; j++) {
        if (expertise[j] === '' || !expertise[j]) {
          dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
          return;
        }
      }
      for (var k = 0; k < skills.length; k++) {
        if (skills[k] === '' || !skills[k]) {
          dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
          return;
        }
      }
      dispatch({ type: 'change-step', step: step + 1 });
    } else if (step === 4 && valPayment()) {
      calltheapinow();
    } else if (step === 5 && errorMessage) {
      dispatch({ type: 'change-step', step: step - 1 });
    }
    window.scrollTo(0, 0);
  }, [step, valAboutYou, valJobOpenning, valDesiredProfile, valPayment, calltheapinow, errorMessage, activity,
      expertise, skills]);

useEffect(() => {
  if (id != null) {
    getApiClient().getJobApplication(id).then(data => {
      HandleData(data);
    }).catch(err => console.log(err));
  } else {
    getApiClient().getEmployerAboutme().then(data => {
      HandleAboutMe(data);
    }).catch(err => console.log(err));
  }
}, [HandleData, id, HandleAboutMe]);

  const onClickOnStep = useCallback(stepNb => {
    dispatch({ type: 'change-step', step: stepNb });
  }, []);

  const Display = () => {
    if (step === 1) {
      return (<AboutYou AddCountry={AddCountry} AddCity={AddCity} AddActivity={AddActivity}
        changeState={changeState} AddTimezone={AddTimezone} activity={activity} AddNewActivity={AddNewActivity}
        country={country} city={city} timezone={timezone} RemoveActivity={RemoveActivity} />);
  } else if (step === 2) {
    return (<JobOpening Addheadline={Addheadline} AddprojectTitle={AddprojectTitle} file={file} AddFile={AddFile}
      Addpositiontitle={Addpositiontitle} Adddescribe={Adddescribe} AddjobTimeLineOption={AddjobTimeLineOption}
      AddjobTimeLineTime={AddjobTimeLineTime} AddjobTimeLineType={AddjobTimeLineType} changeState={changeState}
      headline={headline} projectTitle={projectTitle} positiontitle={positiontitle} postPeriod={postPeriod}
      describe={describe} jobTimeLine={jobTimeLine} changeStateBack={changeStateBack} AddDesc={AddDesc} />);
  } else if (step === 3) {
    return (<DesiredProfile AddLevel={AddLevel} AddExperience={AddExperience} AddExpertise={AddExpertise}
      AddNewExpertise={AddNewExpertise} AddSkills={AddSkills} AddNewSkills={AddNewSkills} skills={skills}
      expertise={expertise} changeState={changeState} experience={experience} level={level}
      changeStateBack={changeStateBack} AddExpertiseSearch={AddExpertiseSearch} RemoveExpertise={RemoveExpertise}
      RemoveSkills={RemoveSkills} />);
  } else if (step === 4) {
    return (<Payment AddCaptcha={AddCaptcha} AddMaximumbudget={AddMaximumbudget} AddMin={AddMin} AddMax={AddMax}
      AddPaymenttype={AddPaymenttype} changeState={changeState} min={min} max={max} paymenttype={paymenttype}
      maximumbudget={maximumbudget} changeStateBack={changeStateBack} />);
  } else if (step === 5) {
    return (<ProfileComplete />);
  }
};
  return (
    <>
    <div style={{ position: 'relative' }}>
    <Navbar />
    <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <TitleContainer>
      {user && user.company_name}
    </TitleContainer>
    <Form>
    <Container>
      <FromContainer>
      {step !== 5
      ? <ProcessBar>
        {windowWidth < 1005
          ? <>
              {step !== 1 &&
                <ButtonContainer1>
                  <Button src={Arrow} onClick={changeStateBack} />
                </ButtonContainer1>}
              <CircleContainer>
                <CircleWtihText>{step === 1 ? <><CurrentCricle>1</CurrentCricle> <CircleText>About You</CircleText></> : ''}
                </CircleWtihText>
                <CircleWtihText>{step === 2 ? <><CurrentCricle>2</CurrentCricle> <CircleText>Job Opening</CircleText></> : ''}
                </CircleWtihText>
                <CircleWtihText>{step === 3 ? <><CurrentCricle>3</CurrentCricle><CircleText>Desired Profile</CircleText></> : ''}
                </CircleWtihText>
                <CircleWtihText>{step === 4 ? <><CurrentCricle>4</CurrentCricle><CircleText>Payment</CircleText></> : ''}
                </CircleWtihText>
              </CircleContainer>
              <ButtonContainer>
                {step !== 4 &&
                  <Button style={{ transform: 'rotate(180deg)' }} src={Arrow} onClick={changeState} />}
              </ButtonContainer>
            </>
          : <>
              {step !== 1 &&
                <ButtonContainer1>
                  <Button src={Arrow} onClick={changeStateBack} />
                </ButtonContainer1>}
              {/* eslint-disable */}
              <CircleContainer>
                <CircleWtihText onClick={() => onClickOnStep(1)}>
                  {step === 1 ? <CurrentCricle>1</CurrentCricle> : <Cricle>1</Cricle>}
                  <CircleText>About You</CircleText>
                </CircleWtihText>
                <CircleWtihText onClick={() => onClickOnStep(2)}>
                  {step === 2 ? <CurrentCricle>2</CurrentCricle> : <Cricle>2</Cricle>}
                  <CircleText>Job Opening</CircleText>
                </CircleWtihText>
                <CircleWtihText onClick={() => onClickOnStep(3)}>
                  {step === 3 ? <CurrentCricle>3</CurrentCricle> : <Cricle>3</Cricle>}
                  <CircleText>Desired Profile</CircleText>
                </CircleWtihText>
                <CircleWtihText onClick={() => onClickOnStep(4)}>
                  {step === 4 ? <CurrentCricle>4</CurrentCricle> : <Cricle>4</Cricle>}
                  <CircleText>Payment</CircleText>
                </CircleWtihText>
              </CircleContainer>
              <ButtonContainer>
                {step !== 4 &&
                  <Button style={{ transform: 'rotate(180deg)' }} src={Arrow} onClick={changeState} />}
              </ButtonContainer>
            </>}
          <IMG src={line} />
        </ProcessBar>
        : ''}
        {errorMessage === '' ? '' : <Error>{errorMessage}</Error>}
        <Dimmer active={loading} inverted>
            <Loader size='large'>Loading</Loader>
        </Dimmer>
        {Display()}
      </FromContainer>
    </Container>
    </Form>
    <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
    <Footer />
    </>
  );
};

export default RequireUserResume(EmployeerProcessFlow, UserStatusEnum.EMPLOYER);

const ButtonContainer = styled.div`
  position: absolute;
  margin-left: 30px;
  left: 100%;
  @media screen and (max-width: 655px){
    left: 96%;
  }
  @media screen and (max-width: 458px){
    left: 94%;
  }
`;

const ButtonContainer1 = styled.div`
  position: absolute;
  margin-right: 30px;
  right: 100%;

  @media screen and (max-width: 655px){
    right: 96%;
  }
  @media screen and (max-width: 458px){
    right: 94%;
  }
`;

const Button = styled.img`
    height: 60px;
    cursor: pointer;
    margin-top: 4px;
    background: rgba(255, 255, 255, 0.58);
    border-radius: 10px;
    font-family: 'Corbel';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 90.69%;
/* or 36px */
  
    text-align: center;
    font-variant: small-caps;
  
    color: #23485B;
    @media screen and (max-width: 765px){
        ${'' /* width: 40vw; */}
    }
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 10vh;
  margin: 0px auto;
  margin-top: 50px;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 96px;
  /* identical to box height */
  color: #000000;
  @media screen and (max-width: 900px){
    font-size: 27px;
  }
`;

const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -105px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -105px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const Error = styled.div`
  margin-top: 10.5vh;
  text-align: center;
  font-family: 'Roboto';
  width: 100vw;
  color: #1B71E1;
  font-size: 20px;
  margin-bottm: -10vh
  height: 10vh;
`;
const Container = styled.div`
  display: flex;
  padding: 50px 0px;
  margin: -50px 0px 100px 0px;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const FromContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  width: 100vw;
  align-items: center;
  box-shadow: 0px 0px 14px 11px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.9);
`;
const ProcessBar = styled.div`
  position: relative;
  display:flex;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
  width: 80vw;
`;
const CircleContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65vw;
  @media screen and (max-width: 1005px){
    justify-content: center;
  }
`;
const Cricle = styled.div`
  display: flex;
  font-family: 'Corbel', sans-serif !important;
  font-weight: 600;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  background: #F4F4F4;
  border: 6px solid #23485B;
  border-radius: 50%;
  width: 70px;
  height: 70px;
`;
const CurrentCricle = styled.div`
  display: flex;
  font-family: 'Corbel', sans-serif !important;
  font-weight: 600;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  background: #FFE38D;
  border: 6px solid #23485B;
  border-radius: 50%;
  width: 85px;
  height: 85px;
`;
const CircleWtihText = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const CircleText = styled.div`
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  text-align: center;
`;
const IMG = styled.img`
  display:flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 10vh;
`;
