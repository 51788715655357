import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import CountryPhoneInput from '../../utils/country_phone_input';
import CloudUploadIcon from '../../svgs/cloud_upload_icon.svg';
import { UserContext } from '../../contexts/user_context';
import { UserStatusEnum } from '../../enums/user_status_enum';
import RequireUserResume from '../../permissions/require_user_resume';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer.js';
import Wave from 'react-wavify';

const GENDER_OPTIONS = [
  { key: 0, text: 'Male', value: 'male' },
  { key: 1, text: 'Female', value: 'female' }
];

const LOCALITY_OPTIONS = [
  { key: 0, text: 'Beirut', value: 'beirut' },
  { key: 1, text: 'North Lebanon', value: 'North Lebanon' },
  { key: 2, text: 'Mount Lebanon', value: 'Mount Lebanon' },
  { key: 3, text: 'South Lebanon', value: 'South Lebanon' },
  { key: 4, text: 'Bekaa', value: 'Bekaa' }
];

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/msword', // .doc format
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx format
  'application/vnd.ms-excel', // .xls format
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx format
  'application/vnd.ms-powerpoint', // .ppt format
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx format
  'application/vnd.ms-powerpoint.presentation.macroenabled.12', // .pptm format
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // .ppts format
  'image/*' // All image types
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'birth-date-changed': return { ...state, errorMessage: null, birthDate: action.birthDate };
    case 'gender-changed': return { ...state, errorMessage: null, gender: action.gender };
    case 'registration-number-changed':
      return { ...state, errorMessage: null, registrationNumber: action.registrationNumber };
    case 'phone-number-changed':
      return { ...state, errorMessage: null, phoneNumber: action.phoneNumber };
    case 'locality-changed': return { ...state, errorMessage: null, locality: action.locality };
    case 'file-changed': return { ...state, errorMessage: null, file: action.file };
    case 'submit-start': return { ...state, loading: true, errorMessage: null };
    case 'submit-failed': return { ...state, loading: false, errorMessage: action.error };
    case 'validation-error': return { ...state, errorMessage: action.error };
  }
};

const CompleteProfilePage = () => {
  const [state, dispatch] = useReducer(reducer, {
    birthDate: '',
    phoneNumber: '+961',
    gender: '',
    registrationNumber: '',
    locality: '',
    file: null,
    errorMessage: null,
    loading: false
  });
  const {
    birthDate,
    phoneNumber,
    gender,
    registrationNumber,
    locality,
    file,
    errorMessage,
    loading
  } = state;
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Wazeefati | Complete Profile';
    window.scrollTo(0, 0);
  }, []);

  const onChangeBirthDate = useCallback((e) => {
    dispatch({ type: 'birth-date-changed', birthDate: e.target.value });
  }, []);

  const onSelectGender = useCallback((_, { value }) => {
    dispatch({ type: 'gender-changed', gender: value });
  }, []);

  const onChangeRegNumber = useCallback((e) => {
    dispatch({ type: 'registration-number-changed', registrationNumber: e.target.value });
  }, []);

  const onChangeNumber = useCallback((phone) => {
    dispatch({ type: 'phone-number-changed', phoneNumber: phone });
  }, []);

  const onSelectLocality = useCallback((_, { value }) => {
    dispatch({ type: 'locality-changed', locality: value });
  }, []);

  const onChangeFile = useCallback((e) => {
    if (!ALLOWED_FILE_TYPES.includes(e.target.files[0].type)) {
      dispatch({ type: 'validation-error', error: 'Invalid file type.' });
      return;
    }
    dispatch({ type: 'file-changed', file: e.target.files[0] });
  }, []);

  const validate = useCallback(() => {
    if (!birthDate || !gender || !registrationNumber || !phoneNumber || !locality || !file) {
      dispatch({ type: 'validation-error', error: 'Please enter all fields.' });
      return false;
    }
    if (isNaN(registrationNumber)) {
      dispatch({ type: 'validation-error', error: 'Invalid Registration Number.' });
      return false;
    }
    // if file size greater than 3MB
    if (file.size > 3145728) {
      dispatch({ type: 'validation-error', error: 'File size must not be greater than 3MB.' });
      return false;
    }
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      dispatch({ type: 'validation-error', error: 'Invalid file type.' });
      return false;
    }
    return true;
  }, [birthDate, gender, registrationNumber, phoneNumber, locality, file]);

  const onSubmit = useCallback(() => {
    if (validate()) {
      dispatch({ type: 'submit-start' });
      getApiClient().completeProfile(birthDate, gender, parseInt(registrationNumber), '+' + phoneNumber, locality, file)
        .then(response => {
          setUser(response.data.user);
          navigate('/resume');
        })
        .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'submit-failed', error: error })));
    }
  }, [validate, birthDate, gender, registrationNumber, phoneNumber, locality, file, navigate, setUser]);

  return (
    <>
    <div style={{ position: 'relative' }}>
      <Navbar />
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <Container>
      <Title>YOUR PROFILE INFO</Title>
      <Form {...{ error: !!errorMessage }} {...{ loading: loading }} unstackable onSubmit={onSubmit}
        id='complete-profile-form'>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <FlexWrapper>
          <Form.Field>
            <label>Birth Date*</label>
            <Form.Input type='text' placeholder='dd-mm-yyyy' onChange={onChangeBirthDate} value={birthDate}
              style={{ width: '400px' }} />
          </Form.Field>
          <Form.Field>
            <label>Gender*</label>
            <Form.Select placeholder='Gender' onChange={onSelectGender} options={GENDER_OPTIONS}
              value={gender} style={{ width: '400px' }} />
          </Form.Field>
          <Form.Field>
            <label>Registration Number*</label>
            <Form.Input type='text' placeholder='ex: 123' onChange={onChangeRegNumber} value={registrationNumber}
              style={{ width: '400px' }} />
          </Form.Field>
        </FlexWrapper>
        <FlexWrapper style={{ marginTop: '20px' }}>
          <Form.Field>
            <label>Phone Number*</label>
            <CountryPhoneInput onChange={onChangeNumber} value={phoneNumber} width={400} />
          </Form.Field>
          <Form.Field>
            <label>Locality*</label>
            <Form.Select placeholder='Select' onChange={onSelectLocality} options={LOCALITY_OPTIONS}
              value={locality} style={{ width: '400px' }} />
          </Form.Field>
          <FileFlex>
            <label>Copy of ID/Passport*</label>
            <FileLabel htmlFor='file-upload' id='file-label'>
              <span>Upload here &nbsp;</span>
              <img src={CloudUploadIcon} alt='upload_icon' />
            </FileLabel>
            <input id='file-upload' type='file' onChange={onChangeFile} hidden />
            {!file
              ? <small style={{ color: 'red', fontSize: '11px' }}>PDF or IMG of Max Size 3MB*</small>
              : <small style={{ fontSize: '12px' }}>{file.name}</small>}
          </FileFlex>
        </FlexWrapper>
        <Button type='submit' form='complete-profile-form' content='SAVE' />
      </Form>
    </Container>
    <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
    <Footer />
    </>
  );
};

const Container = styled.div`
  margin: 60px 0 134px 0;
  padding: 40px 80px 40px 80px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 14px 11px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 1380px) {
    padding: 40px 140px 40px 140px;
  }
  @media screen and (max-width: 1105px) {
    padding: 40px 100px 40px 100px;
  }
  @media screen and (max-width: 1028px) {
    padding: 40px 60px 40px 60px;
  }
`;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -200px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -200px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const Title = styled.h1`
  font-family: 'Corbel';
  margin-bottom: 100px;
  text-align: center;
  font-size: 30px;
`;

const Button = styled(Form.Button)`
  text-align: center;

  .ui.button {
    margin-top: 44px;
    padding: 8px 32px 8px 32px;
    color: #23485B;
    background-color: #F9F9F9;
    font-family: 'Corbel';
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius : 10px;
    font-size: 18px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: 'Corbel';
  font-weight: 400;

  > div > div > .ui.input input, .ui.selection.dropdown, input {
    box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12) !important;
  }

  @media screen and (max-width: 936px) {
    justify-content: center;
  }
`;

const FileFlex = styled.div`
  display: flex;
  flex-direction: column;

  > label:not(#file-label) {
    font-family: 'Corbel';
    font-weight: bold;
    font-size: 13px;
  }
`;

const FileLabel = styled.label`
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-top: 5px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;

  :hover {
    border: 1px solid black;
  }
`;

export default RequireUserResume(CompleteProfilePage, UserStatusEnum.CANDIDATE);
