import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckMark from '../../svgs/Checkmark.svg';
import { Button, Loader } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import Wave from 'react-wavify';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer.js';

export default function EmailVerificationPage() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) navigate('/page_not_found');
  }, [searchParams, navigate, token]);

  useEffect(() => {
    document.title = 'Wazeefati | Email Verification';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getApiClient().verifyEmailVerificationToken(token)
      .then(response => {
        if (response.data.success) {
          getApiClient().verifyUser(token)
            .then(response => {
              if (response.data.success) {
                setLoading(false);
              } else {
                navigate('/page_not_found');
                setLoading(false);
              }
            })
            .catch(makeStandardApiErrorHandler(_ => {
              setLoading(false);
              navigate('/page_not_found');
            }));
        } else {
          navigate('/page_not_found');
          setLoading(false);
        }
      })
      .catch(makeStandardApiErrorHandler(_ => {
        setLoading(false);
        navigate('/page_not_found');
      }));
  }, [token, navigate]);

  const nextPage = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <div style={{ position: 'relative' }}>
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <Navbar />
    <Fields>
        <Loader active={loading} />
        <Image src={CheckMark} />
        <Congrats>Congratulations</Congrats>
        <SmallPara>
          You are now a member of the <span style={{ color: '#23485B' }}>Sanad Wazeefati platform.</span>
        </SmallPara>
        <span style={{ fontSize: '20px' }}>Please click <span style={{ fontWeight: 'bold' }}>login</span> to get started!</span>
        <ButtonS onClick={nextPage}>Login</ButtonS>
    </Fields>
    <Footer />
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
  );
};

const BottomWave = styled(Wave)`
  position: absolute;
  bottom: 270px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: 280px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0px 50px 0px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height 85vh;
  @media screen and (max-width: 745px){
    margin-bottom: 20px !important;
  }
`;
const Image = styled.img`
  width: 220px;
`;
const Congrats = styled.div`
margin-top: -30px;
font-family: 'Corbel Bold';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 55px;
/* identical to box height */

font-variant: all-small-caps;

color: #FFC000;
`;
const SmallPara = styled.div`
text-align: center;
width: 70vw;
margin-top: 1px;
font-family: 'Corbel';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 55px;
/* identical to box height */

font-variant: all-small-caps;

color: black;
`;
const ButtonS = styled(Button)`
    margin-top: 50px !important;
    background: rgba(255, 255, 255, 0.58) !important;
    width: 300px;
    margin-bottom: 40px !important;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.44), inset 0px 0px 5px #000000 !important;
    font-family: 'Corbel';
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 90.69%;
    /* or 36px */

    color: #23485B !important;

    text-align: center;
    font-variant: small-caps;
  
    color: #23485B;
`;
