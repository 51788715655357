import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Form, Icon } from 'semantic-ui-react';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Lines from '../../svgs/Lines.svg';
import { UserContext } from '../../contexts/user_context';
import Wave from 'react-wavify';
import Footer from '../../Common/footer.js';
import RequireLogout from '../../permissions/require_logout';
import Navbar from '../../Common/navbar';

const reducer = (state, action) => {
  switch (action.type) {
    case 'start-login': return { ...state, loading: true, errorMessage: null };
    case 'login-failed': return { ...state, loading: false, errorMessage: action.error };
    case 'email-changed': return { ...state, errorMessage: null, email: action.email };
    case 'password-changed': return { ...state, errorMessage: null, password: action.password };
    case 'toggle-show-password': return { ...state, passwordShown: !state.passwordShown };
    default: throw new Error('Unhandled action type: ' + action.type);
  }
};

const LoginPage = () => {
  const [state, dispatch] = useReducer(reducer, {
    email: '',
    password: '',
    passwordShown: false,
    loading: false,
    errorMessage: null
  });
  const { email, password, passwordShown, loading, errorMessage } = state;
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { status } = useParams();

  useEffect(() => {
    document.title = 'Wazeefati | Login';
    window.scrollTo(0, 0);
  }, []);

  const onChangeEmail = useCallback((e) => dispatch({ type: 'email-changed', email: e.target.value }), []);
  const onChangePassword = useCallback((e) => dispatch({ type: 'password-changed', password: e.target.value }), []);

  const validate = useCallback(() => {
    if (!email || !password) {
      dispatch({ type: 'login-failed', error: 'Please enter all fields.' });
      window.scrollTo(0, 0);
      return false;
    }
    return true;
  }, [email, password]);

  const onSubmit = useCallback(() => {
    if (validate()) {
      getApiClient().loginUser(email, password)
        .then(response => {
          if (response.data.success) {
            localStorage.setItem('wazeefati_token', response.data.token);
            setUser(response.data.user);
            if (response.data.user.status === 'candidate' && response.data.message === 'profile info missed') {
              navigate('/complete_profile');
              return;
            }
            if (response.data.user.status === 'candidate' && !response.data.user.has_resume) {
              navigate('/resume');
              return;
            }
            if (response.data.user.status === 'employer' && !response.data.user.has_resume) {
              return navigate('/about_me');
            }
            return navigate('/');
          } else {
            dispatch({ type: 'login-failed', error: response.data.message });
            window.scrollTo(0, 0);
          }
        })
        .catch(makeStandardApiErrorHandler(error => {
          dispatch({ type: 'login-failed', error: error });
          window.scrollTo(0, 0);
        }));
    }
  }, [email, password, validate, navigate, setUser]);

  const signUpText = status ? `/signup/${status}` : '/signup/candidate';

  const togglePasswordVisibility = useCallback(() => dispatch({ type: 'toggle-show-password' }), []);

  return (
    <TopContainer>
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      <Navbar />
      <BigTitle style={{ textAlign: 'center' }}>Login</BigTitle>
    <Container>
      <Flex>
        <FormContainer>
          <Title>Sanad Wazeefati</Title>
          <Form {...{ error: !!errorMessage }} {...{ loading: loading }} unstackable onSubmit={onSubmit}
            id='login-form'>
            {errorMessage && <p style={{ color: '#FFD042', fontSize: '20px' }}>{errorMessage}</p>}
            <Form.Field>
              <Label>Email:</Label>
              <Input type='email' placeholder='Ex: myusername@gmail.com' value={email}
                onChange={onChangeEmail} />
            </Form.Field>
            <Form.Field>
              <Label>Password:</Label>
              <Input type={passwordShown ? 'text' : 'password'} placeholder='***********' value={password}
                onChange={onChangePassword}
                icon={<Icon name={passwordShown ? 'eye slash' : 'eye'} link onClick={togglePasswordVisibility}
                  style={{ left: 'unset', right: '8px' }} />} />
            </Form.Field>
            <SignupLink to={signUpText}>Don’t have an account? Click here to Signup</SignupLink>
            <Button type='submit' form='login-form' content='Login' />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ForgotPassword to='/forgot_password'>Forgot your password?</ForgotPassword>
            </div>
          </Form>
        </FormContainer>
        <Text>
          <p>
            Please log in at least once every 6 months to ensure that your profile remains active and searchable.
          </p>
        </Text>
      </Flex>
    <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </Container>
    <Footer />
    </TopContainer>
  );
};

const TopContainer = styled.div`
position: relative;
background: url(${Lines});
`;

const FormContainer = styled.div`
  width: 400px;
  padding: 30px 20px 30px 20px;
  background-color: #2E607A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 33px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;

  .ui.loading.form:before {
    background: transparent;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Corbel Bold';
  margin-top: 20px;
  font-size: 18px;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin: 20px 0 220px 0;
`;
const BottomWave = styled(Wave)`
position: absolute;
bottom: -225px;
`;
const BottomWave2 = styled(Wave)`
position: absolute;
bottom: -225px;
`;
const BigTitle = styled.div`
  margin-top: 100px;
  font-size: 30px;
  font-variant: all-small-caps;
  color: Black;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const Title = styled.h1`
  color: #FFD042;
  font-family: 'Corbel Bold';
  font-weight: 100;
  font-size: 40px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
  font-variant: small-caps;
`;

const Label = styled.label`
  color: white !important;
  font-family: 'Corbel' !important;
  font-weight: 300 !important;
  font-size: 16px !important;
`;

const SignupLink = styled(Link)`
  color: white;
  text-decoration: underline;

  :hover {
    color: white;
  }
`;

const Input = styled(Form.Input)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
`;

const Button = styled(Form.Button)`
  text-align: center;

  .ui.button {
    margin-top: 34px;
    padding: 8px 30px 8px 30px;
    color: white;
    background-color: #23485B;
    font-family: 'Corbel';
    font-weight: 300;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius : 10px;
    font-size: 16px;
  }
`;

const ForgotPassword = styled(Link)`
  color: white;

  :hover {
    color: white;
  }
`;

export default RequireLogout(LoginPage);
