import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

const BLACKLISTED_COUNTRIES = ['il'];

const CountryPhoneInput = ({ onChange, value, width }) => (
  <Input onChange={onChange} value={value} country='lb' width={width} excludeCountries={BLACKLISTED_COUNTRIES} />);

const Input = styled(PhoneInput)`
  input {
    width: ${props => props.width === 400 ? '400px' : '100%'} !important;
    padding-left: 50px !important;
  }
`;

export default CountryPhoneInput;
