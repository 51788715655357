import React, { useCallback, useContext, useState } from 'react';
import { Input, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import LanguageContext from '../../contexts/language_context';
import LanguageText from '../language_text/language_text';

const SearchFilter = ({ onChangeSearch }) => {
  const [input, setinput] = useState(null);
  const { language } = useContext(LanguageContext);

  const handleSearch = useCallback((e) => {
    setinput(e.target.value);
  }, []);

  const submitSearch = useCallback(() => {
    onChangeSearch(input);
  }, [input, onChangeSearch]);

  // we made this manually because placeholders doesn't take dynamic text
  const placeholderText = language === 'en'
    ? 'Search by keyword'
    : language === 'fr'
    ? 'Rechercher par mot-clé'
    : 'ابحث';

  return (
    <>
      <Input placeholder={placeholderText} onChange={handleSearch} />
      <UIButton content={<LanguageText text='Search' />} onClick={submitSearch} />
    </>
  );
};

const UIButton = styled(Form.Button)`
  text-align: center;
  margin-bottom: 12px;

  .ui.button {
    margin-top: 34px !important;
    padding: 10px 28px 10px 28px;
    color: white;
    background-color: #23485B;
    font-family: 'Corbel';
    font-weight: 300;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.44), inset 0px 0px 5px #000000;
    border-radius : 8px;
    font-size: 18px;
  }
`;

export default SearchFilter;
