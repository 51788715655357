import React from 'react';
import NavbarDesk from './navbar_desktop.js';
import NavbarMob from './navbar_mobile.js';
import { getScreenWidth } from '../helpers/screen_width';
import { TABLET_WIDTH } from '../helpers/constants';

const Navbar = () => {
  return (
    getScreenWidth() > TABLET_WIDTH
      ? <NavbarDesk />
      : <NavbarMob />
  );
};

export default Navbar;
