import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { getApiClient } from '../../server/get_api_client';
import { Form, Search, Label, Dropdown } from 'semantic-ui-react';

const initialState = {
  loading: false,
  results: [],
  value: ''
};
function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };

    default:
      throw new Error();
  };
};
  /* eslint-disable-next-line react/jsx-no-bind */
  const resultRenderer = ({ title }) => <Label content={title} />;
  const options = [
    { key: 'Junior', text: 'Junior', value: 'Junior' },
    { key: 'Intermediate', text: 'Intermediate', value: 'Intermediate' },
    { key: 'Senior', text: 'Senior', value: 'Senior' }
  ];
export default function DesiredProfile(props) {
  const [expertiseList, setexpertiseList] = useState([]);
  const CallApi = useCallback((value) => {
    console.log(value);
    if (value.length > 2) {
      setTimeout(() => {
        getApiClient().getExpertise(value)
          .then(response => setexpertiseList(response.data.expertise))
          .catch(error => console.log(error));
      }, 400);
      console.log(expertiseList);
    }
  }, [expertiseList]);

  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  /* eslint-disable-next-line react/jsx-no-bind */
  const { loading, results, value } = state;
  console.log(value);
  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback((e, { value, index }) => {
    CallApi(value);
    clearTimeout(timeoutRef.current);
    dispatch({ type: 'START_SEARCH', query: value });
    props.AddExpertiseSearch(value, index);
    timeoutRef.current = setTimeout(() => {
      if (value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' });
        return;
      };

      const re = new RegExp(_.escapeRegExp(value), 'i');
      const isMatch = (result) => re.test(result.title);

      dispatch({
        type: 'FINISH_SEARCH',
        results: _.filter(expertiseList, isMatch)
      });
    }, 300);
  }, [expertiseList, props, CallApi]);
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  return (
    <Fields>
        <Top>
            <Form.Field>
              <LabelIt>key areas of expertise required</LabelIt>
              <Box>
                <WrapBox>
                {props.expertise.map((data, index) => {
                  return (
                  /* eslint-disable-next-line */
                  <>
                    <SearchIt placeholder='Expertise' value={data} onChange={props.AddExpertise} index={index} loading={loading}
                  /* eslint-disable-next-line */
                    key={index}
                  /* eslint-disable-next-line */
                  onResultSelect={(e, data) => {
                  dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title });
                  props.AddExpertiseSearch(data.result.title, index);
                  }} onSearchChange={handleSearchChange}
                resultRenderer={resultRenderer} results={results} />
                {/* eslint-disable-next-line */}
                {index === 0 ? '' : <Rem onClick={() => props.RemoveExpertise(index)}>-</Rem>}
                  </>
                    );
                })}
              <Plus onClick={props.AddNewExpertise}>+</Plus>
                </WrapBox>
              </Box>
            </Form.Field>
            <Form.Field style={{ marginBottom: '10px' }}>
              <LabelIt>key skills required</LabelIt>
              <Box>
                <WrapBox>
                {props.skills.map((data, index) => {
                  return (
                    /* eslint-disable-next-line */
                   <>
                    {/* eslint-disable-next-line */}
                   <Skills value={data} key={index} index={index} onChange={props.AddSkills} placeholder='Skills' />
                    {/* eslint-disable-next-line */}
                   {index === 0 ? '' : <Rem onClick={() => props.RemoveSkills(index)}>-</Rem>}
                   </>
                   );
                })}
              <Plus onClick={props.AddNewSkills}>+</Plus>
                </WrapBox>
              </Box>
            </Form.Field>
        </Top>
        <Bottom>
            <Container>
                <Field>
                    <LabelIt>level of experience required</LabelIt>
                    <Level value={props.level} onChange={props.AddLevel} placeholder='Experience' defaultValue='junior' options={options} search selection />
                </Field>
            </Container>
            <Container>
                <Field>
                    <LabelIt>years of experience required</LabelIt>
                    <SmallCon><MarginIt style={{ marginLeft: '0px' }}>More Than</MarginIt><NumberOfEx type='number' value={props.experience} onChange={props.AddExperience} /><MarginIt style={{ marginRight: '0px' }}>Years</MarginIt></SmallCon>
                </Field>
            </Container>
        </Bottom>
    </Fields>
  );
};

const SearchIt = styled(Search)`
  margin: 10px !important;
  width: 200px !important;
  height: 38px;
  background: white;
  border-radius: 5px;
  input {
    width: 80px !important;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    width: 90px !important;
  }
  .results {
    height: 200px !important;
    overflow: auto;
  }
`;
const Fields = styled.div`
  display: flex;
  margin: 70px 0px 40px 0px;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height 60vh;
`;
const MarginIt = styled.span`
    margin: 0px 10px;
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 88vw;
    min-height 35vh;
    @media screen and (max-width: 1107px){
        flex-direction: column;
      }
`;
const Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    min-height 20vh;
    @media screen and (max-width: 1107px){
        flex-direction: column;
      }
`;
const LabelIt = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-family: 'Corbel', sans-serif !important;
  font-variant: small-caps;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
`;
const Box = styled.div`
  flex-wrap: wrap;
  width: 550px;
  margin-bottom: 20px;
  min-height:240px;
  box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
    @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const WrapBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 550px;
  min-height: 10px;
    @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 400px;
    margin-bottom: 20px;
    min-height: 100px;
    @media screen and (max-width: 1107px){
        width: 90vw;
        justify-content: center;
    }
`;
const Field = styled(Form.Field)`
@media screen and (max-width: 1107px){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
`;
const Level = styled(Dropdown)`
  min-width: 100px !important;
  max-width: 100px !important;
  min-height: 10px !important;
  height: 15px !important;
  input {
    width: 100px !important;
    min-height: 10px !important;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
`;
const Skills = styled(Form.Input)`
  margin: 10px !important;
  margin-bottom: 10px !important;
  width: 200px !important;
  height: 30px !important;
  input {
    width: 120px !important;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    height: 21px !important;
  }
`;
const Plus = styled.div`
  display: flex;
  margin: 15px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #FFC000;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #23485B;
  border-radius: 100px;
`;
const Rem = styled.div`
  display: flex;
  margin: 11.5px 0px 0px -10px;
  font-size: 1.5rem;
  color: #FFC000;
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 40px;
  color: red;
  background: white;
`;
const NumberOfEx = styled(Form.Input)`
  width: 100px;
  height: 38px;
  margin: 0px !important;
  background: white;
  border-radius: 5px;
  .ui.input {
    width: 71px !important;
    height: 38px !important;
  }
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
const SmallCon = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  jusitfy-content: center;
`;
