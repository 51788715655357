import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Loader, Form } from 'semantic-ui-react';
import RequireUserAccess from '../../permissions/require_user_access';
import { UserStatusEnum } from '../../enums/user_status_enum';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import Pagination from '../../utils/pagination/pagination';
import Navbar from '../../Common/navbar.js';
import Footer from '../../Common/footer.js';
import Wave from 'react-wavify';
import ShowMoreText from 'react-show-more-text';
import { UserContext } from '../../contexts/user_context';

const reducer = (state, action) => {
  switch (action.type) {
    case 'get-count-start':
    case 'get-applications-start':
      return { ...state, loading: true, cardsLoading: state.currentPage !== 1 };
    case 'get-applications-failed':
    case 'get-count-failed': return { ...state, loading: false, cardsLoading: false, errorMessage: action.error };
    case 'get-count-success': return { ...state, loading: false, totalCount: action.totalCount };
    case 'get-applications-success':
      return {
        ...state,
        applications: action.applications,
        jobStatus: action.applications.length > 0 && action.applications[0].status,
        loading: false,
        cardsLoading: false
      };
    case 'change-status-start': return { ...state, statusLoading: true };
    case 'change-status-success': return { ...state, statusLoading: false, jobStatus: action.jobStatus };
    case 'change-status-failed': return { ...state, statusLoading: false, errorMessage: action.error };
    case 'on-page-change': return { ...state, currentPage: action.currentPage };
    default: throw new Error('Unhandled action type: ' + action.type);
  }
};

const JobApplications = () => {
  const [state, dispatch] = useReducer(reducer, {
    applications: [],
    jobStatus: 'OPEN',
    totalCount: 0,
    currentPage: 1,
    loading: true,
    statusLoading: false,
    cardsLoading: false,
    errorMessage: null
  });
  const {
    applications,
    jobStatus,
    totalCount,
    currentPage,
    loading,
    statusLoading,
    cardsLoading,
    errorMessage
  } = state;
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    document.title = 'Wazeefati | Job Applications';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: 'get-count-start' });
    getApiClient().getJobApplicationsCount(id)
      .then(response => dispatch({ type: 'get-count-success', totalCount: response.data.totalCount }))
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'get-count-failed', error: error })));
  }, [id]);

  useEffect(() => {
    dispatch({ type: 'get-applications-start' });
    getApiClient().getJobApplications(id, currentPage)
      .then(response => {
        if (!response.data.success) navigate('/page_not_found');
        else dispatch({ type: 'get-applications-success', applications: response.data.applications });
      })
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'get-applications-failed', error: error })));
  }, [id, currentPage, navigate]);

  const changeJobStatus = useCallback(() => {
    dispatch({ type: 'change-status-start' });
    getApiClient().changeJobStatus(id, jobStatus)
      .then(response => dispatch({ type: 'change-status-success', jobStatus: response.data.status }))
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'change-status-failed', error: error })));
  }, [id, jobStatus]);

  const onPageChange = useCallback((currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch({ type: 'on-page-change', currentPage: currentPage });
  }, []);

  const paginationStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  };

  const goToJob = useCallback(() => {
    navigate(`/about_me/${id}`);
  }, [id, navigate]);

  return (
    <>
    <div style={{ position: 'relative' }}>
      <Navbar />
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      {loading
        ? <Loader active={loading} size='medium'>Loading</Loader>
        : applications.length > 0 &&
          <Container>
            <p style={{ color: 'red' }}>{errorMessage}</p>
            <Flex jobStatus={jobStatus}>
              <div>
                <Headline>{applications[0].headline}</Headline>
                <ProjectTitle>Status: {jobStatus.toUpperCase()}</ProjectTitle>
              </div>
              <div style={{ display: 'flex' }}>
                {applications[0].id === user.id && <EditButton content='Edit Job' onClick={goToJob} />}
                <Button content={jobStatus === 'open' ? 'Hide from Listing' : 'Show in Listing'} loading={statusLoading}
                  onClick={changeJobStatus} style={{ fontSize: '18px', padding: '9px 20px 8px 20px', width: '180px' }} />
              </div>
            </Flex>
            <CandidateDiv>
              <p style={{ paddingRight: '20px' }}>Candidates</p>
            </CandidateDiv>
            {applications[0].full_name
              ? cardsLoading
                  ? <div style={{ height: '400px' }}><Loader active={cardsLoading} size='medium'>Loading</Loader></div>
                  : <>
                      {applications.map(value => <Card key={value.id} data={value} />)}
                      <PaginationBox>
                        {/* eslint-disable-next-line react/jsx-no-bind */}
                        <Pagination className={paginationStyle} onPageChange={page => onPageChange(page)} pageSize={6}
                          currentPage={currentPage} totalCount={totalCount} />
                      </PaginationBox>
                    </>
                : <NoApp>No Applications Yet.</NoApp>}
          </Container>}
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
      <BottomWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
    <Footer />
    </>
  );
};

const EditButton = styled(Form.Button)`
  text-align: center;
  margin-right: 20px;

  .ui.button {
    padding: 9px 20px 8px 20px;
    color: #23485B;
    background-color: rgba(255, 255, 255, 0.58);
    font-family: 'Corbel';
    font-weight: 400;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius : 8px;
    font-size: 18px;
  }
`;

const NoApp = styled.p`
  font-size: 20px;
  margin-top: 20px;
`;

const Container = styled.div`
  margin: 65px 0 158px 0;
  padding: 82px 152px 40px 152px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  min-height: 600px;

  @media screen and (max-width: 840px) {
    padding: 82px 100px 40px 100px;
  }
  @media screen and (max-width: 740px) {
    padding: 82px 60px 40px 60px;
  }
  @media screen and (max-width: 650px) {
    padding: 82px 40px 40px 40px;
  }
`;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -165px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -165px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .ui.button {
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-family: 'Corbel';
    color: ${props => props.jobStatus === 'open' ? '#DB2D2D' : '#30BF47'};;
    font-weight: 400 !important;
    width: 130px;
  }

  @media screen and (max-width: 610px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;

    .ui.button {
      margin-top: 6px;
    }
  }
`;

const Headline = styled.h1`
  font-family: 'Corbel Bold';
  font-weight: 400;
  color: #23485B;
`;

const ProjectTitle = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 400;
  font-size: 18px;
  margin-top: -16px;
  margin-bottom: 2px;
`;

const CandidateDiv = styled.div`
  display: flex;
  justify-content: end;
  border-bottom: 1px solid black;
  font-size: 18px;
  font-family: 'Corbel Bold';
  font-weight: 400;
`;

const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 20px 0;
`;

const Card = ({ data }) => {
  const navigate = useNavigate();

  const openCandidateProfile = useCallback(() => navigate(`/candidate/${data.id}`), [data.id, navigate]);

  return (
    data.full_name &&
      <CardContainer onClick={openCandidateProfile}>
        <ColumnFlex>
          <EndFlex>
            <FullName>{data.full_name}</FullName>
            <div>
              <Paragraph>Min Hourly Rate: ${data.min_hourly_charge}</Paragraph>
              <Paragraph>Max Hourly Rate: ${data.max_hourly_charge}</Paragraph>
            </div>
          </EndFlex>
          <CenterFlex>
            <BubblesContainer>
              <div style={{ display: 'flex' }}>
                {data.skills.slice(0, 9).map(value => <Bubble key={value}>{value}</Bubble>)}
                {data.skills.length > 9 && <Bubble>...</Bubble>}
              </div>
            </BubblesContainer>
          </CenterFlex>
          <ShowMoreText more={<MoreLink>Show More</MoreLink>} less={<MoreLink>Show Less</MoreLink>}
            anchorClass={{ textDecoration: 'none' }}>
            <p>{data.strengths}</p>
          </ShowMoreText>
        </ColumnFlex>
      </CardContainer>
  );
};

const MoreLink = styled.a`
  color: #2E607A;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
    color: #2E607A;
  }
`;

const CardContainer = styled.div`
  padding: 16px 34px 8px 34px;
  box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.25), inset -256px 0px 35px rgba(172, 172, 172, 0.25);
  border-top: 0.4px solid #000000;
  cursor: pointer;
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
`;

const FullName = styled.p`
  font-family: 'Corbel Bold';
  font-size: 20px;
  margin-bottom: 0;
`;

const CenterFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BubblesContainer = styled.div`
  margin-top: 26px;
  margin-bottom: 26px;
`;

const Bubble = styled.div`
  border: 2px solid #23485B;
  color: #23485B;
  padding: 6px 12px 6px 12px;
  margin-left: 14px;
  border-radius: 12px;
  font-family: 'Corbel';
  font-weight: 400;
`;

const EndFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Paragraph = styled.p`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 16px;
  margin: 6px 0 6px 0;
`;

export default RequireUserAccess(JobApplications, UserStatusEnum.EMPLOYER);
