import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Form, Search, Label, Dropdown, Checkbox } from 'semantic-ui-react';
import { getApiClient } from '../../server/get_api_client';
import Data from '../../countriesData';

const options = [
  { key: 'Coursework', text: 'Coursework', value: 'Coursework' },
  { key: 'Certificate', text: 'Certificate', value: 'Certificate' },
  { key: 'Diploma', text: 'Diploma', value: 'Diploma' },
  { key: 'Bachelor\'s', text: 'Bachelor\'s', value: 'Bachelor\'s' },
  { key: 'Master\'s', text: 'Master\'s', value: 'Master\'s' },
  { key: 'PhD or Equivalent', text: 'PhD or Equivalent', value: 'PhD or Equivalent' }
];

const language = [
  { key: 'Arabic', text: 'Arabic', value: 'Arabic' },
  { key: 'English', text: 'English', value: 'English' },
  { key: 'French', text: 'French', value: 'French' },
  { key: 'German', text: 'German', value: 'German' },
  { key: 'Spanish', text: 'Spanish', value: 'Spanish' },
  { key: 'Chinese', text: 'Chinese', value: 'Chinese' },
  { key: 'Japanese', text: 'Japanese', value: 'Japanese' },
  { key: 'Russian', text: 'Russian', value: 'Russian' },
  { key: 'Italian', text: 'Italian', value: 'Italian' },
  { key: 'Dutch', text: 'Dutch', value: 'Dutch' },
  { key: 'Other', text: 'Other', value: 'Other' }
];

const level = [
  { key: 'Beginner', text: 'Beginner', value: 'Beginner' },
  { key: 'Fluent', text: 'Fluent', value: 'Fluent' },
  { key: 'Professional', text: 'Professional', value: 'Professional' }
];

const initialState = {
  loading: false,
  results: [],
  value: ''
};

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };

    default:
      throw new Error();
  };
};

const resultRenderer = ({ name }) => <Label content={name} />;
export default function Education(props) {
  const [universities, setUniversities] = useState([]);
  const CallApi = useCallback((value) => {
    console.log('This value', value);
    if (value.length > 3) {
      setTimeout(() => {
        getApiClient().getUniversities(value)
          .then(response => setUniversities(response.data.uni))
          .catch(error => console.log(error));
      }, 1000);
    }
  }, []);

  // const Unis = useCallback(() => {
  //   // axios('http://universities.hipolabs.com/search', {params: {name: }})
  // });
  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  /* eslint-disable-next-line react/jsx-no-bind */
  const { loading, results, value } = state;
  const currentYear = new Date().getFullYear();
  console.log(value);
  console.log(currentYear);
  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback((e, { value, index }) => {
    CallApi(value);
    clearTimeout(timeoutRef.current);
    dispatch({ type: 'START_SEARCH', query: value });
    props.AddeducationInstitutionSearch(value, index);
    timeoutRef.current = setTimeout(() => {
      if (value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' });
        return;
      };

      const re = new RegExp(_.escapeRegExp(value), 'i');
      const isMatch = (result) => re.test(result.name);

      dispatch({
        type: 'FINISH_SEARCH',
        results: _.filter(universities, isMatch)
      });
    }, 300);
  }, [props, CallApi, universities]);
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Fields>
      <Bigger>
        <Reminder>Please start with your most recent degree</Reminder>
        {props.education.map((data, index) => {
          return (
            /* eslint-disable-next-line */
      <Container key={index}>
      <Form.Field>
        <LabelText>from</LabelText>
        <Digit value={data.education_from} onChange={props.AddeductionFrom} index={index} type='text' placeholder='YYYY' />
      </Form.Field>
      <Dash>-</Dash>
      <Form.Field>
        <LabelText>to</LabelText>
        <Digit disabled={data.present} value={data.education_to} onChange={props.AddeductionTo} index={index} type='text' placeholder='YYYY' />
        <Checkbox checked={data.present} index={index} onClick={props.educationPresent} style={{ margin: '10px 0px 0px 10px' }} label={{ children: 'Present' }} />
      </Form.Field>
      <Form.Field style={{ marginTop: '-1.83px' }}>
      <LabelText>institution</LabelText>
      <Inst>
      <RadioContainer>
        <Radio onChange={props.AddeducationInstitutionType} checked={props.education[index].institution_type === 'Uni'} index={index} value='Uni' label='University' />
        <Radio onChange={props.AddeducationInstitutionType} checked={props.education[index].institution_type === 'Other'} index={index} value='Other' label='Other' />
      </RadioContainer>
        {props.education[index].institution_type === 'Uni'
        /* eslint-disable-next-line react/jsx-no-bind */
      ? <SearchIt value={data.institution} onChange={props.AddeducationInstitution} index={index} loading={loading}
      /* eslint-disable-next-line react/jsx-no-bind */
       onResultSelect={(e, data) => {
        dispatch({ type: 'UPDATE_SELECTION', selection: data.result.name });
       props.AddeducationInstitutionSearch(data.result.name, index);
       }} onSearchChange={handleSearchChange}
        resultRenderer={resultRenderer} results={results} />
      : <Name value={data.institution} onChange={props.AddeducationInstitution} index={index} placeholder='AUB' />}
      </Inst>
      </Form.Field>
      <Form.Field style={{ marginTop: '0.83px' }}>
        <LabelText>country</LabelText>
        <Country index={index} onChange={props.AddeducationCountry} value={data.country} placeholder='Select Country' fluid search selection options={Data} />
      </Form.Field>
      <Form.Field>
        <LabelText>degree</LabelText>
        <Degree value={data.degree} onChange={props.AddeducationDegree} index={index} placeholder='Select Degree' options={options} />
      </Form.Field>
      <Form.Field>
        <LabelText>major</LabelText>
        <Major value={data.major} onChange={props.AddeducationMajor} index={index} placeholder='Computer Engineering' />
      </Form.Field>
      {/* eslint-disable-next-line */}
      {index === 0 ? '' : <Rem onClick={() => props.RemoveEducation(index)}>-</Rem>}
      </Container>);
              })}
      <Plus onClick={props.Addeduction}>+</Plus>
      </Bigger>
      <Bottom>
      <Form.Field style={{ marginTop: '20px' }}>
        <LabelText style={{ marginLeft: '10px' }}>language proficiency</LabelText>
      <Flexit>
        {props.language.map((data, index) => {
          console.log('look here', props.language);
          return (
            /* eslint-disable-next-line react/jsx-no-bind */
        <SmallFlex key={data}>
          <Language value={props.language[index].language} onChange={props.Addlanguage} index={index} placeholder='Language' options={language} />
          <Language value={props.language[index].level} onChange={props.Addlevel} index={index} placeholder='Level' options={level} />
          {/* eslint-disable-next-line */}
          {index === 0 ? '' : <RemLan onClick={() => props.RemoveLanguage(index)}>-</RemLan>}
        </SmallFlex>);
        })}
        <NewLang onClick={props.Addarraylang}>+</NewLang>
      </Flexit>
      </Form.Field>
      </Bottom>
    </Fields>
  );
}

    const Rem = styled.div`
    display: flex;
    margin: 31.5px 0px 0px -10px;
    font-size: 1.5rem;
    color: #FFC000;
    box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 40px;
    cursor: pointer;
    color: red;
    background: white;
    @media screen and (max-width: 765px){
      width: 90vw;
      margin: 31.5px 0px 0px 0px;
    }
  `;
  const Reminder = styled.div`
    width: 100%;
    border-bottom: 1px solid black;
    font-family: 'Corbel';
    font-style: italic;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    font-variant: all-small-caps;
    color: rgba(0, 0, 0, 0.48);
    margin-bottom: 40px;
    margin-top: 40px;
  `;
    const RemLan = styled.div`
    display: flex;
    margin: 2px 0px 0px -10px;
    font-size: 1.5rem;
    color: #FFC000;
    box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 40px;
    cursor: pointer;
    color: red;
    background: white;
    @media screen and (max-width: 765px){
      width: 100px;

      margin: 2px 0px 0px -10px;
    }
  `;
const Dash = styled.div`
  margin-top: 30px;
  font-size: 30px;
  @media screen and (max-width: 745px){
    display: none;
  }
`;
const Bottom = styled.div`
  position: relative;
  margin-top: 50px;
  min-height: 100px;
  width: 80%;
`;
const SmallFlex = styled.div`
  display: flex;
  margin: 5px;
  justify-content: space-between;
  width: 400px;
  @media screen and (max-width: 765px){
    width: 90vw;
  }
`;
const Flexit = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (max-width: 765px){
    align-items: center;
    justify-content: center;
  }
`;
const Bigger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 745px){
    margin-bottom: 35px !important;
  }
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0px 50px 0px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  min-height 60vh;
  @media screen and (max-width: 745px){
    margin-bottom: 20px !important;
  }
`;
const NewLang = styled.div`
  display: flex;
  margin: 10px 15px 15px 15px;
  font-size: 1.5rem;
  color: #FFC000;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #23485B;
  border-radius: 100px;
`;
const Plus = styled.div`
  position: absolute;
  right: -15px;
  bottom: -40px;
  display: flex;
  margin: 15px;
  font-size: 1.5rem;
  color: #FFC000;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #23485B;
  border-radius: 100px;
`;
const Inst = styled.div`
  display: flex;
  height: 43.11px !important;
  box-sizing: border-box;
  margin: 0px 5px !important;
  align-items: center;
  justify-content: space-between;
  width: 365px;
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 79vw;
  margin-bottom: 25px;
  @media screen and (max-width: 745px){
    justify-content: center;
    margin-bottom: 20px !important;
    border-top: 1px solid black;
    width: 90vw !important;
  }
`;
const LabelText = styled.label`
  color: black !important;
  margin: 0px 5px 10px 5px!important;
  font-family: 'Corbel' !important;
  font-variant: small-caps;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
`;
const Digit = styled(Form.Input)`
  width: 100px;
  height: 43.11px !important;
  background: white;
  margin: 0px 5px !important;
  border-radius: 5px;
  > input{
    height: 35px;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  }
`;
const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0px 5px!important;
  width: 100px;
  margin-top: 5px !important;
  height: 42px;
  background: white;
  box-shadow: 0px 0px 0.1px 1px rgba(0, 0, 0, 0.156);
  border-radius: 5px;
    @media screen and (max-width: 745px){
    width: 30vw !important;
  }
`;
const Radio = styled(Form.Radio)`
  width: 85px;
  margin: 0px !important;
`;
const Name = styled(Form.Input)`
  width: 250px;
  height: 38px;
  background: white;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    margin: 0px 5px 0px 5px!important;
    width: 60vw !important;
  }
`;
const SearchIt = styled(Search)`
  width: 250px;
  height: 38px;
  background: white;
  border-radius: 5px;
  @media screen and (max-width: 745px){
    margin: 0px 5px 0px 5px!important;
    width: 60vw !important;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  .results {
    height: 200px !important;
    overflow: auto;
  }
`;

const Country = styled(Dropdown)`
  width: 150px !important;
  height: 43.11px !important;
  margin: 0px 5px !important;
  background: white;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    height: 45px !important;
  }
  .text {
    margin-top: 3px;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    height: 45px !important;
  }
  .flag {
    display: none !important;
  }
  .icon{
    margin-top: -5px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
const Major = styled(Form.Input)`
  width: 250px;
  height: 38px;
  margin: 0px 5px !important;
  background: white;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
const Degree = styled(Form.Select)`
  width: 150px;
  height: 43.11px !impotant;
  margin: 0px 5px !important;
  border-radius: 5px;
  > div {
    min-width: 100px !important;
  }
    div {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
const Language = styled(Form.Select)`
  width: 200px !important;
  height: 38px;
  margin: 0px 5px !important;
  border-radius: 5px;
  > div {
    min-width: 150px !important;
  }
    div {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
