import React, { useContext } from 'react';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import styled from 'styled-components';
import LanguageText from '../../components/language_text/language_text';
import LanguageContext from '../../contexts/language_context';

export class AccordionGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlyOpenAccordionKey: props.defaultOpen || null
    };
  }

  openAccordion(key) {
    this.setState((prevState) => {
      return prevState.currentlyOpenAccordionKey === key
        ? { currentlyOpenAccordionKey: null }
        : { currentlyOpenAccordionKey: key };
    });
  }

  render() {
    return (
      <AccordionGroupContainer>
        {React.Children.map(this.props.children, (child, i) => {
          if (child == null) return null;
          return React.cloneElement(child, {
            isOpen: this.props.allOpen || this.state.currentlyOpenAccordionKey === child.props.key2,
            currentlyOpenAccordionKey: this.openAccordion.bind(this, child.props.key2)
          });
        })}
      </AccordionGroupContainer>
    );
  }
}

export const Accordion = ({
  smaller,
  name,
  currentlyOpenAccordionKey,
  isOpen,
  children,
  topBorder,
  color,
  titleColor
}) => {
  const { language } = useContext(LanguageContext);
  return (
    <AccordionGroupWrapper smaller={smaller} topBorder={topBorder}>
      <AccordionHeader onClick={currentlyOpenAccordionKey} smaller={smaller} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <AccordionTitle smaller={smaller} color={titleColor}>
          <LanguageText text={name} />
        </AccordionTitle>
        <AccordionButton smaller={smaller}>
          <Icon color={color} name={isOpen ? 'chevron up' : 'chevron down'} />
        </AccordionButton>
      </AccordionHeader>
      <div style={{ display: isOpen ? 'block' : 'none', fontFamily: 'Corbel' }}>
        {children}
      </div>
    </AccordionGroupWrapper>
  );
};

const AccordionGroupContainer = styled.div`
  margin: 8px 0;
`;

const AccordionGroupWrapper = styled.div`
  width: 100%;
  border-top: ${props => props.topBorder ? '1px #d0d0d0 solid' : 'none'};
  border-bottom: 2px #d0d0d0 solid;
  padding-bottom: 8px;
  padding-bottom: ${props => props.smaller ? 2 : 8}px;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: ${props => props.smaller ? '4px 0' : '8px 0'};
`;

const AccordionTitle = styled.h1`
  font-size: ${props => props.smaller ? 1.2 : 2}em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  padding-left: 8px;
  color: ${props => props.color ? props.color : 'black'};
`;

const AccordionButton = styled.button`
  border: none;
  border-radius: 0;
  color: #131313;
  background: none;
  font-size:  ${props => props.smaller ? 1.2 : 2}em;
  font-weight: 400;
  text-align: center;
  padding: 0;
  cursor: pointer;
  margin: 4px 4px;
  user-select: none;
  &:focus {
    outline:none !important;
  }
`;
