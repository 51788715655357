import React, { useContext } from 'react';
import LanguageContext from '../../contexts/language_context';
import enTranslations from '../../languages/english.json';
import arTranslations from '../../languages/arabic.json';
import frTranslations from '../../languages/french.json';
import './style.css';

const tranlsations = {
  en: enTranslations,
  ar: arTranslations,
  fr: frTranslations
};

const LanguageText = ({ text, withoutTranslation }) => {
  const { language } = useContext(LanguageContext);
  const translatedText = tranlsations[language][text];

  if (withoutTranslation) return <span>{tranlsations.en[text]}</span>;
  return <span dangerouslySetInnerHTML={{ __html: translatedText }} />;
};

export default LanguageText;
