import React, { useCallback, useEffect, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import RequireUserAccess from '../../permissions/require_user_access';
import { UserStatusEnum } from '../../enums/user_status_enum';
import Icon from '../../svgs/icons8-person-64 2.svg';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from '../../utils/pagination/pagination';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer.js';
import Wave from 'react-wavify';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import { UserContext } from '../../contexts/user_context';

const Dashboard = () => {
    const [jobs, setJobs] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [errorMessage, setErrorMessage] = useState(null);
    const cursor = useRef(null);
    const getMore = useRef(1);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const direction = currentPage >= getMore.current ? 'after' : 'before';
        getApiClient().getEmployerJobs(cursor.current, direction)
            .then(response => {
                if (response.data.success) {
                    setJobs(response.data.jobs);
                    setTotalCount(response.data.count);
                }
                cursor.current = response.data.nextCursor;
                getMore.current = currentPage;
            })
            .catch(makeStandardApiErrorHandler(error => setErrorMessage(error)));
    }, [cursor, currentPage]);

    const paginationStyle = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    };

    const EditDiv = {
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 30px',
        paddingTop: '60px'
    };

    useEffect(() => {
        document.title = 'Wazeefati | Dashboard';
        window.scrollTo(0, 0);
      }, []);

    const onPageChange = useCallback((currentPage) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(currentPage);
    }, []);

    return (
        <Fluid>
                <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
        <Navbar />
        <TopContainer>
            <Adarea>AD AREA</Adarea>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <AdImg><Img src='https://wazeefati-s3.s3.eu-central-1.amazonaws.com/adImage2.jpg' /></AdImg>
            <div style={EditDiv}>
            <Lines />
            <Title>Your Dashboard</Title>
            <Lines />
            </div>
            <CompanyName>{user.company_name}</CompanyName>
            <ButtonsContainer>
                <Button to="/about_me">POST A JOB</Button>
                <Button style={{ background: '#2E5163' }} to="/my_jobs">YOUR LISTINGS</Button>
                <Button style={{ background: '#54656D' }} to="/about_me">YOUR PROFILE</Button>
                <Button style={{ background: '#F4F4F4', color: '#23485B' }} to="/candidates">FIND TALENT</Button>
            </ButtonsContainer>
            <ApplicantsContainer>
                {jobs.map(x => <Card data={x} key={x} />)}
                <PaginationBox>
                    {/* eslint-disable-next-line react/jsx-no-bind */}
                    <Pagination className={paginationStyle} onPageChange={page => onPageChange(page)} pageSize={6}
                    currentPage={currentPage} totalCount={totalCount} />
                </PaginationBox>
            </ApplicantsContainer>
        </TopContainer>
        <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
        <Footer />
        </Fluid>
    );
};

const Title = styled.div`
font-weight: 400;
font-size: 52px;
font-family: 'Corbel Bold';
white-space: nowrap;
@media screen and (max-width: 750px){
    font-size: 32px;
}
`;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: 270px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: 280px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const Adarea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1F1F1;
    box-shadow: 0px 0px 17px 2px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    width: 70vw;
    height: 378px;
    margin: auto;
    margin-top: 40px;
`;
const ButtonsContainer = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
width: 78vw;
margin: auto;
margin-top: 100px;
justify-content: space-between;
@media screen and (max-width: 750px){
    justify-content: center;
}
`;
const Button = styled(Link)`
display: flex;
align-items: center;
justify-content: center;
background: #16233D;
width: 200px;
height: 70px;
color: white;
border-radius: 5px;
margin: 20px;
cursor: pointer;
box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.44);
text-decoration: none;
font-family: 'Corbel';
font-style: normal;
font-weight: 400;
font-size: 18px;
:hover {
    color: white;
}
`;

const Fluid = styled.div`
position: relative;
`;

const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 20px 0;
`;

const TopContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  margin-bottom: 250px;
  margin-top: 50px;
  box-shadow: 0px 4px 23px rgba(0,0,0,0.25);
`;

const AdImg = styled.div`
`;

const Img = styled.div`
`;

const Card = ({ data }) => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => navigate(`/job_applications/${data.id}`), [data.id, navigate]);

    return (
        <CardDiv onClick={handleClick}>
            <LeftSide>
                {data.headline}
            </LeftSide>
            <ApplicantsNb>
                <AppImg src={Icon} />
                <p style={{ fontSize: '18px' }}>{data.applications}</p>
            </ApplicantsNb>
        </CardDiv>
    );
};

const ApplicantsContainer = styled.div`
    padding: 80px 160px 80px 160px;

    @media screen and (max-width: 830px) {
        padding: 80px 100px 80px 100px;
    }

    @media screen and (max-width: 670px) {
        padding: 80px 60px 80px 60px;
    }
`;

const CardDiv = styled.div`
    background-color: #F5F5F5;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.25), inset -256px 0px 35px rgba(172, 172, 172, 0.25);
    border-radius: 7px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 560px) {
        flex-direction: column;
    }
`;

const LeftSide = styled.p`
    border-right: 1px solid black;
    width: 90%;
    text-align: center;
    font-size: 24px;
    font-family: 'Corbel';
    font-weight: 500;
    margin-bottom: 0;

    @media screen and (max-width: 560px) {
        border-right: none;
    }
`;

const ApplicantsNb = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    margin-right: 10px;
`;

const AppImg = styled.img`
    width: 40px;
`;

const CompanyName = styled.div`
font-family: 'Corbel Bold';
font-weight: 400;
font-size: 42px;
color: #899196;
margin-top: 50px;
text-align: center;
`;
const Lines = styled.div`
    margin-bottom: 2px;
    width: 24vw;
    height: 1px;
    background-color: black;
`;

export default RequireUserAccess(Dashboard, UserStatusEnum.EMPLOYER);
