import React from 'react';
import styled from 'styled-components';
import { Form, Dropdown, Input } from 'semantic-ui-react';
import CloudUploadIcon from '../../svgs/cloud_upload_icon.svg';

const options = [
    { key: 'Months', text: 'Months', value: 'Months' },
    { key: 'Days', text: 'Days', value: 'Days' }
  ];

export default function JobOpening(props) {
  return (
    <Fields>
        <Left>
            <Form.Field>
                <LabelIt>headline for this post</LabelIt>
                <Name placeholder='Headline' value={props.headline} onChange={props.Addheadline} />
            </Form.Field>
            <Form.Field style={{ marginTop: '20px' }}>
                <LabelIt>project title</LabelIt>
                <Name placeholder='Title' value={props.projectTitle} onChange={props.AddprojectTitle} />
            </Form.Field>
            <Form.Field style={{ marginTop: '20px' }}>
                <LabelIt>position title</LabelIt>
                <Name placeholder='Position title' value={props.positiontitle} onChange={props.Addpositiontitle} />
            </Form.Field>
            <SecondFlexIt style={{ marginTop: '10px' }}>
                <Form.Field>
                <LabelIt>job timeline</LabelIt>
                <RadioContainer>
                  <Radio checked={props.jobTimeLine.option === 'open-ended'} onChange={props.AddjobTimeLineOption} value='open-ended' label='Open Ended' />
                  <Radio checked={props.jobTimeLine.option === 'specific'} onChange={props.AddjobTimeLineOption} value='specific' label='Specific' />
                  <Radio checked={props.jobTimeLine.option === 'full-time'} onChange={props.AddjobTimeLineOption} value='full-time' label='Full Time' />
                </RadioContainer>
                </Form.Field>
                <Form.Field>
                <LabelIt>approximate duration</LabelIt>
                <Duration disabled={props.jobTimeLine.option === 'open-ended' || props.jobTimeLine.option === 'full-time'} value={props.jobTimeLine.time} onChange={props.AddjobTimeLineTime} label={
                <Dropdown disabled={props.jobTimeLine.option === 'open-ended' || props.jobTimeLine.option === 'full-time'} value={props.jobTimeLine.type} onChange={props.AddjobTimeLineType} placeholder='Days' options={options} />
                }
                labelPosition='right'
                placeholder='20' />
                </Form.Field>
            </SecondFlexIt>
        </Left>
        <Right>
            <Form.Field>
                <LabelIt>describe the job opening </LabelIt>
                <Strenghts placeholder='Describe the job opening' value={props.describe} onChange={props.Adddescribe} />
                <FileFlex>
                <FileLabel htmlFor='file-upload' id='file-label'>
                <span>Upload  Detailed Job Description &nbsp;</span>
                <img src={CloudUploadIcon} alt='upload_icon' />
                </FileLabel>
                <input id='file-upload' onChange={props.AddDesc} type='file' hidden />
                {!props.file
                    ? ''
                    : <small style={{ fontSize: '12px' }}>{props.file.name}</small>}
                </FileFlex>
            </Form.Field>
        </Right>
    </Fields>
  );
}
const Fields = styled.div`
  display: flex;
  margin: 70px 0px 40px 0px;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height 100%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 30px;
  min-height: 40vh;
  width: 30%;
  @media screen and (max-width: 1107px){
    margin: 0px 5px 30px 5px!important;
    width: 90vw !important;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 0px;
  align-items: flex-start;
  justify-content: center;
  height: 40vh;
  min-width: 40%;
`;
const Name = styled(Form.Input)`
  width: 330px;
  height: 38px;
  background: white;
  border-radius: 5px;
  @media screen and (max-width: 1107px){
    width: 90vw !important;
  }
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
`;
const LabelIt = styled.label`
  color: black !important;
  font-family: 'Corbel', sans-serif !important;
  font-variant: small-caps;
  white-space: nowrap;
  font-weight: 400 !important;
  width: auto;
  margin-bottom: 10px !important;
  font-size: 1.7rem !important;
`;
const SecondFlexIt = styled.div`
display: flex;
align-item: flex-start;
justify-content: space-between;
width: 300px !important;
min-height: 38px;
@media screen and (max-width: 1107px){
  width: 90vw !important;
}
`;
const Strenghts = styled(Form.TextArea)`
  min-width: 760px !important;
  min-height: 290px;
  background: white;
  border-radius: 5px;
  > textarea {
    min-width: 760px !important;
    min-height: 290px;
    resize: none !important;
    width: 80px !important;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
    @media screen and (max-width: 1107px){
    min-width: 90vw !important;
    textarea {
    min-width: 90vw !important;
  }
  }
`;
const FileFlex = styled.div`
  margin-top: -20px;
  display: flex;
  flex-direction: column;

  > label:not(#file-label) {
    font-family: 'Cairo', sans-serif;
    font-weight: bold;
    font-size: 13px;
  }
`;
const FileLabel = styled.label`
  width: 760px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-top: 5px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.28571429rem;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;

  :hover {
    border: 1px solid black;
  }
  @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0px 5px!important;
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
  width: 150px;
  height: 66px;
  background: white;
  border-radius: 5px;
    @media screen and (max-width: 745px){
    width: 30vw !important;
  }
`;
const Radio = styled(Form.Radio)`
  width: 105px;
  margin: 0px !important;
`;
const Duration = styled(Input)`
  width: 80px !important;
  .text {
    margin-top: 4px !important;
  }
  input {
    width: 80px !important;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
`;
