import React, { useReducer, useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import line from '../../pics/flow.svg';
import AboutYou from './about-you';
import Education from './education';
import Awards from './awards';
import Pic from '../../svgs/exclamation.png';
import SlillsEx from './skills_experience';
import { Form, Dimmer, Loader, Modal } from 'semantic-ui-react';
import References from './references';
import { getApiClient } from '../../server/get_api_client';
import ProfileComplete from './profile-complete';
import Navbar from '../../Common/navbar.js';
import Footer from '../../Common/footer.js';
import Wave from 'react-wavify';
import { UserContext } from '../../contexts/user_context';
import { UserStatusEnum } from '../../enums/user_status_enum';
import RequireUserResume from '../../permissions/require_user_resume';
import Arrow from '../../svgs/Vector.png';

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/msword', // .doc format
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx format
  'application/vnd.ms-excel', // .xls format
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx format
  'application/vnd.ms-powerpoint', // .ppt format
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx format
  'application/vnd.ms-powerpoint.presentation.macroenabled.12', // .pptm format
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // .ppts format
  'image/*' // All image types
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'change-step': return { ...state, step: action.step, errorMessage: action.step === 6 ? state.errorMessage : '' };
    case 'add-expertise': return { ...state, expertise: action.expertise };
    case 'rem-expertise': return { ...state, expertise: action.expertise };
    case 'add-skills': return { ...state, skills: action.skills };
    case 'rem-skills': return { ...state, skills: action.skills };
    case 'add-stregths': return { ...state, stregths: action.stregths };
    case 'add-arraylang': return { ...state, language: action.language };
    case 'add-yearsOfExperience': return { ...state, yearsOfExperience: action.yearsOfExperience };
    case 'add-language': return { ...state, language: action.language };
    case 'add-level': return { ...state, language: action.language };
    case 'add-education': return { ...state, education: action.education };
    case 'add-awards': return { ...state, awards: action.awards };
    case 'add-skillsexp': return { ...state, skillsExp: action.skillsExp };
    case 'add-refreences': return { ...state, refreences: action.refreences };
    case 'add-title': return { ...state, title: action.title };
    case 'add-dailyChargeMin': return { ...state, dailyChargeMin: action.dailyChargeMin };
    case 'add-dailyChargeMax': return { ...state, dailyChargeMax: action.dailyChargeMax };
    case 'add-hourlyChargeMin': return { ...state, hourlyChargeMin: action.hourlyChargeMin };
    case 'add-hourlyChargeMax': return { ...state, hourlyChargeMax: action.hourlyChargeMax };
    case 'add-instagram': return { ...state, instagram: action.instagram };
    case 'add-linkedin': return { ...state, linkedin: action.linkedin };
    case 'add-facebook': return { ...state, facebook: action.facebook };
    case 'add-cv': return { ...state, cv: action.cv, errorMessage: '' };
    case 'close-modal': return { ...state, notificationClose: false };
    case 'change-loading': return { ...state, loading: action.loading };
    case 'add-error': return { ...state, errorMessage: action.errorMessage };
  };
};

function ProcessFlow() {
  const [state, dispatch] = useReducer(reducer, {
    step: 1,
    notificationClose: true,
    language: [{
      language: '',
      level: ''
    }],
    education: [{
      country: '',
      degree: '',
      education_from: '',
      education_to: '',
      institution: '',
      present: false,
      institution_type: '',
      major: ''
    }],
    skillsExp: [{
      country: '',
      employed_at: '',
      experience_from: '',
      experience_to: '',
      latest_position: '',
      present: false,
      organization: ''
    }],
    refreences: [{
      email: '',
      known_since: '',
      name: '',
      organization: '',
      phone_number: '961',
      position: ''
    }],
    awards: [{
      award_from: '',
      award_to: '',
      country: '',
      description: '',
      type: '',
      present: false,
      institution: ''
    }],
    expertise: [''],
    skills: [''],
    stregths: '',
    title: '',
    yearsOfExperience: '',
    dailyChargeMin: '',
    dailyChargeMax: '',
    hourlyChargeMin: '',
    hourlyChargeMax: '',
    cv: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    loading: false,
    errorMessage: ''
  });
  const { user, setUser } = useContext(UserContext);
  const {
    expertise, skills, step, stregths, title, dailyChargeMin, errorMessage,
    dailyChargeMax, hourlyChargeMin, hourlyChargeMax, education, language, awards, skillsExp, refreences,
    facebook, instagram, linkedin, cv, loading, notificationClose, yearsOfExperience
  } = state;
  console.log(education);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.title = 'Wazeefati | Resume';
    window.scrollTo(0, 0);
  }, []);

  const popitExpertise = useCallback((e, { value, index }) => {
    const array = expertise;
    array[index] = value;
    dispatch({ type: 'add-expertise', index: index, value: value });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [expertise]);

  const popitExpertiseSearch = useCallback((value, index) => {
    const array = expertise;
    array[index] = value;
    dispatch({ type: 'add-expertise', expertise: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [expertise]);

  const AddExpertise = useCallback(() => {
    if (expertise.includes('')) {

    } else {
        dispatch({ type: 'add-expertise', expertise: expertise.concat('') });
        dispatch({ type: 'add-error', errorMessage: '' });
    }
  }, [expertise]);

  const valEducation = useCallback(() => {
    let final = false;
    education.map((data) => {
      if (!data.country || !data.degree || !data.education_from || !data.major ||
        !data.education_to || !data.institution || !data.institution_type) {
          dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
          final = false;
        } else {
          dispatch({ type: 'add-error', errorMessage: '' });
          final = true;
        }
      });
      return final;
  }, [education]);

  const Addeduction = useCallback(() => {
    var Create = false;
    education.map((data) => {
      if (!data.country || !data.degree || !data.education_from || !data.major ||
        !data.education_to || !data.institution || !data.institution_type) {
          Create = false;
        } else {
          Create = true;
        }
      });
        if (Create) {
          const obj = {
            country: '',
            degree: '',
            education_from: '',
            education_to: '',
            institution: '',
            present: false,
            institution_type: '',
            major: ''
            };
          dispatch({
            type: 'add-education',
            education: education.concat(obj)
            });
          dispatch({ type: 'add-error', errorMessage: '' });
          }
  }, [education]);

  const educationPresent = useCallback((e, { value, index }) => {
    const array = education;
    const thisis = array[index].present;
    console.log('before' + array[index].present);
    if (thisis === false) {
      array[index].present = !array[index].present;
      array[index].education_to = 'Present';
    } else {
      array[index].present = !array[index].present;
      array[index].education_to = '';
    }
    console.log('this one' + array[index].present);
    console.log('this one' + array[index].education_to);
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-education', education: array });
  }, [education, dispatch]);

  const skillsExpPresent = useCallback((e, { value, index }) => {
    const array = skillsExp;
    const thisis = array[index].present;
    if (thisis === false) {
      array[index].present = !array[index].present;
      array[index].experience_to = 'Present';
    } else {
      array[index].present = !array[index].present;
      array[index].experience_to = '';
    }
    dispatch({ type: 'add-skillsexp', skillsExp: array });
  }, [skillsExp]);
  const awardsPresent = useCallback((e, { value, index }) => {
    const array = awards;
    const thisis = array[index].present;
    if (thisis === false) {
      array[index].present = !array[index].present;
      array[index].award_to = 'Present';
    } else {
      array[index].present = !array[index].present;
      array[index].award_to = '';
    }
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-awards', awards: array });
  }, [awards]);

  const AddeductionFrom = useCallback((e, { value, index }) => {
    const pattern = /^(\d+|present)?$/; // Regex pattern to allow numbers or the word "present"
    if (pattern.test(value)) {
      const array = education;
      array[index].education_from = value;
      dispatch({ type: 'add-error', errorMessage: '' });
      dispatch({ type: 'add-education', education: array });
    }
  }, [education]);
  const AddeductionTo = useCallback((e, { value, index }) => {
    const pattern = /^(\d+|present)?$/; // Regex pattern to allow numbers or the word "present"
    if (pattern.test(value)) {
      const array = education;
      array[index].education_to = value;
      dispatch({ type: 'add-error', errorMessage: '' });
      dispatch({ type: 'add-education', education: array });
    }
  }, [education]);
  const AddExperience = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-yearsOfExperience', yearsOfExperience: value });
  }, []);
  const AddeducationInstitution = useCallback((e, { value, index }) => {
    const array = education;
    array[index].institution = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-education', education: array });
  }, [education]);
  const AddeducationInstitutionSearch = useCallback((value, index) => {
    const array = education;
    array[index].institution = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-education', education: array });
  }, [education]);
  const AddeducationInstitutionType = useCallback((e, { value, index }) => {
    const array = education;
    array[index].institution_type = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-education', education: array });
  }, [education]);
  const AddeducationCountry = useCallback((e, { value, index }) => {
    const array = education;
    array[index].country = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-education', education: array });
  }, [education]);
  const AddeducationDegree = useCallback((e, { value, index }) => {
    const array = education;
    array[index].degree = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-education', education: array });
  }, [education]);
  const AddeducationMajor = useCallback((e, { value, index }) => {
    const array = education;
    array[index].major = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-education', education: array });
  }, [education]);

// skills and experience part
const valskillsExp = useCallback(() => {
  let final = false;
  skillsExp.map((data) => {
    if (!data.country || !data.employed_at || !data.experience_from || !data.experience_to ||
      !data.latest_position || !data.organization) {
        dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
        final = false;
      } else {
        dispatch({ type: 'add-error', errorMessage: '' });
        final = true;
      }
    });
    return final;
}, [skillsExp]);

  const AddskillsExp = useCallback(() => {
    var Create = false;
    console.log('Hello');
    skillsExp.map((data) => {
      if (!data.country || !data.employed_at || !data.experience_from || !data.experience_to ||
        !data.latest_position || !data.organization) {
          Create = false;
        } else {
          Create = true;
        }
      });
        if (Create) {
          const obj = {
            country: '',
            employed_at: '',
            experience_from: '',
            experience_to: '',
            present: false,
            latest_position: '',
            organization: ''
            };
          dispatch({
            type: 'add-skillsexp',
            skillsExp: skillsExp.concat(obj)
            });
            dispatch({ type: 'add-error', errorMessage: '' });
            }
  }, [skillsExp]);

  const Addskillsfrom = useCallback((e, { value, index }) => {
    const pattern = /^(\d+|present)?$/; // Regex pattern to allow numbers or the word "present"
    if (pattern.test(value)) {
      const array = skillsExp;
      array[index].experience_from = value;
      dispatch({ type: 'add-skillsexp', skillsExp: array });
      dispatch({ type: 'add-error', errorMessage: '' });
    }
  }, [skillsExp]);
  const Addskillsto = useCallback((e, { value, index }) => {
    const pattern = /^(\d+|present)?$/; // Regex pattern to allow numbers or the word "present"
    if (pattern.test(value)) {
      const array = skillsExp;
      array[index].experience_to = value;
      dispatch({ type: 'add-skillsexp', skillsExp: array });
      dispatch({ type: 'add-error', errorMessage: '' });
    }
  }, [skillsExp]);
  const Addskillsat = useCallback((e, { value, index }) => {
    const array = skillsExp;
    array[index].employed_at = value;
    dispatch({ type: 'add-skillsexp', skillsExp: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [skillsExp]);
  const Addskillsorg = useCallback((e, { value, index }) => {
    const array = skillsExp;
    array[index].organization = value;
    dispatch({ type: 'add-skillsexp', skillsExp: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [skillsExp]);
  const Addskillscountry = useCallback((e, { value, index }) => {
    const array = skillsExp;
    console.log(value);
    array[index].country = value;
    dispatch({ type: 'add-skillsexp', skillsExp: array });
    dispatch({ type: 'add-error', errorMessage: '' });
    console.log(skillsExp);
  }, [skillsExp]);
  const Addskillspos = useCallback((e, { value, index }) => {
    const array = skillsExp;
    array[index].latest_position = value;
    dispatch({ type: 'add-skillsexp', skillsExp: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [skillsExp]);
// end of skills and experience part
const valarraylang = useCallback(() => {
  let final = false;
  language.map((data) => {
    if (!data.language || !data.level) {
        dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
        final = false;
      } else {
        dispatch({ type: 'add-error', errorMessage: '' });
        final = true;
      }
    });
    return final;
}, [language]);
  const Addarraylang = useCallback(() => {
    var Create = false;
    language.map((data) => {
      if (!data.language || !data.level) {
          Create = false;
        } else {
          Create = true;
        }
      });
        if (Create) {
          const obj = {
            language: '',
            level: ''
            };
          dispatch({
            type: 'add-arraylang',
            language: language.concat(obj)
            });
            dispatch({ type: 'add-error', errorMessage: '' });
          }
  }, [language]);

  const Addlanguage = useCallback((e, { value, index }) => {
    const array = language;
    array[index].language = value;
    dispatch({ type: 'add-language', language: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [language]);

  // starting of about section
  const valabout = useCallback(() => {
    if (!stregths || !title || !dailyChargeMin || !dailyChargeMax ||
      !hourlyChargeMin || !hourlyChargeMax) {
        dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
        return false;
      } else {
        if (stregths.split(' ').length >= 250) {
          dispatch({ type: 'add-error', errorMessage: 'Strength Exceeded 250 words.' });
          return false;
        }
        dispatch({ type: 'add-error', errorMessage: '' });
        return true;
    }
  }, [stregths, title, dailyChargeMin, dailyChargeMax, hourlyChargeMin, hourlyChargeMax]);

  const Addlevel = useCallback((e, { value, index }) => {
    const array = language;
    array[index].level = value;
    dispatch({ type: 'add-level', language: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [language]);

  const AddStregths = useCallback((e) => {
        dispatch({ type: 'add-stregths', stregths: e.target.value });
        dispatch({ type: 'add-error', errorMessage: '' });
  }, []);
  const Addtitle = useCallback((e) => {
        dispatch({ type: 'add-title', title: e.target.value });
        dispatch({ type: 'add-error', errorMessage: '' });
  }, []);
  const AdddailyChargeMin = useCallback((e) => {
        dispatch({ type: 'add-dailyChargeMin', dailyChargeMin: e.target.value });
        dispatch({ type: 'add-error', errorMessage: '' });
  }, []);
  const AdddailyChargeMax = useCallback((e) => {
        dispatch({ type: 'add-dailyChargeMax', dailyChargeMax: e.target.value });
        dispatch({ type: 'add-error', errorMessage: '' });
  }, []);
  const AddhourlyChargeMin = useCallback((e) => {
        dispatch({ type: 'add-hourlyChargeMin', hourlyChargeMin: e.target.value });
        dispatch({ type: 'add-error', errorMessage: '' });
  }, []);
  const AddhourlyChargeMax = useCallback((e) => {
        dispatch({ type: 'add-hourlyChargeMax', hourlyChargeMax: e.target.value });
        dispatch({ type: 'add-error', errorMessage: '' });
  }, []);

  // awards part

  // const valawards = useCallback(() => {
  //   let final = false;
  //   awards.map((data) => {
  //     if (!data.award_from || !data.award_to || !data.country || !data.description ||
  //       !data.type || !data.institution) {
  //         dispatch({ type: 'add-error', errorMessage: 'Please fill all the fields' });
  //         final = false;
  //       } else {
  //         dispatch({ type: 'add-error', errorMessage: '' });
  //         final = true;
  //       }
  //     });
  //     return final;
  // }, [awards]);

  const Addawards = useCallback(() => {
    var Create = false;
    awards.map((data) => {
      if (!data.award_from || !data.award_to || !data.country || !data.description ||
        !data.type || !data.institution) {
          Create = false;
        } else {
          Create = true;
        }
      });
        if (Create) {
          const obj = {
            award_from: '',
            award_to: '',
            country: '',
            description: '',
            type: '',
            institution: ''
            };
          dispatch({
            type: 'add-awards',
            awards: awards.concat(obj)
            });
            dispatch({ type: 'add-error', errorMessage: '' });
          }
  }, [awards]);

  const AddawardsFrom = useCallback((e, { value, index }) => {
    const pattern = /^(\d+|present)?$/; // Regex pattern to allow numbers or the word "present"
    if (pattern.test(value)) {
      const array = awards;
      array[index].award_from = value;
      dispatch({ type: 'add-error', errorMessage: '' });
      dispatch({ type: 'add-awards', awards: array });
    }
  }, [awards]);
  const AddawardsTo = useCallback((e, { value, index }) => {
    const pattern = /^(\d+|present)?$/; // Regex pattern to allow numbers or the word "present"
    if (pattern.test(value)) {
      const array = awards;
      array[index].award_to = value;
      dispatch({ type: 'add-error', errorMessage: '' });
      dispatch({ type: 'add-awards', awards: array });
    }
  }, [awards]);
  const AddawardsInstitution = useCallback((e, { value, index }) => {
    const array = awards;
    array[index].institution = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-awards', awards: array });
  }, [awards]);
  const AddawardsCountry = useCallback((e, { value, index }) => {
    const array = awards;
    array[index].country = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-awards', awards: array });
  }, [awards]);
  const AddawardsType = useCallback((e, { value, index }) => {
    const array = awards;
    array[index].type = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-awards', awards: array });
  }, [awards]);
  const AddawardsDesc = useCallback((e, { value, index }) => {
    const array = awards;
    array[index].description = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-awards', awards: array });
  }, [awards]);

  // Refrences function starting

  const valrefreences = useCallback(() => {
    let final = false;
    refreences.map((data) => {
      if (!data.email || !data.known_since || !data.name || !data.organization || !data.position ||
         !data.phone_number) {
          dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
          final = false;
        } else {
          dispatch({ type: 'add-error', errorMessage: '' });
          final = true;
        }
      });
      console.log(refreences);
      return final;
  }, [refreences]);

  const Addrefreences = useCallback(() => {
    var Create = false;
    refreences.map((data) => {
      if (!data.email || !data.known_since || !data.name || !data.organization ||
        !data.phone_number || !data.position) {
          Create = false;
        } else {
          Create = true;
        }
      });
        if (Create) {
          const obj = {
            email: '',
            known_since: '',
            name: '',
            organization: '',
            phone_number: '',
            position: ''
            };
          dispatch({
            type: 'add-refreences',
            refreences: refreences.concat(obj)
            });
            dispatch({ type: 'add-error', errorMessage: '' });
            }
  }, [refreences]);

  const Addposition = useCallback((e, { value, index }) => {
    const array = refreences;
    array[index].position = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-refreences', refreences: array });
  }, [refreences]);
  const Addname = useCallback((e, { value, index }) => {
    const array = refreences;
    array[index].name = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-refreences', refreences: array });
    console.log(refreences);
  }, [refreences]);
  const Addorganization = useCallback((e, { value, index }) => {
    const array = refreences;
    array[index].organization = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-refreences', refreences: array });
  }, [refreences]);
  const Addemail = useCallback((e, { value, index }) => {
    const array = refreences;
    array[index].email = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-refreences', refreences: array });
  }, [refreences]);
  const AddknownSince = useCallback((e, { value, index }) => {
    const array = refreences;
    array[index].known_since = value;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-refreences', refreences: array });
  }, [refreences]);

  const Addphone = useCallback((phone, index) => {
    const array = refreences;
    array[index].phone_number = phone;
    dispatch({ type: 'add-error', errorMessage: '' });
    dispatch({ type: 'add-refreences', refreences: array });
  }, [refreences]);

  // socail links
  const Addinstagram = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-instagram', instagram: value });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, []);
  const Addlinikedin = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-linkedin', linkedin: value });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, []);
  const Addfacebook = useCallback((e, { value, index }) => {
    dispatch({ type: 'add-facebook', facebook: value });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, []);
  const Addcv = useCallback((e) => {
    if (!ALLOWED_FILE_TYPES.includes(e.target.files[0].type)) {
      dispatch({ type: 'add-error', errorMessage: 'Invalid file type.' });
      return;
    }
    dispatch({ type: 'add-cv', cv: e.target.files[0] });
  }, []);

  // end of socail links

  const RemoveExpertise = useCallback((index) => {
    const array = expertise;
    array.splice(index, 1);
    dispatch({ type: 'add-expertise', expertise: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [expertise]);
  const RemoveSkills = useCallback((index) => {
    const array = skills;
    array.splice(index, 1);
    dispatch({ type: 'add-skills', skills: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [skills]);
  const RemoveEducation = useCallback((index) => {
    const array = education;
    array.splice(index, 1);
    dispatch({ type: 'add-education', education: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [education]);
  const RemoveLanguage = useCallback((index) => {
    const array = language;
    array.splice(index, 1);
    dispatch({ type: 'add-language', language: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [language]);
  const RemoveAward = useCallback((index) => {
    const array = awards;
    array.splice(index, 1);
    dispatch({ type: 'add-awards', awards: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [awards]);
  const RemoveSkillsExp = useCallback((index) => {
    const array = skillsExp;
    array.splice(index, 1);
    dispatch({ type: 'add-skillsexp', skillsExp: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [skillsExp]);
  const RemoveRef = useCallback((index) => {
    const array = refreences;
    array.splice(index, 1);
    dispatch({ type: 'add-refreences', refreences: array });
    dispatch({ type: 'add-error', errorMessage: '' });
  }, [refreences]);

  const changeStateBack = useCallback(() => {
    dispatch({ type: 'change-step', step: step - 1 });
    window.scrollTo(0, 0);
}, [step]);

const CallApis = useCallback(async () => {
        if (cv && typeof cv !== 'string' && !ALLOWED_FILE_TYPES.includes(cv.type)) {
          dispatch({ type: 'add-error', errorMessage: 'Invalid file type.' });
          return;
        }
        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        for (var i = 0; i < refreences.length; i++) {
          if (!emailPattern.test(refreences[i].email)) {
            dispatch({ type: 'add-error', errorMessage: 'Invalid email format in references.' });
            return;
          }
        }
        dispatch({ type: 'change-loading', loading: true });
        const exp = expertise.filter(e => e !== null);
        const sk = skills.filter(e => e !== null);
        await getApiClient().aboutYou(title, JSON.stringify(exp),
            JSON.stringify(sk), stregths, parseInt(dailyChargeMin), parseInt(dailyChargeMax),
            parseInt(hourlyChargeMin), parseInt(hourlyChargeMax), parseInt(yearsOfExperience)).then(res =>
          console.log(res)).catch(error => console.log(error));
        console.log(expertise);
        await getApiClient().education(JSON.stringify(education)).then(res =>
          console.log(res)).catch(() => {
            dispatch({ type: 'add-error', errorMessage: 'There is an error from our end' });
            dispatch({ type: 'change-loading', loading: false });
          });
        await getApiClient().languages(JSON.stringify(language)).then(res =>
          console.log(res)).catch(() => {
            dispatch({ type: 'add-error', errorMessage: 'There is an error from our end' });
            dispatch({ type: 'change-loading', loading: false });
          });
        await getApiClient().awards(JSON.stringify(awards)).then(res =>
          console.log(res)).catch(() => {
            dispatch({ type: 'add-error', errorMessage: 'There is an error from our end' });
            dispatch({ type: 'change-loading', loading: false });
          });

        await getApiClient().experiences(JSON.stringify(skillsExp)).then(res =>
          console.log(res)).catch(() => {
            dispatch({ type: 'add-error', errorMessage: 'There is an error from our end' });
            dispatch({ type: 'change-loading', loading: false });
          });

        await getApiClient().references(JSON.stringify(refreences)).then(res =>
          console.log(res)).catch(() => {
            dispatch({ type: 'add-error', errorMessage: 'There is an error from our end' });
            dispatch({ type: 'change-loading', loading: false });
          });
        await getApiClient().links(cv, facebook, instagram, linkedin).then(res => {
          const userEdit = user;
          userEdit.has_resume = true;
          setUser(userEdit);
          dispatch({ type: 'change-step', step: step + 1 });
        }).catch(() => {
          dispatch({ type: 'add-error', errorMessage: 'There is an error from our end' });
          dispatch({ type: 'change-loading', loading: false });
        });
        dispatch({ type: 'change-loading', loading: false });
}, [refreences, skillsExp, awards, language, education, title, expertise, skills,
   stregths, dailyChargeMin, dailyChargeMax, hourlyChargeMin, hourlyChargeMax,
   cv, facebook, instagram, linkedin, step, user, setUser, yearsOfExperience]);

   const changeState = useCallback(() => {
    if (step === 1 && valabout()) {
      for (var i = 0; i < expertise.length; i++) {
        if (expertise[i] === '' || !expertise[i]) {
          dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
          return;
        }
      }
      for (var j = 0; j < skills.length; j++) {
        if (skills[j] === '' || !skills[j]) {
          dispatch({ type: 'add-error', errorMessage: 'One or more fields are empty.' });
          return;
        }
      }
      dispatch({ type: 'change-step', step: step + 1 });
    } else if (step === 2 && valEducation() && valarraylang()) {
      dispatch({ type: 'change-step', step: step + 1 });
    } else if (step === 3 && valskillsExp()) {
      dispatch({ type: 'change-step', step: step + 1 });
    } else if (step === 4) {
      dispatch({ type: 'change-step', step: step + 1 });
    } else if (step === 5 && valrefreences()) {
      CallApis();
    } else if (step === 6 && errorMessage) {
      dispatch({ type: 'change-step', step: step - 1 });
    } else if (step === 6 && errorMessage) {
      dispatch({ type: 'change-step', step: step - 1 });
    }
    window.scrollTo(0, 0);
}, [valabout, valEducation, valarraylang, expertise, skills,
   valskillsExp, valrefreences, step, CallApis, errorMessage]);

  const onCloseModal = useCallback(() => dispatch({ type: 'close-modal' }), []);

  const Display = () => {
    if (step === 1) {
      return (<AboutYou popitExpertise={popitExpertise} AddSkills={AddSkills} popitSkills={popitSkills}
        AddExpertise={AddExpertise} expertise={expertise} skills={skills}
        AddStregths={AddStregths} Addtitle={Addtitle}
        AdddailyChargeMin={AdddailyChargeMin} AdddailyChargeMax={AdddailyChargeMax}
        AddhourlyChargeMin={AddhourlyChargeMin}
        AddhourlyChargeMax={AddhourlyChargeMax} changeState={changeState} stregths={stregths} title={title}
        dailyChargeMin={dailyChargeMin} dailyChargeMax={dailyChargeMax} hourlyChargeMin={hourlyChargeMin}
        hourlyChargeMax={hourlyChargeMax} popitExpertiseSearch={popitExpertiseSearch}
        RemoveExpertise={RemoveExpertise} RemoveSkills={RemoveSkills} AddExperience={AddExperience}
        yearsOfExperience={yearsOfExperience} />);
    } else if (step === 2) {
      return (<Education education={education} AddeductionFrom={AddeductionFrom} AddeductionTo={AddeductionTo}
        AddeducationInstitution={AddeducationInstitution} AddeducationInstitutionType={AddeducationInstitutionType}
        AddeducationCountry={AddeducationCountry} AddeducationDegree={AddeducationDegree} changeState={changeState}
        Addlanguage={Addlanguage} Addlevel={Addlevel} Addarraylang={Addarraylang} language={language}
        AddeducationMajor={AddeducationMajor} Addeduction={Addeduction} changeStateBack={changeStateBack}
        AddeducationInstitutionSearch={AddeducationInstitutionSearch} RemoveEducation={RemoveEducation}
        RemoveLanguage={RemoveLanguage} educationPresent={educationPresent} />);
    } else if (step === 3) {
      return (<SlillsEx skillsExp={skillsExp} Addskillsfrom={Addskillsfrom} Addskillsto={Addskillsto}
        Addskillsat={Addskillsat} Addskillsorg={Addskillsorg} Addskillscountry={Addskillscountry}
        Addskillspos={Addskillspos} AddskillsExp={AddskillsExp} changeState={changeState}
        changeStateBack={changeStateBack} Addcv={Addcv} Addfacebook={Addfacebook}
        Addlinikedin={Addlinikedin} Addinstagram={Addinstagram} RemoveSkillsExp={RemoveSkillsExp}
        cv={cv} instagram={instagram} linkedin={linkedin} facebook={facebook}
         skillsExpPresent={skillsExpPresent} />);
    } else if (step === 4) {
      return (<Awards awards={awards} Addawards={Addawards} AddawardsFrom={AddawardsFrom} AddawardsTo={AddawardsTo}
        AddawardsInstitution={AddawardsInstitution} AddawardsCountry={AddawardsCountry}
        AddawardsType={AddawardsType} AddawardsDesc={AddawardsDesc} changeState={changeState}
        changeStateBack={changeStateBack} RemoveAward={RemoveAward} awardsPresent={awardsPresent} />);
    } else if (step === 5) {
      return (<References refreences={refreences} AddknownSince={AddknownSince} Addemail={Addemail}
        Addorganization={Addorganization} Addposition={Addposition} Addskillscountry={Addskillscountry}
        Addname={Addname} Addrefreences={Addrefreences} Addphone={Addphone} changeState={changeState}
        changeStateBack={changeStateBack} RemoveRef={RemoveRef} />);
    } else if (step === 6) {
      return (<ProfileComplete />);
    }
  };
  const popitSkills = useCallback((e, { value, index }) => {
    const array = skills;
    array[index] = value;
    dispatch({ type: 'add-skills', skills: array });
  }, [skills]);

  const AddSkills = useCallback(() => {
    if (skills.includes(null)) {

    } else {
        dispatch({ type: 'add-skills', skills: skills.concat(null) });
    }
  }, [skills]);

  const addthem = useCallback((res) => {
    dispatch({ type: 'change-loading', loading: true });
    if (res.data.candidate.about) {
      dispatch({ type: 'add-hourlyChargeMax', hourlyChargeMax: res.data.candidate.about.max_hourly_charge });
      dispatch({ type: 'add-hourlyChargeMin', hourlyChargeMin: res.data.candidate.about.min_hourly_charge });
      dispatch({ type: 'add-dailyChargeMax', dailyChargeMax: res.data.candidate.about.max_daily_charge });
      dispatch({ type: 'add-dailyChargeMin', dailyChargeMin: res.data.candidate.about.min_daily_charge });
      dispatch({ type: 'add-title', title: res.data.candidate.about.title });
      dispatch({ type: 'add-stregths', stregths: res.data.candidate.about.strengths });
      dispatch({ type: 'add-skills', skills: res.data.candidate.about.skills });
      dispatch({ type: 'add-expertise', expertise: res.data.candidate.about.expertise });
      dispatch({ type: 'add-cv', cv: res.data.candidate.about.cv });
      dispatch({ type: 'add-facebook', facebook: res.data.candidate.about.facebook_link });
      dispatch({ type: 'add-linkedin', linkedin: res.data.candidate.about.linkedin_link });
      dispatch({ type: 'add-instagram', instagram: res.data.candidate.about.instagram_link });
      dispatch({ type: 'add-yearsOfExperience', yearsOfExperience: res.data.candidate.about.years_of_experience });
    }
    if (res.data.candidate.experiences.length > 0) {
      dispatch({ type: 'add-skillsexp', skillsExp: res.data.candidate.experiences });
    }
    if (res.data.candidate.educations.length > 0) {
      console.log('SeachMe', res.data.candidate.educations);
      dispatch({ type: 'add-education', education: res.data.candidate.educations });
    }
    if (res.data.candidate.languages) {
      dispatch({ type: 'add-language', language: res.data.candidate.languages.languages });
    }
    if (res.data.candidate.references.length > 0) {
      dispatch({ type: 'add-refreences', refreences: res.data.candidate.references });
    }
    if (res.data.candidate.awards.length > 0) {
      dispatch({ type: 'add-awards', awards: res.data.candidate.awards });
    }
    dispatch({ type: 'change-loading', loading: false });
  }, []);
  useEffect(() => {
    getApiClient().getCandidateFlow().then(res => addthem(res));
  }, [addthem]);

  const onClickOnStep = useCallback(stepNb => {
    dispatch({ type: 'change-step', step: stepNb });
  }, []);
  return (
    <>
    <div style={{ position: 'relative' }}>
    <Navbar />
    <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <TitleContainer>
      {user && user.full_name}
    </TitleContainer>
    <Form>
    <Container>
    <CustomModal open={notificationClose} className='custom-modal' onClose={onCloseModal}>
        <Modal.Content>
          <PopIt>
            <Img src={Pic} />
            <Para>Please fill all the Fields in English</Para>
          </PopIt>
        </Modal.Content>
    </CustomModal>
      <FromContainer>
        {step !== 6
     ? (<ProcessBar>
          {windowWidth < 1005
          ? <>
              {step !== 1 &&
                <ButtonContainer1>
                  <Button src={Arrow} onClick={changeStateBack} />
                </ButtonContainer1>}
              <CircleContainer>
                <CircleWtihText>{step === 1 ? <><CurrentCricle>1</CurrentCricle> <CircleText>About You</CircleText></> : ''}
                </CircleWtihText>
                <CircleWtihText>{step === 2 ? <><CurrentCricle>2</CurrentCricle> <CircleText>Education</CircleText></> : ''}
                </CircleWtihText>
                <CircleWtihText>{step === 3 ? <><CurrentCricle>3</CurrentCricle> <CircleText>Experience</CircleText></> : ''}
                </CircleWtihText>
                <CircleWtihText>{step === 4 ? <><CurrentCricle>4</CurrentCricle><CircleText>Awards</CircleText></> : ''}
                </CircleWtihText>
                <CircleWtihText>{step === 5 ? <><CurrentCricle>5</CurrentCricle><CircleText>References</CircleText></> : ''}
                </CircleWtihText>
              </CircleContainer>
              <ButtonContainer>
                {step !== 5 &&
                  <Button style={{ transform: 'rotate(180deg)' }} src={Arrow} onClick={changeState} />}
              </ButtonContainer>
            </>
          : <>
              {step !== 1 &&
                <ButtonContainer1>
                  <Button src={Arrow} onClick={changeStateBack} />
                </ButtonContainer1>}
              {/* eslint-disable */}
              <CircleContainer>
                <CircleWtihText onClick={() => onClickOnStep(1)}>
                  {step === 1 ? <CurrentCricle>1</CurrentCricle> : <Cricle>1</Cricle>}
                  <CircleText>About You</CircleText>
                </CircleWtihText>
                <CircleWtihText onClick={() => onClickOnStep(2)}>
                  {step === 2 ? <CurrentCricle>2</CurrentCricle> : <Cricle>2</Cricle>}
                  <CircleText>Education</CircleText>
                </CircleWtihText>
                <CircleWtihText onClick={() => onClickOnStep(3)}>
                  {step === 3 ? <CurrentCricle>3</CurrentCricle> : <Cricle>3</Cricle>}
                  <CircleText>Experience</CircleText>
                </CircleWtihText>
                <CircleWtihText onClick={() => onClickOnStep(4)}>
                  {step === 4 ? <CurrentCricle>4</CurrentCricle> : <Cricle>4</Cricle>}
                  <CircleText>Awards</CircleText>
                </CircleWtihText>
                <CircleWtihText onClick={() => onClickOnStep(5)}>
                  {step === 5 ? <CurrentCricle>5</CurrentCricle> : <Cricle>5</Cricle>}
                  <CircleText>References</CircleText>
                </CircleWtihText>
              </CircleContainer>
              <ButtonContainer>
                {step !== 5 &&
                  <Button style={{ transform: 'rotate(180deg)' }} src={Arrow} onClick={changeState} />}
              </ButtonContainer>
            </>}
          <IMG src={line} />
        </ProcessBar>)
        : ''}
        {errorMessage === '' ? '' : <Error>{errorMessage}</Error>}
        <Dimmer active={loading} inverted>
            <Loader size='large'>Loading</Loader>
        </Dimmer>
        {Display()}
      </FromContainer>
    </Container>
    </Form>
    <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
    <Footer />
    </>
  );
}

export default RequireUserResume(ProcessFlow, UserStatusEnum.CANDIDATE);

const ButtonContainer = styled.div`
  position: absolute;
  margin-left: 30px;
  left: 100%;
  @media screen and (max-width: 655px){
    left: 96%;
  }
  @media screen and (max-width: 458px){
    left: 94%;
  }
`;

const ButtonContainer1 = styled.div`
  position: absolute;
  margin-right: 30px;
  right: 100%;

  @media screen and (max-width: 655px){
    right: 96%;
  }
  @media screen and (max-width: 458px){
    right: 94%;
  }
`;

const Button = styled.img`
    height: 60px;
    cursor: pointer;
    margin-top: 4px;
    background: rgba(255, 255, 255, 0.58);
    border-radius: 10px;
    font-family: 'Corbel';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 90.69%;
/* or 36px */
  
    text-align: center;
    font-variant: small-caps;
  
    color: #23485B;
    @media screen and (max-width: 765px){
        ${'' /* width: 40vw; */}
    }
`;

const CustomModal = styled(Modal)`
  @media screen and (max-width: 504px){
    width: 80vw !important;
  }
`;
const Img = styled.img`
  width: 100px;
`;
const PopIt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justifty-content: space-between;
  height: 100%;
  width: 100%;
`;
const Para = styled.div`
  text-align: center;
  font-family: Corbel;
  width: 100%;
  font-size: 20px;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 10vh;
  margin: 0px auto;
  margin-top: 50px;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 96px;
  /* identical to box height */
  color: #000000;
  @media screen and (max-width: 900px){
    font-size: 27px;
  }
`;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -105px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -105px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const Error = styled.div`
  margin-top: 10.5vh;
  text-align: center;
  font-family: 'Corbel';
  width: 100vw;
  color: #1B71E1;
  font-size: 20px;
  margin-bottm: -10vh
  height: 10vh;
`;
const Container = styled.div`
  display: flex;
  padding: 50px 0px;
  margin: -25px 0px 100px 0px;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const FromContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  width: 100vw;
  align-items: center;
  box-shadow: 0px 0px 14px 11px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.9);
`;
const ProcessBar = styled.div`
  position: relative;
  display:flex;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
  width: 80vw;
`;
const CircleContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65vw;
  @media screen and (max-width: 1005px){
    justify-content: center;
  }
`;
const Cricle = styled.div`
  display: flex;
  font-family: 'Corbel' !important;
  font-weight: 600;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  background: #F4F4F4;
  border: 6px solid #23485B;
  border-radius: 50%;
  width: 70px;
  height: 70px;
`;
const CurrentCricle = styled.div`
  display: flex;
  font-family: 'Corbel' !important;
  font-weight: 600;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  background: #FFE38D;
  border: 6px solid #23485B;
  border-radius: 50%;
  width: 85px;
  height: 85px;
`;
const CircleWtihText = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const CircleText = styled.div`
  font-family: 'Corbel Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  text-align: center;
`;
const IMG = styled.img`
  display:flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 10vh;
`;
