import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { UserContext } from '../contexts/user_context';

const checkIfUserInfoIsMissed = (user) => {
  if (!user.id_image) return true;
  return false;
};

const checkIfUserHasResume = (user) => {
  if (user.has_resume) return true;
  return false;
};

const RequireUserAccess = (Component, userStatus = null) => {
  const RequireUserAccessComponent = (props) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('wazeefati_token');

    useEffect(() => {
      if (!token) {
        navigate('/login');
        return;
      }
      setIsLoaded(false);
      if (user) {
        setIsLoaded(true);
        if (userStatus && user.status !== userStatus) {
          navigate('/page_not_found');
          return;
        }
        if (user.status === 'candidate' && checkIfUserInfoIsMissed(user)) {
          navigate('/complete_profile');
          return;
        }
        if (user.status === 'employer' && !checkIfUserHasResume(user)) navigate('/about_me');
        if (user.status === 'candidate' && !checkIfUserHasResume(user)) navigate('/resume');
      }
    }, [navigate, user, token]);

    if (!isLoaded || !token || (userStatus && user.status !== userStatus) || (user.status === 'candidate' && checkIfUserInfoIsMissed(user)) ||
      (!checkIfUserHasResume(user) && user.status === 'employer') || (!checkIfUserHasResume(user) && user.status === 'candidate')) {
      return <Loader active>Loading</Loader>;
    } else {
      return <Component {...props} />;
    }
  };

  return RequireUserAccessComponent;
};

export default RequireUserAccess;
