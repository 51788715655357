import React, { useCallback, useEffect, useReducer } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer';
import Wave from 'react-wavify';

const reducer = (state, action) => {
  switch (action.type) {
    case 'start-verify-token': return { ...state, loading: true };
    case 'verify-token-success': return { ...state, loading: false };
    case 'verify-token-failed':
    case 'reseting-password-failed': return { ...state, loading: false };
    case 'password-changed': return { ...state, errorMessage: null, password: action.password };
    case 'retyped-password-changed': return { ...state, errorMessage: null, retypedPassword: action.password };
    case 'start-reseting-password': return { ...state, loading: true, errorMessage: null };
    case 'toggle-show-password': return { ...state, passwordShown: !state.passwordShown };
    case 'validation-error': return { ...state, errorMessage: action.error };
  }
};

const ResetPasswordPage = () => {
  const [state, dispatch] = useReducer(reducer, {
    password: '',
    retypedPassword: '',
    passwordShown: false,
    errorMessage: null,
    loading: true
  });
  const { password, retypedPassword, passwordShown, loading, errorMessage } = state;
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate('/forgot_password');
  }, [searchParams, navigate, token]);

  useEffect(() => {
    document.title = 'Wazeefati | Reset Password';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: 'start-verify-token' });
    getApiClient().verifyPasswordResetToken(token)
      .then(response => {
        if (response.data.success) {
          dispatch({ type: 'verify-token-success' });
        } else {
          navigate('/forgot_password');
        }
      })
      .catch(makeStandardApiErrorHandler(_ => {
        dispatch({ type: 'verify-token-failed' });
        navigate('/page_not_found');
      }));
  }, [token, navigate]);

  const onPasswordChange = useCallback((e) => dispatch({ type: 'password-changed', password: e.target.value }), []);
  const onRetypedPasswordChange = useCallback((e) => {
    dispatch({ type: 'retyped-password-changed', password: e.target.value });
  }, []);

  const validate = useCallback(() => {
    if (!password || !retypedPassword) {
      dispatch({ type: 'validation-error', error: 'Please enter all fields.' });
      return false;
    }
    if (password !== retypedPassword) {
      dispatch({ type: 'validation-error', error: 'Passwords are not the same.' });
      return false;
    }
    return true;
  }, [password, retypedPassword]);

  const onSubmit = useCallback(() => {
    if (validate()) {
      dispatch({ type: 'start-reseting-password' });
      getApiClient().resetPassword(password, token)
        .then(response => response.data.success && navigate('/login'))
        .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'reseting-password-failed', error: error })));
    }
  }, [navigate, password, token, validate]);

  const togglePasswordVisibility = useCallback(() => dispatch({ type: 'toggle-show-password' }), []);

  return (
    <TopContainer>
    <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <Navbar />
    <Container>
      <FormContainer>
        <Title>Reset Your Password</Title>
        <Form {...{ error: !!errorMessage }} {...{ loading: loading }} unstackable onSubmit={onSubmit}
          id='reset-password-form'>
          {errorMessage && <p style={{ color: '#FFD042' }}>{errorMessage}</p>}
          <Form.Field>
            <Label>New Password:</Label>
            <Input type={passwordShown ? 'text' : 'password'} placeholder='***********' value={password}
              onChange={onPasswordChange}
              icon={<Icon name={passwordShown ? 'eye slash' : 'eye'} link onClick={togglePasswordVisibility}
                style={{ left: 'unset', right: '8px' }} />} />
          </Form.Field>
          <Form.Field>
            <Label>Re-type Password:</Label>
            <Input type='password' placeholder='************' value={retypedPassword}
              onChange={onRetypedPasswordChange} />
          </Form.Field>
          <Button type='submit' form='reset-password-form' content='Reset' />
        </Form>
      </FormContainer>
    </Container>
    <Footer />
    <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </TopContainer>
  );
};

const TopContainer = styled.div`
  position: relative;
`;

const BottomWave = styled(Wave)`
  position: absolute;
  bottom: 270px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: 280px;
`;

const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const FormContainer = styled.div`
  width: 400px;
  padding: 30px 20px 30px 20px;
  background-color: #2E607A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 33px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .ui.loading.form:before {
    background: transparent;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 140px 0 220px 0;
`;

const Title = styled.h1`
  color: #FFD042;
  font-family: 'Corbel Bold';
  font-weight: 100;
  font-size: 40px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
  font-variant: small-caps;
`;

const Label = styled.label`
  color: white !important;
  font-family: 'Corbel' !important;
  font-weight: 300 !important;
  font-size: 16px !important;
`;

const Input = styled(Form.Input)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
`;

const Button = styled(Form.Button)`
  text-align: center;
  
  .ui.button {
    margin-top: 34px;
    padding: 8px 30px 8px 30px;
    color: white;
    background-color: #23485B;
    font-family: 'Corbel';
    font-weight: 300;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.44), inset 0px 0px 5px #000000;
    border-radius : 10px;
    font-size: 16px;
  }
`;

export default ResetPasswordPage;
