import React, { useCallback, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer';
import Wave from 'react-wavify';

const reducer = (state, action) => {
  switch (action.type) {
    case 'email-changed': return { ...state, errorMessage: null, successMessage: null, email: action.email };
    case 'start-sending-mail': return { ...state, errorMessage: null, successMessage: null, loading: true };
    case 'sending-mail-failed': return { ...state, errorMessage: action.error, loading: false };
    case 'sending-mail-success': return { ...state, loading: false, successMessage: action.message };
    case 'validation-error': return { ...state, errorMessage: action.error };
  }
};

const ForgotPasswordPage = () => {
  const [state, dispatch] = useReducer(reducer, {
    email: '',
    loading: false,
    errorMessage: null,
    successMessage: null
  });
  const { email, loading, errorMessage, successMessage } = state;

  useEffect(() => {
    document.title = 'Wazeefati | Forgot Password';
    window.scrollTo(0, 0);
  }, []);

  const onChangeEmail = useCallback((e) => dispatch({ type: 'email-changed', email: e.target.value }), []);

  const validate = useCallback(() => {
    if (!email) {
      dispatch({ type: 'validation-error', error: 'Please enter your email.' });
      return false;
    }
    return true;
  }, [email]);

  const onSubmit = useCallback(() => {
    if (validate()) {
      dispatch({ type: 'start-sending-mail' });
      getApiClient().sendUpdatePasswordMail(email)
        .then(response => {
          if (response.data.success) {
            dispatch({ type: 'sending-mail-success', message: 'Reset email sent, check your inbox.' });
          } else {
            dispatch({ type: 'sending-mail-failed', error: response.data.message });
          }
        })
        .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'sending-mail-failed', error: error })));
    }
  }, [email, validate]);

  return (
    <TopContainer>
    <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      <Navbar />
      <Container>
        <FormContainer>
          <Title>Update Your Password</Title>
          <Form {...{ error: !!errorMessage }} {...{ loading: loading }} unstackable onSubmit={onSubmit}
            id='forgot-password-form'>
            {errorMessage && <p style={{ color: '#FFD042', fontSize: '20px' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: '#25C935', fontSize: '20px' }}>{successMessage}</p>}
            <Form.Field>
              <Label>Email:</Label>
              <Input type='email' placeholder='Ex: myusername@gmail.com' value={email}
                onChange={onChangeEmail} />
            </Form.Field>
            <Button type='submit' form='forgot-password-form' content='Send' />
          </Form>
        </FormContainer>
      </Container>
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <Footer />
    </TopContainer>
  );
};

const TopContainer = styled.div`
  position: relative;
`;

const BottomWave = styled(Wave)`
  position: absolute;
  bottom: 270px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: 280px;
`;

const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const FormContainer = styled.div`
  width: 400px;
  padding: 30px 20px 30px 20px;
  background-color: #2E607A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 33px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .ui.loading.form:before {
    background: transparent;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 140px 0 220px 0;
`;

const Title = styled.h1`
  color: #FFD042;
  font-family: 'Corbel Bold';
  font-weight: 100;
  font-size: 40px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
  font-variant: small-caps;
`;

const Label = styled.label`
  color: white !important;
  font-family: 'Corbel' !important;
  font-weight: 300 !important;
  font-size: 16px !important;
`;

const Input = styled(Form.Input)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
`;

const Button = styled(Form.Button)`
  text-align: center;
  
  .ui.button {
    margin-top: 34px;
    padding: 8px 30px 8px 30px;
    color: white;
    background-color: #23485B;
    font-family: 'Corbel';
    font-weight: 300;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.44), inset 0px 0px 5px #000000;
    border-radius : 10px;
    font-size: 16px;
  }
`;

export default ForgotPasswordPage;
