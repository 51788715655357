import React from 'react';
import styled from 'styled-components';
import { Form, Input, Label, Dropdown } from 'semantic-ui-react';
import Data from '../../countriesData';

export default function AboutYou(props) {
  return (
    <Fields>
      <BottomContainer>
        <Left>
          <Form.Field>
          <LabelItBig>your fields of activity</LabelItBig>
          <Box>
            <WrapBox>
              {props.activity.map((data, index) => {
                return (
                  /* eslint-disable-next-line */
                <>
                {/* eslint-disable-next-line */}
                <Drop key={index} index={index} value={data} onChange={props.AddActivity} placeholder='Activity' />
                {/* eslint-disable-next-line */}
                {index === 0 ? '' : <Rem onClick={() => props.RemoveActivity(index)}>-</Rem>}
                </>
                );
              })}
              <Plus onClick={props.AddNewActivity}>+</Plus>
            </WrapBox>
          </Box>
          </Form.Field>
        </Left>
        <Right>
            <LabelItBig>your location</LabelItBig>
          <Fieldbox>
            <Form.Field style={{ marginTop: '20px' }}>
              <LabelIt>city</LabelIt>
              <Digit value={props.city} onChange={props.AddCity} placeholder='Paris' />
            </Form.Field>
            <Form.Field style={{ marginTop: '20px' }}>
              <LabelIt>country</LabelIt>
              {/* eslint-disable-next-line */}
              <Country value={props.country} onChange={props.AddCountry} placeholder='Select Country' fluid search selection options={Data} />
            </Form.Field>
            <Form.Field>
            <LabelIt>time zone</LabelIt>
            <Timezone readonly value={props.timezone} labelPosition='right' type='number' placeholder='+3'>
                  <input />
              <Label Label>GMT</Label>
            </Timezone>
            </Form.Field>
          </Fieldbox>
        </Right>
      </BottomContainer>
    </Fields>
  );
}

const Fields = styled.div`
  display: flex;
  margin: 40px 0px 40px 0px;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height 100%;
`;
const Rem = styled.div`
  display: flex;
  margin: 11.5px 0px 0px -10px;
  font-size: 1.5rem;
  color: #FFC000;
  box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 40px;
  color: red;
  background: white;
`;
const BottomContainer = styled.div`
  display: flex;
  width: 100vw;
  min-height: 40vh;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 1107px){
    flex-direction: column;
  }
`;
const Left = styled.div`
  display: flex;
  box-sizing: border-box;
  padding-top: 30px;
  align-items: flex-start;
  justify-content: center;
  height: 40vh;
  width: 50%;
`;
const Right = styled.div`
  box-sizing: border-box;
  padding-top: 30px;
  height: 40vh;
  margin-right: 100px;
  width: 25%;
  @media screen and (max-width: 1107px){
    width: 90vw;
    margin-right: 0px;
  }
`;
const Box = styled.div`
  flex-wrap: wrap;
  width: 600px;
  margin-bottom: 20px;
  min-height:240px;
  box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
    @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const WrapBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  min-height: 10px;
    @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const Drop = styled(Form.Input)`
  margin: 10px !important;
  width: 250px !important;
  min-height: 10px !important;
  height: 35px !important;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
`;
const Plus = styled.div`
  display: flex;
  cursor: pointer;
  margin: 15px;
  font-size: 1.5rem;
  color: #FFC000;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #23485B;
  border-radius: 100px;
`;
const LabelIt = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-variant: small-caps;
  font-family: 'Corbel', sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.7rem !important;
`;
const LabelItBig = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-family: 'Corbel', sans-serif !important;
  font-variant: small-caps;
  font-weight: 200 !important;
  margin-bottom: 30px !important;
  font-size: 2rem !important;
`;
const Digit = styled(Form.Input)`
  width: 400px;
  height: 38px;
  margin: 5px !important;
  background: white;
  border-radius: 5px;
  .ui.input {
    width: 400px !important;
    height: 38px !important;
  }
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width:1107px){
    width: 90vw;
    .ui.input {
      width: 90vw !important;
    }
  }
`;
const Country = styled(Dropdown)`
  width: 400px !important;
  height: 28px !important;
  margin: 0px 5px !important;
  background: white;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    height: 38px !important;
  }
    div {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  .ui.search.dropdown>.text {
    margin-left: -25px !important;
  }
  .flag {
    display: none !important;
  }
  @media screen and (max-width: 1107px){
    width: 90vw !important;
  }
`;
const Fieldbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 200px;
`;
const Timezone = styled(Input)`
width: 400px !important;
height: 38px;
margin: 5px !important;
background: white;
border-radius: 5px;
input {
  width: 10px !important;
  height: 38px !important;
}
@media screen and (max-width: 1107px){
  width: 90vw !important;
}
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
`;
