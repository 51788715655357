import React, { useEffect, useReducer, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Loader } from 'semantic-ui-react';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import ShowMoreText from 'react-show-more-text';
import CloudUploadIcon from '../../svgs/download_icon.svg';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer.js';
import Wave from 'react-wavify';
import RequireUserAccess from '../../permissions/require_user_access';
import * as i18nIsoCountries from 'i18n-iso-countries';

const reducer = (state, action) => {
  switch (action.type) {
    case 'get-candidate-start': return { ...state, loading: true };
    case 'get-candidate-failed': return { ...state, loading: false, errorMessage: action.error };
    case 'get-candidate-success':
      return { ...state, candidate: action.candidate, loading: false };
    case 'show-contact-info': return { ...state, showContactInfo: !state.showContactInfo };
    default: throw new Error('Unhandled action type: ' + action.type);
  }
};

const isValidInstagramLink = (link) => {
  if (!link.includes('https') && !link.includes('instagram.com')) return false;
  return true;
};

const isValidLinkedinLink = (link) => {
  if (!link.includes('https') && !link.includes('linkedin.com')) return false;
  return true;
};

const isValidFacebookLink = (link) => {
  if (!link.includes('https') && !link.includes('facebook.com')) return false;
  return true;
};

const ProfilePage = () => {
  const [state, dispatch] = useReducer(reducer, {
    candidate: null,
    showContactInfo: false,
    loading: true,
    errorMessage: null
  });
  const {
    candidate,
    showContactInfo,
    loading,
    errorMessage
  } = state;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Wazeefati | Candidate';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: 'get-candidate-start' });
    getApiClient().getCandidate(id)
      .then(response => {
        if (!response.data.candidate.about) navigate('/page_not_found');
        else {
          dispatch({
            type: 'get-candidate-success',
            candidate: response.data.candidate
          });
        }
      })
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'get-candidate-failed', error: error })));
  }, [id, navigate]);

  const showInfo = useCallback(() => dispatch({ type: 'show-contact-info' }), []);

  const dawnloadCV = useCallback(async () => {
    if (candidate) {
      window.location.href = candidate.about.cv;
    }
  }, [candidate]);

  return (
    <div style={{ position: 'relative' }}>
      <Navbar />
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    {loading
    ? <Loader active={loading} size='medium'>Loading</Loader>
    : candidate &&
      <Container>
        <p style={{ color: 'red' }}>{errorMessage}</p>
        <Headline>{candidate.about.full_name}</Headline>
        <FirstFlex>
          <Title>{candidate.about.title}</Title>
          <Links>
            {candidate.about.facebook_link.length > 0 && isValidFacebookLink(candidate.about.facebook_link) &&
              <Link target='blank' href={candidate.about.facebook_link}>
                Facebook,
              </Link>}
            {candidate.about.instagram_link.length > 0 && isValidInstagramLink(candidate.about.instagram_link) &&
              <Link target='blank' href={candidate.about.instagram_link}>
                &nbsp;Instagram,
              </Link>}
            {candidate.about.linkedin_link.length > 0 && isValidLinkedinLink(candidate.about.linkedin_link) &&
              <Link target='blank' href={candidate.about.linkedin_link}>
                &nbsp;LinkedIn
              </Link>}
          </Links>
        </FirstFlex>
        <SecondFlex>
          <Paragraph>{candidate.about.years_of_experience} Years of Experience</Paragraph>
          <Paragraph style={{ textAlign: 'center' }}>
            Average Charge<br />
            ${candidate.about.min_hourly_charge} - ${candidate.about.max_hourly_charge} Hourly
          </Paragraph>
        </SecondFlex>
        <Paragraph2>{candidate.about.strengths}</Paragraph2>
        <Paragraph>Education:<br /></Paragraph>
        <Educations data={candidate.educations} />
        <Paragraph>Experience:<br /></Paragraph>
        <Experiences data={candidate.experiences} />
        {candidate.awards[0].award_from.length > 0 &&
          <>
            <Paragraph>Awards:<br /></Paragraph>
            <Awards data={candidate.awards} />
          </>}
        <BubblesContainer>
          <Paragraph>Key areas of Expertise:<br /></Paragraph>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {candidate.about.expertise.map(value =>
              <Bubble key={value}>{value}</Bubble>)}
          </div>
        </BubblesContainer>
        <BubblesContainer>
          <Paragraph>Key areas of Skills:<br /></Paragraph>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {candidate.about.skills.map(value =>
              <Bubble key={value}>{value}</Bubble>)}
          </div>
        </BubblesContainer>
        <Paragraph>Languages:<br /></Paragraph>
        <Languages data={candidate.languages[0].languages} />
        <Paragraph>References:<br /></Paragraph>
        <References data={candidate.references} />
        <InfoContainer>
          <div>
            {showContactInfo &&
              <ContactInfo>
                <Email href={`mailto:${candidate.about.email}`}>
                  {candidate.about.email}
                </Email>
                <p><Comma>,</Comma> {candidate.about.phone_number}</p>
              </ContactInfo>}
            <InfoButton onClick={showInfo}>
              {showContactInfo ? 'Hide Contact Info' : 'Show Contact Info'}
            </InfoButton>
          </div>
          {candidate.about.cv && <Button content={<BtnText>Download CV &nbsp;<img src={CloudUploadIcon} style={{ width: '24px' }} alt='upload_icon' /></BtnText>}
            onClick={dawnloadCV} />}
        </InfoContainer>
      </Container>}
      <Footer />
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
  );
};

const Container = styled.div`
  margin: 65px 0 158px 0;
  padding: 82px 152px 40px 152px;
  box-shadow: 0px 0px 14px 11px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 686px) {
    padding: 82px 100px 40px 100px;
  }
  @media screen and (max-width: 620px) {
    padding: 82px 60px 40px 60px;
  }
  @media screen and (max-width: 580px) {
    padding: 82px 40px 40px 40px;
  }
`;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: 270px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: 280px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const Headline = styled.h1`
  font-family: 'Corbel Bold';
`;

const FirstFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 2px solid gray;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

const SecondFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-top: 10px;
  border-bottom: 2px solid gray;

  @media screen and (max-width: 730px) {
    flex-direction: column;
    align-items: start;
    padding-bottom: 10px;
  }
`;

const Title = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 2px;
`;

const Links = styled.p`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 12px;
  padding-left: 30px;
  margin-bottom: 2px;

  @media screen and (max-width: 820px) {
    padding-left: 0;
    padding-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 400;
  font-size: 18px;
  margin: 6px 0 6px 0;
`;

const Paragraph2 = styled.p`
  font-family: 'Corbel';
  font-weight: 300;
  font-size: 18px;
  border-bottom: 2px solid gray;
  padding: 12px 0 12px 0;
`;

const BubblesContainer = styled.div`
  margin-top: 20px;
  border-bottom: 2px solid gray;
  padding-bottom: 12px;
`;

const Bubble = styled.div`
  border: 2px solid #23485B;
  color: #23485B;
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  margin-top: 12px;
  margin-left: 14px;
  font-family: 'Corbel';
  font-weight: 400;
`;

const Link = styled.a`
  font-size: 18px;
  color: black;
  font-family: 'Corbel Bold';

  &:hover {
    color: black;
  }
`;

const Email = styled.a`
  color: #2E607A;

  &:hover {
    color: #2E607A;
  }
`;

const InfoContainer = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;

  .ui.button {
    background-color: white;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0 30px 0 30px;
    height: 36px;
    font-family: 'Corbel';
    color: black;
    font-size: 16px;
    font-weight: 500 !important;
  }
`;

const ContactInfo = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 300;
  font-size: 16px;
  padding: 12px 0 0 0;
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

const InfoButton = styled.p`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
`;

const BtnText = styled.div`
  display: flex;
  align-items: center;
`;

const Comma = styled.span`
  @media screen and (max-width: 820px) {
    display: none;
  }
`;

/* eslint-disable react/no-array-index-key */
const Educations = ({ data }) => (
  <Content>
    <ShowMoreText more={<MoreLink>Show More</MoreLink>} less={<MoreLink>Show Less</MoreLink>}
      anchorClass={{ textDecoration: 'none' }}>
      <List>
        {data.map((x, index) =>
          <div key={index}>
            <ItemNumber>{x.education_from} &rarr; {x.education_to}<br /></ItemNumber>
            <ItemList>
              {x.institution}, {i18nIsoCountries.getName(x.country, 'en')}<br />
              Degree: {x.degree}<br />
              Major: {x.major}<br />
            </ItemList>
          </div>
        )}
      </List>
    </ShowMoreText>
  </Content>
);

const Experiences = ({ data }) => (
  <Content>
    <ShowMoreText more={<MoreLink>Show More</MoreLink>} less={<MoreLink>Show Less</MoreLink>}
      anchorClass={{ textDecoration: 'none' }}>
      <List>
        {data.map((x, index) =>
          <div key={index}>
            <ItemNumber>{x.experience_from} &rarr; {x.experience_to}<br /></ItemNumber>
            <ItemList>
              Organization: {x.organization}, {i18nIsoCountries.getName(x.country, 'en')}<br />
              Position: {x.latest_position}<br />
            </ItemList>
          </div>
        )}
      </List>
    </ShowMoreText>
  </Content>
);

const Awards = ({ data }) => (
  <Content>
    <ShowMoreText more={<MoreLink>Show More</MoreLink>} less={<MoreLink>Show Less</MoreLink>}
      anchorClass={{ textDecoration: 'none' }}>
      <List>
        {data.map((x, index) =>
          <div key={index}>
            <ItemNumber>{x.award_from} &rarr; {x.award_to}<br /></ItemNumber>
            <ItemList>
              Institution: {x.institution}, {x.country}<br />
              {/* Type: {x.type}<br /> */}
              Description: {x.description}<br />
            </ItemList>
          </div>
        )}
      </List>
    </ShowMoreText>
  </Content>
);

const References = ({ data }) => (
  <Content>
    <ShowMoreText more={<MoreLink>Show More</MoreLink>} less={<MoreLink>Show Less</MoreLink>}
      anchorClass={{ textDecoration: 'none' }}>
      <List>
        {data.map((x, index) =>
          <div key={index}>
            <ItemNumber>{x.name}<br /></ItemNumber>
            <ItemList>
              Organization: {x.organization}<br />
              Position: {x.position}<br />
              Email: <Email href={`mailto:${x.email}`}>{x.email}</Email><br />
              Phone Number: {x.phone_number}<br />
              Known Since: {x.known_since}<br />
            </ItemList>
          </div>
        )}
      </List>
    </ShowMoreText>
  </Content>
);

const Languages = ({ data }) => (
  <Content>
    <ShowMoreText more={<MoreLink>Show More</MoreLink>} less={<MoreLink>Show Less</MoreLink>}
      anchorClass={{ textDecoration: 'none' }}>
      <List>
        {data.map(x =>
          <div key={x} style={{ marginTop: '20px' }}>
            <ItemNumber>{x.language}<br /></ItemNumber>
            <ItemList>
              Level: {x.level}<br />
            </ItemList>
          </div>
        )}
      </List>
    </ShowMoreText>
  </Content>
);

const Content = styled.div`
  padding: 0 0 20px 20px;
  border-bottom: 2px solid gray;
`;

const MoreLink = styled.a`
  color: #2E607A;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
    color: #2E607A;
  }
`;

const List = styled.ul`
  padding-left: 20px;
`;

const ItemNumber = styled.li`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 16px;
  margin: 8px 0 0 8px;
`;

const ItemList = styled.div`
  padding-left: 16px;
`;

export default RequireUserAccess(ProfilePage);
