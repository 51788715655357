import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Wave from 'react-wavify';
import Navbar from '../../Common/navbar.js';
// import AlgosLogo from '../../svgs/algoshub.svg';
// import Sanand from '../../svgs/sanadLogo.svg';
// import Paragon from '../../svgs/paragonlogo.JPG';
import Footer from '../../Common/footer.js';
import LanguageText from '../language_text/language_text.js';
import LanguageContext from '../../contexts/language_context';

export default function About() {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    document.title = 'Wazeefati | About Us';
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div style={{ position: 'relative' }}>
    <Navbar />
    <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    <Fields language={language}>
        <Congrats language={language}><LanguageText text='AboutSanadWazeefati' /></Congrats>
        <SmallPara>
          <p>
          <LanguageText text='AboutPart1' />
          <a href="https://www.google.com/url?sa=D&q=http://www.sanadleb.org&ust=1687373040000000&usg=AOvVaw0UtuF3tIISsRfrjiFbFtD3&hl=en&source=gmail">
          <LanguageText text='AboutPart2' />
          </a>
          <LanguageText text='AboutPart3' />
          </p>
          <p>
          <LanguageText text='AboutPart4' />
          <a href="https://www.google.com/url?sa=D&q=http://www.paragon-bi.com&ust=1687373040000000&usg=AOvVaw1yEpQD_WiM7Z05-L0badUu&hl=en&source=gmail">
          <LanguageText text='AboutPart5' />
          </a>
          <LanguageText text='AboutPart6' />
          <a href="https://www.algoshub.tech"><span style={{ color: '#406B9C', fontWeight: 'bold' }}>AlgosHub</span></a>
          <LanguageText text='AboutPart7' />
          </p>
        </SmallPara>
    {/* <Logos>
      <Image src={AlgosLogo} />
        </a>
        <a href="https://www.paragon-bi.com/">
      <Image src='https://wazeefati-s3.s3.eu-central-1.amazonaws.com/paragonlogo.JPG' />
    </Logos> */}
    </Fields>
    <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
    <Footer />
    </>
  );
};

const Fields = styled.div`
  display: flex;
  direction: ${props => props.language === 'ar' ? 'rtl' : 'ltr'};
  flex-direction: column;
  margin: 100px 0px 50px 0px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 100px;
  min-height 100%;
  @media screen and (max-width: 745px){
    margin-bottom: 20px !important;
    padding: 30px;
    margin: 200px 0px 50px 0px;
  }
`;
// const Logos = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   width: 70vw;
// `;
// const Image = styled.img`
//   width: 200px;
//   margin: 0px 20px 40px 40px;
//   height: auto;
// `;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -55px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -55px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const Congrats = styled.div`
margin-top: -30px;
direction: ${props => props.language === 'ar' ? 'rtl' : 'ltr'};
margin-bottom: 20px;
width: 70vw;
font-family: 'Corbel';
font-style: normal;
font-size: 44px;
line-height: 55px;
/* identical to box height */

font-variant: all-small-caps;

color: #FFC000;
@media screen and (max-width: 824px) {
    font-size: 40px;
    text-align: center;
}
`;
const SmallPara = styled.div`
width: 70vw;
margin-bottom: 100px;
min-height: 100%;
margin-top: 1px;
font-family: 'Corbel';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 55px;
/* identical to box height */
@media screen and (max-width: 745px){
  font-size: 20px;
  width: 80vw;
  }
color: black;
`;
