import React, { useCallback } from 'react';
import styled from 'styled-components';
import CheckMark from '../../svgs/Checkmark.svg';
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

export default function ProfileComplete() {
    const navigate = useNavigate();
    const nextPage = useCallback(() => {
        navigate('/dashboard');
    }, [navigate]);
  return (
    <Fields>
        <Image src={CheckMark} />
        <Congrats>Congratulations</Congrats>
        <SmallPara>
        you have just posted an opportunity on <span style={{ color: '#23485B' }}>Sanad Wazeefati.</span>
        </SmallPara>
            <Span>Please visit your dashboard soon to check who applied.</Span>
        <ButtonS onClick={nextPage}>visit dashboard</ButtonS>
    </Fields>
  );
};

const Span = styled.span`
  font-size: 20px;

  @media screen and (max-width: 774px){
    font-size: 15px;
    text-align: center;
    width: 100vw;
    padding: 0px 30px;
  }
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0px 50px 0px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height 65vh;
  @media screen and (max-width: 745px){
    margin-bottom: 20px !important;
  }
`;
const Image = styled.img`
  width: 220px;
`;
const Congrats = styled.div`
margin-top: -30px;
font-family: 'Corbel';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 55px;
/* identical to box height */

font-variant: all-small-caps;

color: #FFC000;
@media screen and (max-width: 774px){
  font-size: 40px;
}
`;
const SmallPara = styled.div`
text-align: center;
width: 60vw;
margin-top: 1px;
font-family: 'Corbel';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 55px;
/* identical to box height */

font-variant: all-small-caps;

color: black;
@media screen and (max-width: 774px){
  font-size: 27px;
  line-height: 30px;
  margin-bottom: 10px;
}
`;
const ButtonS = styled(Button)`
margin-top: 50px !important;
background: rgba(255, 192, 0, 0.58) !important;
width: 300px;
margin-bottom: 40px !important;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5) !important;
font-family: 'Corbel';
font-style: normal;
font-weight: 400;
font-size: 20px !important;
line-height: 90.69%;
/* or 36px */

color: #23485B !important;

text-align: center;
font-variant: small-caps;

  
    color: #23485B;
`;
