import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import Navbar from '../../Common/navbar.js';
import Footer from '../../Common/footer.js';
import styled from 'styled-components';
import Lines from '../../svgs/Lines.svg';
import Company from '../../svgs/Company.svg';
import { useNavigate } from 'react-router-dom';
import Wave from 'react-wavify';
import LanguageText from '../language_text/language_text.js';
import LanguageContext from '../../contexts/language_context';

const HomePage = () => {
  useEffect(() => {
    document.title = 'Wazeefati | Home';
  }, []);

  const ref = useRef(null);

  const navigate = useNavigate();
  const RegisterEmployee = useCallback(() => {
    if (localStorage.getItem('wazeefati_token') === null) {
      navigate('/signup/employer');
    } else {
      navigate('/candidates');
    }
  }, [navigate]);
  const RegisterCandidate = useCallback(() => {
    if (localStorage.getItem('wazeefati_token') === null) {
      navigate('/signup/candidate');
    } else {
      navigate('/jobs');
    }
  }, [navigate]);
  const handleClick = useCallback(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const [scrollY, setScrollY] = useState(0);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    document.title = 'Wazeefati | Home';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const AddAnimation = {
    animationName: scrollY >= 250 ? 'fadeInRight' : '',
    display: scrollY >= 250 ? 'flex' : 'none'
  };
  const AddAnimationLeft = {
    animationName: scrollY >= 650 ? 'fadeInLeft' : '',
    display: scrollY >= 650 ? 'flex' : 'none',
    flexDirection: 'row-reverse'
  };
  return (
    <Body>
      <TopContainer>
        <Navbar />
        <SamllFlex>
          <FlexIt>
          <BigTitle>
            <LanguageText text='sanadwazeefati' />
          </BigTitle>
          </FlexIt>
          <RelativeSmallTitle language={language}><LanguageText text='Engage Lebanese Talent' /></RelativeSmallTitle>
          <TextCont>
            <Text language={language}>
              <LanguageText text='HomepageParagraph' />
            </Text>
            <StartButton>
            <Button onClick={handleClick} language={language}><LanguageText text='HomepageButtonText' /></Button>
            </StartButton>
          </TextCont>
        </SamllFlex>
        <ImgContainer>
        <a target='blank' href='https://sanadleb.org'><Img src='https://wazeefati-s3.s3.eu-central-1.amazonaws.com/sanad.png' /></a>
        </ImgContainer>
        <BottomWave fill='#FFFFFF'
        paused={false}
        options={{
          height: 50,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
      </TopContainer>
      <BottomPart ref={ref}>
        <div>
          <Grid onClick={RegisterCandidate} style={AddAnimation}>
            <ImgBox>
            <Images src='https://wazeefati-s3.s3.eu-central-1.amazonaws.com/JobSeekers.jpg' />
              <UnderImageTitle language={language}>
              <LanguageText text='Join the crowd!' />
              </UnderImageTitle>
            </ImgBox>
            <TitleAndText>
              <GridTitle language={language}><LanguageText text='Job Seekers' /></GridTitle>
              <GridText language={language}>
              <LanguageText text='JopeSeekerText' />
              </GridText>
            </TitleAndText>
          </Grid>
          {scrollY > 250 && scrollY < 650 ? <Grid style={{ height: '590px' }} /> : ''}
          <Grid onClick={RegisterEmployee} style={AddAnimationLeft}>
            <ImgBox>
              <Images src={Company} />
              <UnderImageTitle language={language}>
                <LanguageText text='Register Now!' />
              </UnderImageTitle>
            </ImgBox>
            <TitleAndText>
              <GridTitle language={language}><LanguageText text='Employers' /></GridTitle>
              <GridText language={language}>
              <LanguageText text='EmployerText' />
              </GridText>
            </TitleAndText>
          </Grid>
        </div>
        <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#EFF6FA'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      </BottomPart>
      <Footer />
    </Body>
  );
};

export default HomePage;

const Body = styled.body`
  margin: 0px !important;
  padding: 0px !important;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: 10px;
`;
const TopContainer = styled.div`
  position: relative;
  margin-top: -1vh !important;
  padding-top: 1vh !important;
  box-sizing: border-box;
  background: url('https://wazeefati-s3.s3.eu-central-1.amazonaws.com/Background.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media screen and (max-width: 1180px ){
    
  }
  @media screen and (max-width: 878px){

  }
  @media screen and (max-height: 750px){
    min-height: 150vh;
  }
  @media screen and (max-height: 682px){
    min-height: 160vh;
  }
  @media screen and (max-height: 641px){
    min-height: 170vh;
  }
  @media screen and (max-height: 610px){
    min-height: 180vh;
  }
  @media screen and (max-width: 280px ){
    min-height: 185vh;
  }
`;
const FlexIt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
    @media screen and (max-width: 878px){
      flex-direction: column;
  }
`;
const BottomPart = styled.div`
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  min-height: 150vh;
  background: url(${Lines});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;
const SamllFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;
const BigTitle = styled.div`
font-style: normal;
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 68px;
  line-height: 90.69%;
  /* identical to box height, or 62px */

  text-align: center;
  font-variant: small-caps;

  color: #FFD042;

  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
  animation-duration: 1s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRightIT;
    @keyframes fadeInRightIT {
            0% {
               opacity: 0;
               transform: translateY(-40px);
            }
            50%{
              opacity: 0.5;
            }
            100% {
               opacity: 1;
               transform: translateY(0px);
            }
         }
`;
const RelativeSmallTitle = styled.div`
  direction: ${props => props.language === 'ar' ? 'rtl' : 'ltr'};
  font-family: ${props => props.language === 'ar' ? 'Cairo' : 'Corbel'} !important;
  width: 60vw !important;
  margin-top: 60px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 124.52%;
  /* identical to box height, or 64px */
  letter-spacing:  ${props => props.language === 'ar' ? '0em' : '0.08em'};
  color: #FFFFFF;
  width: 80vw;
  ${'' /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */}
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 1s;
    @keyframes fadeInRight {
            0% {
               opacity: 0;
               transform: translateX(-40px);
            }
            100% {
               opacity: 1;
               transform: translateX(0);
            }
         }
  @media screen and (max-width: 600px){
    font-size: 35px;
    margin-top: 55px;
    width: 90vw;
  }
  @media screen and (max-width: 400px){
    font-size: 30px;
    width: 70vw !important;
  }
`;
const TextCont = styled.div`
  position: relative;
  width: 60vw;
  animation-duration: 1s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
    @keyframes fadeInRight {
            0% {
               opacity: 0;
               transform: translateX(-40px);
            }
            50%{
              opacity: 0.5;
            }
            100% {
               opacity: 1;
               transform: translateX(0);
            }
         }
  @media screen and (max-width: 600px){
    margin-left: -20px;
  }
`;
const Text = styled.div`
  width: 60vw;
  direction: ${props => props.language === 'ar' ? 'rtl' : 'ltr'};
  background: rgba(35, 72, 91, 0.28);
  box-shadow: 0px 4px 30px 35px rgba(35, 72, 91, 0.32);
  border-radius: 5px;
  font-family: 'Corbel';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 124.52%;
  /* or 37px */
  letter-spacing: 0.035em;
  color: #FFFFFF;
  @media screen and (max-width: 1136px){
    font-size: 25px;
  }
    @media screen and (max-width: 600px){
    font-size: 18px;
    width: 60vw;
  }
`;
const StartButton = styled.div`
  @media screen and (max-width: 1000px){
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Button = styled.div`
font-family: Corbel;
margin-top: 31px;
bottom: -80px;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
width: 180px;
height: 41px;
cursor: pointer;
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 124.52%;
text-align: center;
letter-spacing: 0.1em;
color: rgb(35, 72, 91);
background: rgb(255, 255, 255);
box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 9px 3px;
border-radius: 3px;
`;
const BottomWave = styled(Wave)`
  position: absolute;
  height: 150px;
  bottom: -10px;
`;
const ImgContainer = styled.div`
  height: 200px;
`;
const Img = styled.img`
  position: absolute;
  z-index: 10;
  bottom: 80px;
  right: 100px;
  width: 150px;
  @media screen and (max-width: 1024px){
    right: 0px;
    width: 150px;
    left: 0px;
    margin: 0 auto;
  }
`;
const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 100px;
  cursor: pointer;
  width: 80vw;
  height: 350px;
  @media screen and (max-width: 1364px){
    flex-direction: column-reverse !important;
    height: auto;
  }
    animation-duration: 1s;
    @keyframes fadeInRight {
            0% {
               opacity: 0;
               transform: translateX(-40px);
            }
            100% {
               opacity: 1;
               transform: translateX(0);
            }
         }
    @keyframes fadeInLeft {
            0% {
               opacity: 0;
               transform: translateX(40px);
            }
            100% {
               opacity: 1;
               transform: translateX(0);
            }
         }
`;
const ImgBox = styled.div`
  height: 100%;
  width: 306px;
  background: rgba(255, 255, 255, 0.78);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.42), 0px 11px 5px 3px rgba(0, 0, 0, 0.05);
`;
const Images = styled.img`
  object-fit: cover;
  width: 100%;
  height: 280px;
`;
const UnderImageTitle = styled.div`
  font-family: ${props => props.language === 'ar' ? 'Cairo' : 'Corbel Bold'} !important;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 20%;
  margin-top: -6px;
  font-family: 'Corbel';
  font-variant: small-caps;
  font-style: normal;
  font-size: 24px;
  line-height: 124.52%;
  /* identical to box height, or 30px */

  color: #23485B;
`;
const TitleAndText = styled.div`
  display: flex;
  height: 100%;
  width: 700px;
  flex-direction: column;
  align-itmes: center;
  justify-content: center;
  @media screen and (max-width: 1364px){
    margin-bottom: 20px;
    width: 90vw;
  }
`;
const GridTitle = styled.div`
  direction: ${props => props.language === 'ar' ? 'rtl' : 'ltr'};
  margin-bottom: 30px;
  font-family: ${props => props.language === 'ar' ? 'Cairo' : 'Corbel Bold'} !important;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 124.52%;
  /* identical to box height, or 45px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #23485B;

  ${'' /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */}
  @media screen and (max-width: 1364px){
    margin-top: 20px;
    padding-left: 20px;

  }
`;
const GridText = styled.div`
  direction: ${props => props.language === 'ar' ? 'rtl' : 'ltr'};
  font-family: ${props => props.language === 'ar' ? 'Cairo' : 'Corbel'};
  height: 70%;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 124.52%;
  /* or 37px */
  letter-spacing: 0.035em;
  *{
    font-family: ${props => props.language === 'ar' ? 'Cairo' : 'Corbel'} !important;
  }
  @media screen and (max-width: 1364px){
    width: 90vw;
    padding: 0px 20px;
    text-align: left;
    font-size: 20px;
  }
`;
