import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RequireLogout = (Component) => {
  const LogoutComponent = (props) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('wazeefati_token');

    useEffect(() => {
      if (token) {
        navigate('/');
      }
    }, [token, navigate]);

    if (token) {
      return null;
    } else {
      return <Component {...props} />;
    }
  };

  return LogoutComponent;
};

export default RequireLogout;
