import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { UserContext } from '../contexts/user_context';

const RequireUserResume = (Component, userStatus = null) => {
  const RequireUserAccessComponent = (props) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem('wazeefati_token');

    useEffect(() => {
      if (!token) {
        navigate('/login');
        return;
      }
      setIsLoaded(false);
      if (user) {
        setIsLoaded(true);
        if (userStatus && user.status !== userStatus) {
          navigate('/page_not_found');
        }
      }
    }, [navigate, user, token]);

    if (!isLoaded || !token || (userStatus && user.status !== userStatus)) {
      return <Loader active>Loading</Loader>;
    } else {
      return <Component {...props} />;
    }
  };

  return RequireUserAccessComponent;
};

export default RequireUserResume;
