import React, { useCallback, useEffect, useReducer, useContext } from 'react';
import styled from 'styled-components';
import { Form, Loader, Modal } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import RequireUserAccess from '../../permissions/require_user_access';
import Pic from '../../svgs/exclamation.png';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import Navbar from '../../Common/navbar.js';
import Footer from '../../Common/footer.js';
import { UserContext } from '../../contexts/user_context';
import Wave from 'react-wavify';
import CheckMark from '../../svgs/Checkmark.svg';

const reducer = (state, action) => {
  switch (action.type) {
    case 'get-job-start': return { ...state, loading: true };
    case 'get-job-failed':
    case 'close-modal': return { ...state, notificationClose: false };
    case 'open-modal': return { ...state, notificationClose: true };
    case 'submit-job-failed': return { ...state, loading: false, errorMessage: action.error };
    case 'get-job-success':
      return {
        ...state,
        job: action.job,
        submitted: action.submitted,
        loading: false
      };
    case 'submit-job-start': return { ...state, loading: true, errorMessage: null };
    case 'submit-job-success': return { ...state, loading: false, completeSubmit: true };
    default: throw new Error('Unhandled action type: ' + action.type);
  }
};

const JobPost = () => {
  const [state, dispatch] = useReducer(reducer, {
    job: null,
    notificationClose: false,
    submitted: false,
    completeSubmit: false,
    loading: true,
    errorMessage: null
  });
  const {
    job,
    submitted,
    notificationClose,
    completeSubmit,
    loading,
    errorMessage
  } = state;
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    document.title = 'Wazeefati | Job Post';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: 'get-job-start' });
    getApiClient().getJob(id)
      .then(response => {
        if (!response.data.job) navigate('/page_not_found');
        else dispatch({ type: 'get-job-success', job: response.data.job, submitted: response.data.submitted });
      })
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'get-job-failed', error: error })));
  }, [id, navigate]);

  const onSubmit = useCallback(() => {
    dispatch({ type: 'submit-job-start' });
    getApiClient().submitJobApplication(id)
      .then(_ => dispatch({ type: 'submit-job-success' }))
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'submit-job-failed', error: error })));
  }, [id]);

  const dawnloadDesc = useCallback(async () => {
    if (job) {
      window.location.href = job.description_file;
    }
  }, [job]);

  const LogoutHandler = useCallback((l) => {
    localStorage.removeItem('wazeefati_token');
    navigate('/signup/candidate');
}, [navigate]);

  const onCloseModal = useCallback(() => dispatch({ type: 'close-modal' }), []);
  const onOpenModal = useCallback(() => dispatch({ type: 'open-modal' }), []);

  const goToJob = useCallback(() => {
    navigate(`/about_me/${id}`);
  }, [id, navigate]);

  return (
    <>
    <div style={{ position: 'relative' }}>
      <Navbar />
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      {loading
        ? <Loader active={loading} size='medium'>Loading</Loader>
        : completeSubmit
        ? <OnCompleteSubmit navigate={navigate} />
        : job &&
          <JobContainer>
            <Form {...{ error: !!errorMessage }} {...{ loading: loading }} unstackable onSubmit={onSubmit}
              id='job-form'>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <JobHeadline>{job.headline}</JobHeadline>
                {job.user_id === user.id && <EditButton content='Edit Job' onClick={goToJob} />}
              </div>
              <FirstFlex>
                <ProjectTitle>{job.project_title}</ProjectTitle>
                <PositionTitle>{job.position_title}</PositionTitle>
                <LevelOfExperience>Level of Experience &quot;{job.level_of_experience}&quot;</LevelOfExperience>
              </FirstFlex>
              <SecondFlex>
                <Paragraph>
                  Time Line: <Span>{job.job_timeline}</Span>
                  {job.job_timeline === 'specific' && <span>, Duration: <Span>{job.duration} {job.duration_type}</Span></span>}
                </Paragraph>
                <Paragraph>{job.years_of_experience} Years of Experience</Paragraph>
              </SecondFlex>
              <Description>{job.description}</Description>
              <BubblesContainer>
                <Paragraph>Key areas of Expertise:<br /></Paragraph>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {job.expertise.map(value =>
                    <Bubble key={value}>{value}</Bubble>)}
                </div>
              </BubblesContainer>
              <BubblesContainer>
                <Paragraph>Key areas of Skills:<br /></Paragraph>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {job.skills.map(value =>
                    <Bubble key={value}>{value}</Bubble>)}
                </div>
              </BubblesContainer>
              <FileContainer>
                <div style={{ marginTop: '20px' }}>
                  <Paragraph style={{ marginBottom: '0' }}>Average Payment:<br /></Paragraph>
                  <Payment>Min: ${job.min} - {job.amount_type}<br /></Payment>
                  <Payment>Max: ${job.max} - {job.amount_type}<br /></Payment>
                  <Payment>Maximum Project budget: ${job.maximum_project_budget}</Payment>
                </div>
                {job.description_file &&
                  <DownloadBtn>
                    <Button content={<BtnText>Download Description</BtnText>}
                      onClick={dawnloadDesc} />
                  </DownloadBtn>}
              </FileContainer>
              {user.status === 'candidate' &&
                <Button type='submit' form='job-form' content={submitted ? 'Application Sent' : 'Submit Your Application'}
                  disabled={submitted} />}
              {user.status === 'employer' &&
                <Button type='submit' onClick={onOpenModal} form='job-form' content={submitted ? 'Application Sent' : 'Submit Your Application'}
                  disabled={submitted} />}
              <CustomModal open={notificationClose} className='custom-modal' onClose={onCloseModal}>
                  <Modal.Content>
                    <PopIt>
                      <Img src={Pic} />
                      <Para>You are logged in as an Employer. Please logout and sign up as a
                         Candidate to be able to apply for this job.
                      </Para>
                      <Button type='submit'onClick={LogoutHandler} content='Sign up' />
                    </PopIt>
                  </Modal.Content>
              </CustomModal>
            </Form>
          </JobContainer>}
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
    <Footer />
    </>
  );
};

const DownloadBtn = styled.div`
`;

const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -165px;
`;
const PopIt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justifty-content: space-between;
  height: 100%;
  width: 100%;
`;

const CustomModal = styled(Modal)`
  @media screen and (max-width: 504px){
    width: 80vw !important;
  }
`;
const Para = styled.div`
  text-align: center;
  font-family: Corbel;
  width: 100%;
  font-size: 20px;
`;
const Img = styled.img`
  width: 100px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -165px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const BtnText = styled.div`
  display: flex;
  align-items: center;
`;

const FileContainer = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;

  .ui.button {
    background-color: white;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0 30px 0 30px;
    height: 36px;
    font-family: 'Corbel';
    color: black;
    font-size: 16px;
    font-weight: 500 !important;
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: start;
  }
`;

const JobContainer = styled.div`
  margin: 65px 0 158px 0;
  padding: 82px 152px 40px 152px;
  box-shadow: 0px 0px 14px 11px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 686px) {
    padding: 82px 100px 40px 100px;
  }
  @media screen and (max-width: 620px) {
    padding: 82px 60px 40px 60px;
  }
  @media screen and (max-width: 580px) {
    padding: 82px 40px 40px 40px;
  }
`;

const JobHeadline = styled.h1`
  font-family: 'Corbel Bold';
  font-weight: 400;
`;

const FirstFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 2px solid gray;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }
`;

const SecondFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  border-bottom: 2px solid gray;

  @media screen and (max-width: 780px) {
    margin-top: 20px;
    padding-bottom: 20px !important;
    flex-direction: column;
  }
`;

const ProjectTitle = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 2px;
`;

const PositionTitle = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 400;
  padding-left: 58px;
  font-size: 18px;
  margin-bottom: 2px;

  @media screen and (max-width: 780px) {
    padding-left: 0;
  }
`;

const LevelOfExperience = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 400;
  font-size: 18px;
  padding-left: 30px;
  margin-bottom: 2px;

  @media screen and (max-width: 780px) {
    padding-left: 0;
    padding-bottom: 6px;
  }
`;

const Paragraph = styled.p`
  font-family: 'Corbel Bold';
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 2px;
`;

const Description = styled.p`
  font-family: 'Corbel';
  font-weight: 300;
  font-size: 18px;
  border-bottom: 2px solid gray;
  padding-bottom: 12px;
`;

const Span = styled.span`
  font-weight: 300;
`;

const BubblesContainer = styled.div`
  margin-top: 20px;
  border-bottom: 2px solid gray;
  padding-bottom: 12px;
`;

const Bubble = styled.div`
  border: 2px solid #23485B;
  color: #23485B;
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  margin-top: 12px;
  margin-left: 14px;
  font-family: 'Corbel';
  font-weight: 400;
`;

const Payment = styled.div`
  margin: 2px 0 0 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
`;

const Button = styled(Form.Button)`
  text-align: center;

  .ui.button {
    margin-top: 26px;
    padding: 9px 40px 8px 40px;
    color: #23485B;
    background-color: rgba(255, 255, 255, 0.58);
    font-family: 'Corbel';
    font-weight: 400;
    box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
    border-radius : 8px;
    font-size: 18px;
  }
`;

const EditButton = styled(Form.Button)`
  text-align: center;

  .ui.button {
    padding: 9px 20px 8px 20px;
    color: #23485B;
    background-color: rgba(255, 255, 255, 0.58);
    font-family: 'Corbel';
    font-weight: 400;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius : 8px;
    font-size: 18px;
  }
`;

const OnCompleteSubmit = ({ navigate }) => {
  const nextPage = useCallback(() => navigate('/jobs'), [navigate]);

  return (
    <Fields>
      <Image src={CheckMark} />
      <Congrats>Congratulations</Congrats>
      <SmallPara>
        You just applied for this job position.
      </SmallPara>
          <Span1>You can search for more jobs. </Span1>
      <ButtonS onClick={nextPage}>Continue</ButtonS>
    </Fields>
  );
};

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0px 50px 0px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height 65vh;
  @media screen and (max-width: 745px){
    margin-bottom: 20px !important;
  }
`;

const Image = styled.img`
  width: 220px;
`;
const Congrats = styled.div`
margin-top: -30px;
font-family: 'Corbel';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 55px;
/* identical to box height */

font-variant: all-small-caps;

color: #FFC000;
@media screen and (max-width: 774px){
  font-size: 40px;
}
`;
const Span1 = styled.span`
  font-size: 20px;

  @media screen and (max-width: 774px){
    font-size: 15px;
    text-align: center;
    width: 100vw;
    padding: 0px 30px;
  }
`;
const SmallPara = styled.div`
text-align: center;
width: 60vw;
margin-top: 1px;
font-family: 'Corbel';
font-style: normal;
font-weight: 400;
font-size: 47px;
line-height: 55px;
/* identical to box height */

font-variant: all-small-caps;

color: black;
@media screen and (max-width: 774px){
  font-size: 27px;
  line-height: 30px;
  margin-bottom: 10px;
}
`;
const ButtonS = styled(Button)`
    margin-top: 20px !important;
    width: 300px;
    margin-bottom: 40px !important;
    font-family: 'Corbel';
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 90.69%;
    /* or 36px */

    color: #23485B !important;

    text-align: center;
    font-variant: small-caps;
  
    color: #23485B;
`;

export default RequireUserAccess(JobPost);
