import React from 'react';
import styled from 'styled-components';
import { Form, Dropdown, Checkbox } from 'semantic-ui-react';
import CloudUploadIcon from '../../svgs/cloud_upload_icon.svg';
import Data from '../../countriesData';

export default function SlillsEx(props) {
  return (
    <Fields>
      <Bigger>
      {props.skillsExp.map((data, index) => {
          return (
            /* eslint-disable-next-line */
      <Container key={index}>
      <Form.Field>
        <Label>from</Label>
        <Digit value={data.experience_from} index={index} onChange={props.Addskillsfrom} placeholder='YYYY' min='0' type='text' />
      </Form.Field>
      <Dash>-</Dash>
      <Form.Field>
        <Label>to</Label>
        <Digit disabled={data.present} value={data.experience_to} index={index} onChange={props.Addskillsto} placeholder='YYYY' type='text' />
        <Checkbox checked={data.present} index={index} onClick={props.skillsExpPresent} style={{ margin: '10px 0px 0px 10px' }} label={{ children: 'Present' }} />
      </Form.Field>
      <Inst>
      <Form.Field>
      <Label>employed at</Label>
      <RadioContainer>
        <Radio checked={props.skillsExp[index].employed_at === 'company'} index={index} onChange={props.Addskillsat} value='company' label='Company' />
        <Radio checked={props.skillsExp[index].employed_at === 'self-employed'} index={index} onChange={props.Addskillsat} value='self-employed' label='Self-Employed' />
      </RadioContainer>
      </Form.Field>
      <Form.Field>
        <Label>organization</Label>
        <Name value={data.organization} index={index} onChange={props.Addskillsorg} placeholder='Company name' />
      </Form.Field>
      </Inst>
      <Form.Field>
        <Label>country</Label>
        <Country value={data.country} index={index} onChange={props.Addskillscountry} placeholder='Select Country' fluid search selection options={Data} />
      </Form.Field>
      <Form.Field>
        <Label>latest position</Label>
        <Position value={data.latest_position} index={index} onChange={props.Addskillspos} placeholder='eg: Software Developer' />
      </Form.Field>
      {/* eslint-disable-next-line */}
      {index === 0 ? '' : <Rem onClick={() => props.RemoveSkillsExp(index)}>-</Rem>}
      </Container>);
              })}
        <Plus onClick={props.AddskillsExp}>+</Plus>
      </Bigger>
      <Bottom>
      <Form.Field style={{ marginTop: '40px' }}>
      <Label>cv</Label>
      <FileFlex>
              <FileLabel htmlFor='file-upload' id='file-label'>
                <span>Please upload your CV &nbsp;</span>
                <img src={CloudUploadIcon} alt='upload_icon' />
              </FileLabel>
                <input id='file-upload' onChange={props.Addcv} type='file' hidden />
                {!props.cv
                    ? <small style={{ color: 'red', fontSize: '11px' }}>PDF or IMG of Max Size 3MB*</small>
                    : <small style={{ fontSize: '12px' }}>{props.cv.name}</small>}
      </FileFlex>
      </Form.Field>
      <Form.Field style={{ marginTop: '20px' }}>
        <Label>your linkedin profile link</Label>
        <Links value={props.linkedin} onChange={props.Addlinikedin} />
      </Form.Field>
      <Form.Field style={{ marginTop: '15px' }}>
        <Label>your instagram profile Link</Label>
        <Links value={props.instagram} onChange={props.Addinstagram} />
      </Form.Field>
      <Form.Field>
        <Label>your facebook profile link</Label>
        <Links value={props.facebook} onChange={props.Addfacebook} />
      </Form.Field>
      </Bottom>
    </Fields>
  );
}

const FileFlex = styled.div`
  display: flex;
  flex-direction: column;

  > label:not(#file-label) {
    font-family: 'Cairo', sans-serif;
    font-weight: bold;
    font-size: 13px;
  }
`;
const Rem = styled.div`
display: flex;
margin: 25.5px 0px 0px -10px;
font-size: 1.5rem;
color: #FFC000;
box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
align-items: center;
justify-content: center;
width: 30px;
height: 40px;
cursor: pointer;
color: red;
background: white;
@media screen and (max-width: 765px){
  width: 90vw;
  margin: 31.5px 0px 0px 0px;
}
`;
const FileLabel = styled.label`
  width: 400px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-top: 5px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.28571429rem;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;

  :hover {
    border: 1px solid black;
  }
  @media screen and (max-width: 1107px){
    width: 90vw;
  }
`;
const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  margin-top: 40px;
  border-top: 1px solid black;
  min-height: 200px;
  padding: 0px 200px !important;
  width: 90%;
  @media screen and (max-width: 745px){
    padding: 0px !important;  }
`;
const Dash = styled.div`
  margin-top: 30px;
  font-size: 30px;
  @media screen and (max-width: 745px){
    display: none;
  }
`;
const Bigger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 745px){
    margin-bottom: 35px !important;
  }
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0px 50px 0px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  min-height 65vh;
  @media screen and (max-width: 745px){
    margin-bottom: 20px !important;
  }
`;
const Plus = styled.div`
  position: absolute;
  right: -15px;
  bottom: -40px;
  display: flex;
  margin: 15px;
  font-size: 1.5rem;
  color: #FFC000;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #23485B;
  border-radius: 100px;
`;
const Container = styled.div`
display: flex;
flex-wrap: wrap;
align-items: flex-start;
width: 73vw;
margin-top: 20px;
@media screen and (max-width: 745px){
  justify-content: center;
  margin-bottom: 20px !important;
  border-top: 1px solid black;
  width: 90vw !important;
}
`;
const Label = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-family: 'Corbel' !important;
  font-variant: small-caps;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
`;
const Digit = styled(Form.Input)`
width: 100px;
height: 38px;
background: white;
margin: 0px 5px !important;
border-radius: 5px;
input {
  color: rgb(35, 72, 91) !important;
  font-size: 16px !important;
}
input {
  color: rgb(35, 72, 91) !important;
  font-size: 16px !important;
}
> input{
  height: 35px;
}
@media screen and (max-width: 745px){
  width: 90vw !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
`;
const Inst = styled.div`
  display: flex;
  margin: 0px 5px !important;
  align-items: center;
  justify-content: space-between;
  width: 420px;
  @media screen and (max-width: 745px){
    width: 91vw !important;
  }
`;
const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0px 5px !important;
  width: 150px;
  height: 41px;
  background: white;
  box-shadow: 0px 0px 0.1px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 0.1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
    @media screen and (max-width: 745px){
    margin: 0px 5px 0px 0px!important;
    width: 40vw !important;
  }
`;
const Radio = styled(Form.Radio)`
  width: 120px;
  margin: 0px !important;
  label {
    white-space: nowrap;
  }
`;
const Name = styled(Form.Input)`
  width: 250px;
  height: 38px;
  background: white;
  margin: 0px 5px 15px 5px!important;
  border-radius: 5px;
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    margin: 0px 0px 15px 5px!important;
    width: 48vw !important;
  }
`;
const Country = styled(Dropdown)`
  width: 160px !important;
  height: 43.11px !important;
  margin: 0px 5px !important;
  background: white;
  border-radius: 5px;
    input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    height: 45px !important;
  }
  .text {
    margin-top: 3px;
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
    height: 45px !important;
  }
  .flag {
    display: none !important;
  }
  .icon{
    margin-top: -5px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
const Links = styled(Form.Input)`
  width: 400px;
  height: 38px;
  background: white;
  border-radius: 5px;
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
const Position = styled(Form.Input)`
  width: 250px;
  height: 38px;
  background: white;
  border-radius: 5px;
  margin: 0px 5px 0px 5px!important;
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 745px){
    width: 90vw !important;
  }
`;
