import React, { useCallback, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Form, Modal, Icon, Dropdown, Checkbox } from 'semantic-ui-react';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import { Link, useParams } from 'react-router-dom';
import Lines from '../../svgs/Lines.svg';
import RequireLogout from '../../permissions/require_logout';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer';
import Wave from 'react-wavify';
import EmailIcon from '../../svgs/icons8-mail-400 2.svg';
import CountryPhoneInput from '../../utils/country_phone_input';
import { getScreenWidth } from '../../helpers/screen_width';
import './style.css';
import Data from '../../countriesData';

const reducer = (state, action) => {
  switch (action.type) {
    case 'start-signup': return { ...state, loading: true, errorMessage: null };
    case 'companyName-changed': return { ...state, errorMessage: null, companyName: action.companyName };
    case 'companyWebsite-changed': return { ...state, errorMessage: null, companyWebsite: action.companyWebsite };
    case 'signup-failed': return { ...state, loading: false, errorMessage: action.error };
    case 'signup-success': return { ...state, loading: false, emailSent: true };
    case 'name-changed': return { ...state, errorMessage: null, fullName: action.fullName };
    case 'email-changed': return { ...state, errorMessage: null, email: action.email };
    case 'phoneNumber-changed': return { ...state, errorMessage: null, phoneNumber: action.phoneNumber };
    case 'password-changed': return { ...state, errorMessage: null, password: action.password };
    case 'confirm-password-changed': return { ...state, errorMessage: null, confirmPassword: action.confirmPassword };
    case 'toggle-show-password': return { ...state, passwordShown: !state.passwordShown };
    case 'reg-nb-changed': return { ...state, errorMessage: null, regNb: action.value };
    case 'reg-place-changed': return { ...state, errorMessage: null, regPlace: action.value };
    case 'reg-year-changed': return { ...state, errorMessage: null, regYear: action.value };
    case 'street-changed': return { ...state, errorMessage: null, street: action.value };
    case 'city-changed': return { ...state, errorMessage: null, city: action.value };
    case 'zip-code-changed': return { ...state, errorMessage: null, zipCode: action.value };
    case 'country-changed': return { ...state, errorMessage: null, country: action.value };
    case 'hr-name-changed': return { ...state, errorMessage: null, hrName: action.value };
    case 'hr-nb-changed': return { ...state, errorMessage: null, hrNb: action.phoneNumber };
    case 'close-modal': return { ...state, emailSent: false };
    case 'accept-terms-changed': return { ...state, acceptTerms: !state.acceptTerms };
  }
};

const SignupPage = () => {
  const { status } = useParams();
  const [state, dispatch] = useReducer(reducer, {
    companyName: '',
    fullName: '',
    companyWebsite: '',
    email: '',
    phoneNumber: '32', // 32 is Belgium country code
    password: '',
    confirmPassword: '',
    passwordShown: false,
    loading: false,
    errorMessage: null,
    status: status,
    emailSent: false,
    regNb: '',
    regPlace: '',
    regYear: '',
    street: '',
    city: '',
    zipCode: '',
    country: '',
    hrName: '',
    hrNb: '32',
    acceptTerms: false
  });
  const {
    companyName,
    fullName,
    companyWebsite,
    email,
    phoneNumber,
    password,
    passwordShown,
    loading,
    errorMessage,
    confirmPassword,
    emailSent,
    regNb,
    regPlace,
    regYear,
    street,
    city,
    zipCode,
    country,
    hrName,
    hrNb,
    acceptTerms
  } = state;

  useEffect(() => {
    document.title = 'Wazeefati | Signup';
    window.scrollTo(0, 0);
  }, []);

  const screenWidth = getScreenWidth();

  const onChangeName = useCallback((e) => dispatch({ type: 'name-changed', fullName: e.target.value }), []);
  const onChangeEmail = useCallback((e) => dispatch({ type: 'email-changed', email: e.target.value }), []);
  const onChangePassword = useCallback((e) => dispatch({ type: 'password-changed', password: e.target.value }), []);
  const onChangeConfirmPassword = useCallback((e) => dispatch({ type: 'confirm-password-changed', confirmPassword: e.target.value }), []);
  const onChangeCompanyName = useCallback((e) => dispatch({ type: 'companyName-changed', companyName: e.target.value }), []);
  const onChangeCompanyWebsite = useCallback((e) => dispatch({ type: 'companyWebsite-changed', companyWebsite: e.target.value }), []);
  const onChangephoneNumber = useCallback((phone) => dispatch({ type: 'phoneNumber-changed', phoneNumber: phone }), []);

  const onAcceptTermsChange = useCallback((_, { checked }) => {
    dispatch({ type: 'accept-terms-changed', acceptTerms: checked });
  }, []);

  const validate = useCallback(() => {
    if (status === 'employer' &&
      (!fullName || !email || !password || !phoneNumber || !companyName || !confirmPassword ||
        !regNb || !regPlace || !regYear || !city || !street || !zipCode || !country ||
        !hrNb || !hrName || !companyWebsite)) {
      dispatch({ type: 'signup-failed', error: 'Please enter all fields.' });
      window.scrollTo(0, 0);
      return false;
    }
    if (status === 'candidate' && (!fullName || !email || !password || !confirmPassword)) {
      dispatch({ type: 'signup-failed', error: 'Please enter all fields.' });
      window.scrollTo(0, 0);
      return false;
    }
    if (password.length < 8) {
      dispatch({ type: 'signup-failed', error: 'Password must be at least 8 characters.' });
      window.scrollTo(0, 0);
      return false;
    }
    if (password !== confirmPassword) {
      dispatch({ type: 'signup-failed', error: 'Passwords do not match.' });
      window.scrollTo(0, 0);
      return false;
    }
    if (!acceptTerms) {
      dispatch({ type: 'signup-failed', error: 'Please check our Terms and Privacy.' });
      window.scrollTo(0, 0);
      return false;
    }
    return true;
  }, [fullName, email, password, status, confirmPassword, companyName, phoneNumber, acceptTerms,
      regNb, regPlace, regYear, street, city, zipCode, country, hrName, hrNb, companyWebsite]);

  const onSubmit = useCallback(() => {
    if (validate()) {
      dispatch({ type: 'start-signup' });
      getApiClient().signupUser(companyName, fullName, email, phoneNumber, password, status, confirmPassword,
        regNb, regPlace, regYear, street, city, zipCode, country, hrName, hrNb, companyWebsite)
        .then(response => {
          if (response.data.success) {
            dispatch({ type: 'signup-success' });
          } else {
            dispatch({ type: 'signup-failed', error: response.data.message });
            window.scrollTo(0, 0);
          }
        })
        .catch(makeStandardApiErrorHandler(error => {
          dispatch({ type: 'signup-failed', error: error });
          window.scrollTo(0, 0);
        }));
    }
  }, [companyName, fullName, email, phoneNumber, password, validate, status, confirmPassword,
      regNb, regPlace, regYear, street, city, zipCode, country, hrName, hrNb, companyWebsite]);

  const onCloseModal = useCallback(() => dispatch({ type: 'close-modal' }), []);

  const loginText = `/login/${status}`;

  const togglePasswordVisibility = useCallback(() => dispatch({ type: 'toggle-show-password' }), []);

  const onChangeRegNb = useCallback((e) => dispatch({ type: 'reg-nb-changed', value: e.target.value }), []);
  const onChangeRegPlace = useCallback((e) => dispatch({ type: 'reg-place-changed', value: e.target.value }), []);
  const onChangeRegYear = useCallback((e) => dispatch({ type: 'reg-year-changed', value: e.target.value }), []);
  const onChangeStreet = useCallback((e) => dispatch({ type: 'street-changed', value: e.target.value }), []);
  const onChangeCity = useCallback((e) => dispatch({ type: 'city-changed', value: e.target.value }), []);
  const onChangeZipCode = useCallback((e) => dispatch({ type: 'zip-code-changed', value: e.target.value }), []);
  const onChangeCountry = useCallback((_, { value }) => dispatch({ type: 'country-changed', value: value }), []);
  const onChangeHrName = useCallback((e) => dispatch({ type: 'hr-name-changed', value: e.target.value }), []);
  const onChangeHrNb = useCallback((phone) => dispatch({ type: 'hr-nb-changed', phoneNumber: phone }), []);

  return (
    <Fluid>
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      <Navbar />
        {status === 'employer' ? <BigTitle style={{ textAlign: 'center' }}>Employers&apos; Sign up</BigTitle>
          : <BigTitle style={{ textAlign: 'center' }}>Candidates&apos; Sign up</BigTitle>}
      <Modal open={emailSent} className='custom-modal' onClose={onCloseModal}>
        <Modal.Content>
          <PopupDiv>
            <PopupImg src={EmailIcon} />
            <Paragraph>Please check your <span style={{ color: '#FFC000' }}>email</span> to verify your <br />account.
              Make sure to check the Junk folder
            </Paragraph>
          </PopupDiv>
        </Modal.Content>
      </Modal>
      <Container>
        <FormContainer>
        <Title>sanad wazeefati</Title>
        <Form {...{ error: !!errorMessage }} {...{ loading: loading }} unstackable onSubmit={onSubmit} id='signup-form'>
          {errorMessage && <p style={{ color: '#FFD042', fontSize: '20px' }}>{errorMessage}</p>}
          <Form.Input label='Full Name' placeholder='Full Name' onChange={onChangeName} value={fullName} />
          {status === 'employer' &&
            <>
              <Label>Phone Number</Label>
              <CountryPhoneInput onChange={onChangephoneNumber} value={phoneNumber} />
              <div style={{ marginTop: '10px' }} />
              <Form.Input label='Company Name' placeholder='Company Name' onChange={onChangeCompanyName} value={companyName} />
              <Form.Input label='Company Website' placeholder='Company Website' onChange={onChangeCompanyWebsite} value={companyWebsite} />
              <Form.Group widths='equal'>
                <Form.Input width={1} label='Registration Nb' placeholder='Registration Nb' onChange={onChangeRegNb} value={regNb} />
                <div style={{ marginBottom: screenWidth <= 767 ? '76px' : '0' }} />
                <Form.Input width={1} label='Registration Place' placeholder='Registration Place' onChange={onChangeRegPlace} value={regPlace} />
                <div style={{ marginBottom: screenWidth <= 767 ? '76px' : '0' }} />
                <NumberInput width={1} type='number' label='Registration Year' placeholder='Registration Year' onChange={onChangeRegYear} value={regYear} />
                <div style={{ marginBottom: screenWidth <= 767 ? '76px' : '0' }} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input label='Street & Nb' placeholder='Street & Nb' onChange={onChangeStreet} value={street} />
                <div style={{ marginBottom: screenWidth <= 767 ? '76px' : '0' }} />
                <Form.Input label='City' placeholder='City' onChange={onChangeCity} value={city} />
                <div style={{ marginBottom: screenWidth <= 767 ? '76px' : '0' }} />
              </Form.Group>
              <Form.Group widths='equal'>
                <div style={{ width: '100%', marginLeft: '8px', marginRight: '8px' }}>
                  <Form.Input type='number' label='Zip Code' placeholder='Zip Code' onChange={onChangeZipCode} value={zipCode} />
                  <div style={{ marginBottom: screenWidth <= 767 ? '8px' : '0' }} />
                </div>
                <div style={{ width: '100%', marginLeft: '8px', marginRight: '8px' }}>
                  <Label>Country</Label>
                  <Dropdown value={country} onChange={onChangeCountry} placeholder='Country' fluid search selection options={Data} />
                  <div style={{ marginBottom: screenWidth <= 767 ? '8px' : '0' }} />
                </div>
              </Form.Group>
            </>}
          <Form.Input label='Email' placeholder='Email' type='email' onChange={onChangeEmail} value={email} />
          <Form.Input label='Password' type={passwordShown ? 'text' : 'password'} placeholder='***********' value={password}
              onChange={onChangePassword}
              icon={<Icon name={passwordShown ? 'eye slash' : 'eye'} link onClick={togglePasswordVisibility}
                style={{ left: 'unset', right: '8px' }} />} />
          <Form.Input label='Confirm Password' placeholder='Confirm Password' type='password' onChange={onChangeConfirmPassword} value={confirmPassword} />
          {status === 'employer' &&
            <Form.Group widths='equal'>
              <Form.Input width={1} label="HR Manager's Name" placeholder="HR Manager's Name" onChange={onChangeHrName} value={hrName} />
              <div style={{ marginLeft: '8px', marginRight: '4px' }}>
                <Label>Direct Phone Number</Label>
                <CountryPhoneInput onChange={onChangeHrNb} value={hrNb} />
                <div style={{ marginBottom: screenWidth <= 767 ? '8px' : '0' }} />
              </div>
            </Form.Group>}
          <AcceptsTerms>
            <label className="accept-terms-label">
              <Checkbox checked={acceptTerms}
              value={acceptTerms}
              onChange={onAcceptTermsChange} />
              <LabelSentence>
                I accept all <a href="/terms" className='linkforterms'>Terms & Privacy policy</a>
              </LabelSentence>
            </label>
          </AcceptsTerms>
          <LoginLink to={loginText}>You already have an account? Click here to login</LoginLink>
          <Button type='submit' form='signup-form' content='Sign up' />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ForgotPassword to='/forgot_password'>Forgot your password?</ForgotPassword>
          </div>
        </Form>
        </FormContainer>
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      </Container>
      <Footer />
    </Fluid>
  );
};

const LabelSentence = styled.div`
  margin-left: 5px;
`;

const AcceptsTerms = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  display: flex;
  align-items: center;

  .accept-terms-label {
    display: flex !important;
  }
  .linkforterms{
    color: white;
    text-decoration: underline;
    margin-left: 1.5px;
    :hover {
    color: white;
    text-decoration: none;
  }
  }
`;

const NumberInput = styled(Form.Input)`
  input {
    padding: 10px !important;
  }
`;

const ForgotPassword = styled(Link)`
  color: white;

  :hover {
    color: white;
  }
`;

const Label = styled.label`
  color: white !important;
  font-family: 'Corbel' !important;
  font-weight: 300 !important;
  font-size: 16px !important;
`;

const Fluid = styled.div`
  position: relative;
  background: url(${Lines});
`;

const PopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px 60px 20px;
`;

const Paragraph = styled.p`
  font-family: 'Corbel';
  font-weight: 500;
  color: #23485B;
  text-align: center;
`;

const PopupImg = styled.img`
  width: 120px;
`;

const FormContainer = styled.div`
  width: 500px;
  padding: 30px 20px 30px 20px;
  background-color: #2E607A;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 33px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .ui.loading.form:before {
    background: transparent;
  }

  .ui.form .field > label {
    color: white !important;
    font-family: 'Corbel' !important;
    font-weight: 300 !important;
    font-size: 16px !important;
  }
`;
const BigTitle = styled.div`
  margin-top: 100px;
  font-size: 30px;
  font-variant: all-small-caps;
  color: Black;
`;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -225px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -225px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;
const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 20px 0 220px 0;
`;

const Title = styled.h1`
  color: #FFD042;
  font-family: 'Corbel';
  font-weight: 100;
  font-size: 40px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
  font-variant: small-caps;
`;

const Button = styled(Form.Button)`
  text-align: center;

  .ui.button {
    margin-top: 34px;
    padding: 8px 30px 8px 30px;
    color: white;
    background-color: #23485B;
    font-family: 'Corbel';
    font-weight: 300;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius : 10px;
    font-size: 16px;
  }
`;

const LoginLink = styled(Link)`
  color: white;
  text-decoration: underline;

  :hover {
    color: white;
  }
`;

export default RequireLogout(SignupPage);
