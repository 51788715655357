import React, { useCallback, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import RequireUserAccess from '../../permissions/require_user_access';
import { UserStatusEnum } from '../../enums/user_status_enum';
import { getApiClient, makeStandardApiErrorHandler } from '../../server/get_api_client';
import Pagination from '../../utils/pagination/pagination';
import Navbar from '../../Common/navbar.js';
import Footer from '../../Common/footer.js';
import Wave from 'react-wavify';
import { getScreenWidth } from '../../helpers/screen_width';

const reducer = (state, action) => {
  switch (action.type) {
    case 'get-count-start':
    case 'get-jobs-start':
      return { ...state, loading: true, cardsLoading: state.currentPage !== 1 };
    case 'get-jobs-failed':
    case 'get-count-failed': return { ...state, loading: false, cardsLoading: false, errorMessage: action.error };
    case 'get-count-success': return { ...state, loading: false, totalCount: action.totalCount };
    case 'get-jobs-success':
      return {
        ...state,
        jobs: action.jobs,
        loading: false,
        cardsLoading: false
      };
    case 'on-page-change': return { ...state, currentPage: action.currentPage };
    default: throw new Error('Unhandled action type: ' + action.type);
  }
};

const MyJobs = () => {
  const [state, dispatch] = useReducer(reducer, {
    jobs: [],
    totalCount: 0,
    currentPage: 1,
    loading: true,
    cardsLoading: false,
    errorMessage: null
  });
  const {
    jobs,
    totalCount,
    currentPage,
    loading,
    cardsLoading,
    errorMessage
  } = state;

  useEffect(() => {
    document.title = 'Wazeefati | My Jobs';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({ type: 'get-count-start' });
    getApiClient().getMyJobsCount()
      .then(response => dispatch({ type: 'get-count-success', totalCount: response.data.totalCount }))
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'get-count-failed', error: error })));
  }, []);

  useEffect(() => {
    dispatch({ type: 'get-jobs-start' });
    getApiClient().getMyJobs(currentPage)
      .then(response => {
        dispatch({ type: 'get-jobs-success', jobs: response.data.jobs });
      })
      .catch(makeStandardApiErrorHandler(error => dispatch({ type: 'get-jobs-failed', error: error })));
  }, [currentPage]);

  const onPageChange = useCallback((currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch({ type: 'on-page-change', currentPage: currentPage });
  }, []);

  const paginationStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  };

  return (
    <>
    <div style={{ position: 'relative' }}>
      <Navbar />
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      {loading
        ? <Loader active={loading} size='medium'>Loading</Loader>
        : jobs.length > 0
        ? <Container>
            <p style={{ color: 'red' }}>{errorMessage}</p>
            <CandidateDiv>
              <p style={{ paddingRight: '20px' }}>My Jobs</p>
            </CandidateDiv>
            {cardsLoading
              ? <div style={{ height: '400px' }}><Loader active={cardsLoading} size='medium'>Loading</Loader></div>
              : <>
                  {jobs.map(value => <Card key={value.id} data={value} />)}
                  <PaginationBox>
                    {/* eslint-disable-next-line react/jsx-no-bind */}
                    <Pagination className={paginationStyle} onPageChange={page => onPageChange(page)} pageSize={6}
                      currentPage={currentPage} totalCount={totalCount} />
                  </PaginationBox>
                </>}
          </Container>
        : <Container><NoApp>No Jobs Yet.</NoApp></Container>}
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
      <BottomWave fill='#466E83'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
    </div>
    <Footer />
    </>
  );
};

const NoApp = styled.p`
  font-size: 20px;
  margin-top: 20px;
`;

const Container = styled.div`
  margin: 65px 0 158px 0;
  padding: 82px 152px 40px 152px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  min-height: 600px;

  @media screen and (max-width: 840px) {
    padding: 82px 100px 40px 100px;
  }
  @media screen and (max-width: 740px) {
    padding: 82px 60px 40px 60px;
  }
  @media screen and (max-width: 650px) {
    padding: 82px 40px 40px 40px;
  }
`;
const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -165px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -165px;
`;
const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const CandidateDiv = styled.div`
  display: flex;
  border-bottom: 1px solid black;
  font-size: 18px;
  font-family: 'Corbel Bold';
  font-weight: 400;
`;

const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 20px 0;
`;

const Card = ({ data }) => {
  const navigate = useNavigate();

  const openJob = useCallback(() => navigate(`/job/${data.id}`), [data.id, navigate]);

  const date = new Date(data.date_created);
  const year = date.getFullYear();
  const day = ('0' + date.getDate()).slice(-2);
  const dateCreated = `${date.toLocaleDateString('en-US', { month: 'short' })} ${day},
    ${year} at ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}`;
  const maxBubbleNb = getScreenWidth() <= 564 ? 2 : 4;

  return (
    <CardContainer onClick={openJob}>
      <ColumnFlex>
        <EndFlex>
          <FullName>
            {data.headline} -&nbsp;
            <Span>
              {data.project_title} -&nbsp;
            </Span>
            <Span>
              {data.position_title}
            </Span>
          </FullName>
          <div>
            <Paragraph>Min Hourly Rate: ${data.min}</Paragraph>
            <Paragraph>Max Hourly Rate: ${data.max}</Paragraph>
          </div>
        </EndFlex>
        <CenterFlex>
          <BubblesContainer>
            <div style={{ display: 'flex' }}>
              {data.skills.slice(0, maxBubbleNb).map(value => <Bubble key={value}>{value}</Bubble>)}
              {data.skills.length > maxBubbleNb && <Bubble>...</Bubble>}
            </div>
          </BubblesContainer>
        </CenterFlex>
        <Desc>
          <Paragraph1>
            {data &&
              data.description.length <= 55
                ? data.description
                : <span>{data.description.substring(0, 56)}...</span>}
          </Paragraph1>
          <Paragraph style={{ marginLeft: 'auto' }}>Date Created: {dateCreated}</Paragraph>
        </Desc>
      </ColumnFlex>
    </CardContainer>
  );
};

const Desc = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1310px) {
    flex-direction: column;
    align-items: start;
  }
`;

const Paragraph1 = styled.p`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 16px;
  margin: 6px 0 6px 0 !important;
`;

const Span = styled.span`
  font-family: 'Corbel';
  font-size: 14px;
`;

const CardContainer = styled.div`
  padding: 16px 34px 8px 34px;
  box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.25), inset -256px 0px 35px rgba(172, 172, 172, 0.25);
  border-top: 0.4px solid #000000;
  cursor: pointer;
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
`;

const FullName = styled.p`
  font-family: 'Corbel Bold';
  font-size: 20px;
  margin-bottom: 0;
`;

const CenterFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BubblesContainer = styled.div`
  margin-top: 26px;
  margin-bottom: 26px;
`;

const Bubble = styled.div`
  border: 2px solid #23485B;
  color: #23485B;
  padding: 6px 12px 6px 12px;
  margin-left: 14px;
  border-radius: 12px;
  font-family: 'Corbel';
  font-weight: 400;
`;

const EndFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Paragraph = styled.p`
  font-family: 'Corbel';
  font-weight: 400;
  font-size: 16px;
  margin: 6px 0 6px 0;
`;

export default RequireUserAccess(MyJobs, UserStatusEnum.EMPLOYER);
