import React from 'react';
import styled from 'styled-components';
import { Form, Dropdown } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import Arrow from '../../svgs/arrow.svg';

const Options = [
    { key: 'Hourly', value: 'Hourly', flag: 'Hourly', text: 'Hourly' },
    { key: 'Daily', value: 'Daily', flag: 'Daily', text: 'Daily' }
  ];

export default function Payment(props) {
  return (
    <>
    <Fields>
        <Top>
            <BigLabel>average payment</BigLabel>
            <SmallCon>
                <Field>
                    <Label>min</Label>
                    <Digit icon='dollar' placeholder='20' type='number' value={props.min} onChange={props.AddMin} />
                </Field>
                <Field style={{ marginBottom: '14px' }}>
                    <Label>max</Label>
                    <Digit icon='dollar' placeholder='40' value={props.max} onChange={props.AddMax} type='number' />
                </Field>
                <div style={{ marginTop: '8px', marginLeft: '10px' }}> - </div>
                <Drop placeholder='Hourly' value={props.paymenttype} onChange={props.AddPaymenttype} selection options={Options} />
            </SmallCon>
        </Top>
        <Top>
            <Field>
                <Label>maximum project budget</Label>
                <BigDigit value={props.maximumbudget} onChange={props.AddMaximumbudget} type='number' placeholder='1000' icon='dollar' />
            </Field>
        </Top>
        <Top>
        <ReCAPTCHA sitekey='6LflgxgmAAAAAAoTrMb0XtAYDuKzZc2z6FO6thV8' onChange={props.AddCaptcha} />
        </Top>
    </Fields>
    <ButtonContainer>
      <SubmitButton src={Arrow} onClick={props.changeState}>submit</SubmitButton>
    </ButtonContainer>
    </>
  );
};

const Fields = styled.div`
  display: flex;
  margin: 70px 0px 40px 0px;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height 50vh;
`;
const ButtonContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: right;
      flex-wrap: wrap;
      position: relative;
      min-height: 100px;
      margin-bottom: 20px;
      width: 90%;
`;
const SubmitButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    height: 40px;
    cursor: pointer;
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-size: 27px;

    background: #23485B;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.20);
    line-height: 90.69%;
    /* or 36px */
  
    text-align: center;
    font-variant: small-caps;
  
    color: #FFD042;
    @media screen and (max-width: 765px){
        width: 40vw;
    }
`;
const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    margin-bottom: 10px;
    min-height 8vh;
`;
const Label = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-family: 'Corbel', sans-serif !important;
  font-variant: small-caps;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
`;
const BigLabel = styled.label`
  color: black !important;
  margin-bottom: 10px;
  font-family: 'Corbel', sans-serif !important;
  font-variant: small-caps;
  font-weight: 400 !important;
  font-size: 2.0rem !important;
`;
const SmallCon = styled.div`
  display: flex;
  width: 420px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 475px){
    flex-direction: column;
    width: 100vw;
  }
`;
const Digit = styled(Form.Input)`
  width: 100px;
  height: 38px;
  background: white;
  border-radius: 5px;
  .ui.input {
    width: 100px !important;
    height: 38px !important;
  }
  .ui.input input {
    width: 48px !important;
  }
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
}
  @media screen and (max-width: 475px){
    width: 150px !important;
  input {
    width: 150px !important;
  }
  .ui.input {
    width: 150px !important;
  }
  }
`;
const Field = styled(Form.Field)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Drop = styled(Dropdown)`
  margin: 10px 10px 0px 10px;
  min-width: 100px !important;
  max-width: 100px !important;
  min-height: 10px !important;
  height: 15px !important;
  > input {
    width: 100px !important;
    min-height: 10px !important;
  }
`;
const BigDigit = styled(Form.Input)`
  width: 410px;
  height: 38px;
  margin: 0px !important;
  background: white;
  border-radius: 5px;
  .ui.input {
    width: 410px !important;
    height: 38px !important;
  }
  input {
    color: rgb(35, 72, 91) !important;
    font-size: 16px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  @media screen and (max-width: 475px){
    width: 80vw !important;
    .ui.input {
    width: 80vw !important;
    height: 38px !important;
  }
  }
`;
