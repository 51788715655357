import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Form } from 'semantic-ui-react';

export default function References(props) {
    return (
      <>
        <Fields>
          <Bigger>
          {props.refreences.map((data, index) => {
              return (
                /* eslint-disable-next-line */
          <Container key={index}>
          <Form.Field>
            <Label>name</Label>
            <NameAndPosition value={data.name} index={index} onChange={props.Addname} placeholder='John Doe' />
          </Form.Field>
          <Form.Field>
            <Label>position</Label>
            <NameAndPosition value={data.position} index={index} onChange={props.Addposition} placeholder='HR' />
          </Form.Field>
          <Form.Field>
            <Label>organization</Label>
            <Name value={data.organization} index={index} onChange={props.Addorganization} placeholder='Sanad Lebanon' />
          </Form.Field>
          <Form.Field>
            <Label>email</Label>
            <Country value={data.email} index={index} onChange={props.Addemail} placeholder='email@email.com' />
          </Form.Field>
          <Form.Field>
            <Label>phone number</Label>
            <Input value={data.phone_number} index={index}
            /* eslint-disable-next-line react/jsx-no-bind */
             onChange={(phone, { ignore }) => props.Addphone(phone, index)} />
          </Form.Field>
          <Form.Field>
            <Label>known since</Label>
            <Digit value={data.known_since} index={index} onChange={props.AddknownSince} placeholder='YYYY' min='0' type='number' />
          </Form.Field>
          {/* eslint-disable-next-line */}
          {index === 0 ? '' : <Rem onClick={() => props.RemoveRef(index)}>-</Rem>}
          </Container>);
                  })}
            <Plus onClick={props.Addrefreences}>+</Plus>
          </Bigger>
        </Fields>
        <ButtonContainer>
          <SubmitButton onClick={props.changeState}>submit</SubmitButton>
        </ButtonContainer>
      </>
      );
    }
const SubmitButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    height: 40px;
    cursor: pointer;
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;

    background: #FFD042;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.20);
    line-height: 90.69%;
    /* or 36px */
  
    text-align: center;
    font-variant: small-caps;
  
    color: #23485B;
    @media screen and (max-width: 765px){
        width: 40vw;
    }
`;
const Rem = styled.div`
display: flex;
margin: 25.5px 0px 0px -10px;
font-size: 1.5rem;
color: #FFC000;
box-shadow: 0px 0px 1.5px 1.5px rgba(0, 0, 0, 0.12);
align-items: center;
justify-content: center;
width: 30px;
height: 40px;
cursor: pointer;
color: red;
background: white;
@media screen and (max-width: 765px){
  width: 90vw;
  margin: 31.5px 0px 0px 0px;
}
`;
    const Input = styled(PhoneInput)`
    input {
         width: 240px !important;
         padding-left: 50px !important;
        
          color: rgb(35, 72, 91) !important;
          font-size: 16px !important;
        
        }
        @media screen and (max-width: 745px){
          width: 90vw !important;
          input {
            width: 90vw !important;
            padding-left: 50px !important;
        }
        }
    `;
    const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    flex-wrap: wrap;
    position: relative;
    border-top: 1px solid black;
    min-height: 100px;
    margin-bottom: 20px;
    width: 90%;
    `;
    const Bigger = styled.div`
      position: relative;
      margin-bottom: 20px !important;
    `;
    const Fields = styled.div`
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 70px 0px 50px 0px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      min-height 40vh;
      @media screen and (max-width: 745px){
        margin-bottom: 20px !important;
      }
    `;
    const Plus = styled.div`
      position: absolute;
      right: -15px;
      bottom: -40px;
      display: flex;
      margin: 15px;
      font-size: 1.5rem;
      color: #FFC000;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #23485B;
      border-radius: 100px;
    `;
    const Container = styled.div`
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 78vw;
      @media screen and (max-width: 745px){
        justify-content: center;
        margin-bottom: 20px !important;
        border-top: 1px solid black;
        width: 90vw !important;
}
    `;
    const Label = styled.label`
      color: black !important;
      margin-bottom: 10px;
      font-family: 'Corbel' !important;
      font-variant: small-caps;
      font-weight: 400 !important;
      font-size: 1.4rem !important;
    `;
    const NameAndPosition = styled(Form.Input)`
      width: 150px;
      margin: 0px 5px !important;
      height: 38px;
      background: white;
      border-radius: 5px;
      input {
        color: rgb(35, 72, 91) !important;
        font-size: 16px !important;
      }
      > input{
        height: 35px;
      }
      @media screen and (max-width: 745px){
        width: 90vw !important;
      }
    `;
    const Digit = styled(Form.Input)`
      width: 100px;
      margin: 0px 5px !important;
      height: 38px;
      background: white;
      border-radius: 5px;
      input {
        color: rgb(35, 72, 91) !important;
        font-size: 16px !important;
      }
      > input{
        height: 35px;
     }
     @media screen and (max-width: 745px){
      width: 90vw !important;
    }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  }
    `;
    const Name = styled(Form.Input)`
      width: 250px;
      height: 38px;
      margin: 0px 5px !important;
      background: white;
      border-radius: 5px;
      input {
        color: rgb(35, 72, 91) !important;
        font-size: 16px !important;
      }
      @media screen and (max-width: 745px){
       width: 90vw !important;
       }
    `;
    const Country = styled(Form.Input)`
      width: 200px;
      margin: 0px 5px !important;
      height: 38px;
      background: white;
      border-radius: 5px;
      input {
        color: rgb(35, 72, 91) !important;
        font-size: 16px !important;
      }
      @media screen and (max-width: 745px){
        width: 90vw !important;
      } 
    `;
