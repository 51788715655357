import React, { useCallback, useEffect, useReducer } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { getApiClient } from './server/get_api_client';
import { UserContext } from './contexts/user_context';
import LanguageContext from './contexts/language_context';
import * as i18nIsoCountries from 'i18n-iso-countries';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-user': return { ...state, user: action.me };
    case 'set-language': return { ...state, language: action.language };
  }
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, {
    user: null,
    language: 'en'
  });
  const {
    user,
    language
  } = state;

  useEffect(() => {
    if (localStorage.getItem('wazeefati_token')) {
      getApiClient().me().then(response => dispatch({ type: 'set-user', me: response.data.me }));
    }
  }, []);

  const setUser = useCallback((me) => {
    dispatch({ type: 'set-user', me: me });
  }, []);

  const setLanguage = useCallback((lang) => {
    dispatch({ type: 'set-language', language: lang });
    localStorage.setItem('wazeefati_lang', lang);
  }, []);

  useEffect(() => {
    const wazeefatiLang = localStorage.getItem('wazeefati_lang');
    if (wazeefatiLang) {
      setLanguage(wazeefatiLang);
    }
  }, [setLanguage]);

  useEffect(() => {
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <RouterProvider router={router} />
      </LanguageContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
