import React, { useEffect } from 'react';
import styled from 'styled-components';
import Navbar from '../../Common/navbar';
import Footer from '../../Common/footer';
import Wave from 'react-wavify';
import { getScreenWidth } from '../../helpers/screen_width';
import { MOBILE_WIDTH } from '../../helpers/constants';
import PhoneIcon from '../../svgs/phone_icon.svg';
import MessageIcon from '../../svgs/message_icon.svg';
import AddressIcon from '../../svgs/address_icon.svg';
import GlobalIcon from '../../svgs/global_icon.svg';

const ContactUsPage = () => {
  useEffect(() => {
    document.title = 'Wazeefati | Contact Us';
    window.scrollTo(0, 0);
  }, []);

  return (
    <TopContainer>
      <TopWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <TopWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      <Navbar />
      <Container>
        <FlexWrapper style={{ justifyContent: 'space-between !important', padding: '30px 30px 0 30px' }}>
          <Line />
          <ContactUsTitle>CONTACT US</ContactUsTitle>
          <Line />
        </FlexWrapper>
        <Flex style={{ justifyContent: 'space-between' }} alignItems='start' screenWidth={getScreenWidth()} mobileWidth={MOBILE_WIDTH}>
          <div>
            <Logo width='350' src='https://wazeefati-s3.s3.eu-central-1.amazonaws.com/logo.png' />
          </div>
          <div>
            <BlurContainer>
              <FlexWrapper1 style={{ justifyContent: 'start !important' }}
                alignItems={getScreenWidth() > 800 ? 'center' : 'start'}>
                <Img width='30' src={PhoneIcon} />
                <Text>
                  <Span>Phone number:</Span> {getScreenWidth() <= 800 && <br />}+961 1 867367
                </Text>
              </FlexWrapper1>
              <FlexWrapper1 style={{ margin: '30px 0 30px 0', justifyContent: 'start !important' }}
                alignItems={getScreenWidth() > 800 ? 'center' : 'start'}>
                <Img width='30' src={MessageIcon} />
                <Text><Span>E-Mail:</Span> {getScreenWidth() <= 800 && <br />}info@sanadleb.org</Text>
              </FlexWrapper1>
              <FlexWrapper1 style={{ margin: '30px 0 30px 4px', justifyContent: 'start !important' }}
                alignItems={getScreenWidth() > 800 ? 'center' : 'start'}>
                <Img width='20' src={AddressIcon} />
                <Text>
                  <Span style={{ marginLeft: '5px' }}>Address:</Span> {getScreenWidth() <= 800 && <br />}Dolphin Center,
                    {getScreenWidth() <= 800 && <br />} 4th floor, Rouche, Beirut, Lebanon
                </Text>
              </FlexWrapper1>
              <FlexWrapper1 style={{ justifyContent: 'start !important' }}
                alignItems={getScreenWidth() > 800 ? 'center' : 'start'}>
                <Img width='30' src={GlobalIcon} />
                <Text>
                  <Span>Hours:</Span> {getScreenWidth() <= 800 && <br />}M - F : {getScreenWidth() <= 800 && <br />}
                    09:00 AM - 17:30 PM
                </Text>
              </FlexWrapper1>
            </BlurContainer>
          </div>
        </Flex>
      <BottomWave2 fill='#23485B'
        paused={false}
        options={{
          height: 20,
          amplitude: 50,
          speed: 0.15,
          points: 3
        }} />
    <BottomWave fill='#FFD042'
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }} />
      </Container>
      <Footer />
    </TopContainer>
  );
};

const Text = styled.p`
  margin: 0 0 0 12px;
`;

const Img = styled.img`
  width: 30px;
  filter: grayscale(0.8);
`;
const Span = styled.span`
  font-weight: bold;
`;

const BlurContainer = styled.div`
  margin-top: 50px;
  font-family: 'Corbel';
  letter-spacing: 2px;
  font-size: 17px;
`;

const Logo = styled.img`
  margin-left: 20px;

  @media screen and (max-width: 800px) {
    margin-top: 50px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexWrapper1 = styled.div`
  display: flex;
  align-items: center;
`;

const Flex = styled(FlexWrapper)`

  padding: 10px 200px 0 200px;

  @media screen and (max-width: 1190px) {
    padding: 10px 50px 0 50px;
  }

  @media screen and (max-width: 900px) {
    padding: 10px 10px 0 10px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContactUsTitle = styled.h1`
  font-family: 'Corbel Bold';
  font-size: 38px;
  padding: 0 14px 0 14px;
  letter-spacing: 1px;
  margin-bottom: 22px;
  text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 0px -4px 10px rgba(255,255,255,0.3);

  @media screen and (max-width: 1300px) {
    font-size: 26px;
  }
`;

const Line = styled.div`
  background-color: black;
  width: 38%;
  height: 3px;

  @media screen and (max-width: 1190px) {
    width: 30%;
  }

  @media screen and (max-width: 867px) {
    width: 24%;
  }

  @media screen and (max-width: 634px) {
    width: 20%;
  }

  @media screen and (max-width: 536px) {
    width: 16%;
  }
`;

const Container = styled.div`
  position: relative;
  margin: 40px 0 220px 0;
`;

const TopContainer = styled.div`
  position: relative;
`;

const TopWave = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: -15px;
`;
const TopWave2 = styled(Wave)`
  z-index: -3;
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
`;

const BottomWave = styled(Wave)`
  position: absolute;
  bottom: -230px;
`;
const BottomWave2 = styled(Wave)`
  position: absolute;
  bottom: -230px;
`;

export default ContactUsPage;
